import React from 'react';
import { Navigate } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { useUser } from '../contexts/UserContext';
 
interface ProtectedRouteProps {
  children: React.ReactElement;
  requiredRoles?: string[];
}
 
export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredRoles = [] }) => {
  const { account, roles, isLoading } = useUser();
  const isAuthenticated = useIsAuthenticated();
 
  if (isLoading) {
    return <div>Loading...</div>;
  }
 
  console.log('Protected Route Check:', {
    isAuthenticated,
    account,
    requiredRoles,
    currentRoles: roles,
    hasRequiredRole: requiredRoles.some((role) => roles.includes(role)),
  });
 
  if (!isAuthenticated || !account) {
    return <Navigate to="/" />;
  }
 
  if (requiredRoles.length > 0 && !requiredRoles.some((role) => roles.includes(role))) {
    return <Navigate to="/" />;
  }
 
  return children;
};