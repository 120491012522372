import React, { useEffect, useState } from 'react';
import { LandingPageHeader } from '../components/Layout/LandingPageHeader';
import { AppFooter } from '../components/Layout/AppFooter';
import { Button, Card, Col, Row, Select, Typography } from 'antd';
import { Flex, Layout } from 'antd';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { USER_ROLES, hasRole, getUserRoles } from '../utils/auth';
import { useUser } from '../contexts/UserContext';
const { Header, Content } = Layout;
const headerStyle: React.CSSProperties = {
  color: '#222',
  height: 120,
  paddingInline: 48,
  lineHeight: '64px',
  backgroundColor: '#E3E7F1',
};
 
const contentStyle: React.CSSProperties = {
  marginTop: -25,
  minHeight: '600px',
  lineHeight: '200px',
  color: '#222',
  backgroundColor: '#E3E7F1',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'left',
};
 
const layoutStyle = {
  borderRadius: 8,
  overflow: 'hidden',
  minWidth: 'calc(50% - 8px)',
  maxWidth: 'calc(70% - 8px)',
  margin: '80px auto',
};
 
const LandingPage: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const {account, roles, isLoading} = useUser();
  const [devRole, setDevRole] = useState<string | null>(null);

  const isDevelopment = process.env.REACT_APP_ENV === 'DEV';
  const checkRole = (role: string) => {
    if (isDevelopment && devRole) {
      return devRole === role;
    }
    return roles.includes(role);
  };
 
 
  // Temporary debug code
  useEffect(() => {
    console.log('Current Account:', account);
    console.log('User Roles:', getUserRoles(account));
    console.log('Has Disseminator Role:', hasRole(account, USER_ROLES.DISSEMINATOR));
  }, [account]);
 
  return (
    <>
      <Flex gap="middle" wrap>
        <Layout style={layoutStyle}>
          <Header style={headerStyle}>
            <LandingPageHeader />
            {isDevelopment && isAuthenticated && (
              <><Select
                style={{ width: 200, position: 'absolute', right: 20, top: 20 }}
                placeholder="Switch Role (Dev Only)"
                onChange={setDevRole}
                options={[
                  { label: 'Reader', value: USER_ROLES.READER },
                  { label: 'Disseminator', value: USER_ROLES.DISSEMINATOR },
                  { label: 'Well Log Viewer', value: USER_ROLES.WELL_LOG_VIEWER },
                  { label: 'License Transfer Admin', value: USER_ROLES.LICENSE_TRANSFER_ADMIN },
                  { label: 'Technical Admin', value: USER_ROLES.TECHNICAL_ADMIN },
                ]} /><Typography.Text style={{ position: 'absolute', right: 20, top: 50, color: 'gray', fontSize: 10, marginTop: 5 }}>
                  Note: The roles selected from this menu only determine the visibility of menu items on this page. They do not affect the actual roles assigned to the user.
                </Typography.Text></>
            )}
          </Header>
          <Content style={contentStyle} className="Landing-page-bg">
            <Row gutter={16} style={{ marginTop: '-20px' }}>
              <Col span={10} offset={1}>
                {isAuthenticated ? (
                  <div className="user-info">
                    <span style={{ fontSize: '2em' }}>Welcome back, {account?.idTokenClaims.name.split(' ')[0]}</span>
                  </div>
                ) : (
                  'Welcome back.'
                )}
              </Col>
              <Col span={6} offset={0} style={{ marginTop: '120px' }}>
                {isAuthenticated && (
                  <Card
                    hoverable
                    title="Product Search"
                    style={{ marginBottom: '15px' }}
                    bordered={false}
                    extra={
                      <Button type="primary" size="middle" href="/productsearch">
                        Search
                      </Button>
                    }
                  >
                    Search and download product files
                  </Card>
                )}
                {isAuthenticated &&
                  (checkRole(USER_ROLES.DISSEMINATOR) || checkRole(USER_ROLES.TECHNICAL_ADMIN)) && (
                    <Card
                      hoverable
                      title="Dissemination"
                      bordered={false}
                      extra={
                        <Button type="primary" size="middle" href="/dissemination">
                          Manage
                        </Button>
                      }
                      style={{
                        marginBottom: '15px',
                      }}
                    >
                      Process dissemination for Well Logs, Licence Transfers, GOS-REPS, and Core
                      Analysis, Fluid Analysis, Directional Survey, Well Log G9, Pipelines,
                      Facilities
                    </Card>
                  )}
                {isAuthenticated &&
                  (checkRole(USER_ROLES.WELL_LOG_VIEWER) ||
                    checkRole(USER_ROLES.TECHNICAL_ADMIN)) && (
                    <Card
                      hoverable
                      title="File Maintenance"
                      bordered={false}
                      extra={
                        <Button type="primary" size="middle" href="/maintenance">
                          Manage
                        </Button>
                      }
                    >
                      Well Log Viewer
                    </Card>
                  )}
              </Col>
 
              <Col span={6} style={{ marginTop: '120px' }}>
                {isAuthenticated &&
                  (checkRole(USER_ROLES.LICENSE_TRANSFER_ADMIN) ||
                    checkRole(USER_ROLES.TECHNICAL_ADMIN)) && (
                    <Card
                      hoverable
                      title="Public Request"
                      bordered={true}
                      style={{ marginBottom: '15px' }}
                      extra={
                        <Button type="primary" size="middle" href="/maintenance">
                          Manage
                        </Button>
                      }
                    >
                      <div>Manage existing public requests</div>
                    </Card>
                  )}
                {isAuthenticated && checkRole(USER_ROLES.TECHNICAL_ADMIN) && (
                  <Card
                    hoverable
                    title="Reporting"
                    bordered={false}
                    style={{ marginBottom: '15px' }}
                    extra={
                      <Button type="primary" size="middle">
                        Manage
                      </Button>
                    }
                  >
                    View dissemination, subscription, and compilcation reports
                  </Card>
                )}
                {isAuthenticated && checkRole(USER_ROLES.TECHNICAL_ADMIN) && (
                  <Card
                    hoverable
                    title="New File Type"
                    bordered={false}
                    extra={
                      <Button type="primary" size="middle">
                        Manage
                      </Button>
                    }
                  >
                    Create new file types for dissemination processing
                  </Card>
                )}
              </Col>
            </Row>
          </Content>
        </Layout>
        <AppFooter />
      </Flex>
    </>
  );
};
 
export default LandingPage;