import React from 'react';

const WellSearchFormByArea: React.FC = () => {
    return (
        <div>
            Placeholder for Area Filter.
        </div>
    );
};

export default WellSearchFormByArea;
