/* eslint-disable max-lines */
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  addCustomerRequestBillingNotes,
  addCustomerRequestNotes,
  getBillingStatuses,
  getDisseminationRequestPayload,
  getNotesByRequestId,
  setCustomerRequestBillingStatus,
  setCustomerRequestStatus,
  upsertDisseminationRequest,
} from '../../../../services/api';
import notifyUser from '../../../../utils/notifyUser';
import { Upload, message } from 'antd';
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckOutlined,
  CheckSquareOutlined,
  DeleteOutlined,
  FileSearchOutlined,
  LeftCircleOutlined,
  SendOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { useUser } from '../../../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { uploadFiles } from '../../../../services/blob';

interface ModalsState {
  readyForBilling: boolean;
  readyForDissemination: boolean;
}

interface BillingStatus {
  id: string;
  name: string;
}

interface RequestDetails {
  id: string;
  requestNumber: string;
  description: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  companyName: string;
  requestTypeName: string;
  requestStatusName: string;
  billingStatusId: string;
  billingStatusName: string;
  paymentTypeName: string;
  requestFileTypes: [
    {
      requestFileTypeId: string;
      requestFileTypeName: string;
    }
  ];
}

interface RequestDetailsProp {
  requestDetails: RequestDetails;
  cartCount: number;
  //updateCartCount: (newCount: number) => void;
}

interface Notes {
  id: string;
  notes: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
}

const { TextArea } = Input;

const ReadyForBillingForm: React.FC<RequestDetailsProp> = ({ requestDetails, cartCount }) => {
  //use info.
  const { account, roles } = useUser();

  //modal handling
  const [modals, setModals] = useState<ModalsState>({
    readyForBilling: false,
    readyForDissemination: false,
  });

  const [newNotes, setNewNotes] = useState<string>('');
  const [billingStatus, setBillingStatus] = useState<BillingStatus[]>([]);
  const [selectedBillingStatus, setSelectedBillingStatus] = useState('Ready for Billing');
  const [loadingReadyForBilling, setLoadingReadyForBilling] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingDisseminate, setLoadingDisseminate] = useState(false);
  const [notes, setNotes] = useState<Notes[]>([]);

  const [disseminationConfirmation, setDisseminationConfirmation] = useState(false);

  const navigate = useNavigate();

  function isReadyForBilling(requestDetails: any): boolean {
    const { requestStatusName, billingStatusName } = requestDetails || {};
    return billingStatusName === 'Not Set' && requestStatusName != 'Completed';
  }

  function isReadyForDissemination(requestDetails: any): boolean {
    const { requestStatusName, billingStatusName } = requestDetails || {};
    return (
      (billingStatusName === 'Billing not Required' ||
        billingStatusName === 'Ready for Billing' ||
        billingStatusName !== 'Not Set') &&
      requestStatusName !== 'Completed'
    );
  }

  const fetchBillingStatuses = async () => {
    try {
      const res = await getBillingStatuses();
      setBillingStatus(res.data);
    } catch (error) {
      console.error('Error fetching billing statuses:', error);
    }
  };

  const handleChangeBillingStatus = async (value: string) => {
    const selectedBilling = billingStatus.find((billing) => billing.id === value);
    const requestId = requestDetails?.id;
    const statusId = selectedBilling.id;
    const statusName = selectedBilling.name;

    try {
      await setCustomerRequestBillingStatus(requestId, statusId);
      setSelectedBillingStatus(statusName);
      notifyUser('success', 'Billing Status Updated.');
    } catch (error) {
      notifyUser('error', 'Error setting the billing status for this request:' + error);
    }
  };

  const handleChangeNewNotes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewNotes(e.target.value);
  };

  const handleAddBillingNotes = async () => {
    //add the new billing notes for this request. this is what CRC will see on the billing screen.
    setLoadingReadyForBilling(true);
    try {
      await addCustomerRequestBillingNotes({
        customerRequestId: requestDetails.id,
        notes: newNotes,
        modifiedBy: account.username, //'andrew.heaven@aer.ca',
      });

      //setNewNotes('');
      notifyUser('success', 'Billing status updated and notes added successfully to the request.');
      setModals({ ...modals, readyForBilling: false });
      //navigate('/disseminatoin/irm/' + requestDetails?.requestNumber);
      navigate('/dissemination/irm/');
    } catch (error) {
      //console.log('Error adding the notes for this request:', error);
      notifyUser('error', 'Error adding the billing notes for this request:' + error);
    }
    setLoadingReadyForBilling(false);
  };

  const handleAddRequestNotes = async () => {
    //add the new notes for this request.
    try {
      await addCustomerRequestNotes({
        customerRequestId: requestDetails.id,
        notes: newNotes,
        createdBy: account.username, //'andrew.heaven@aer.ca',
      });

      setNewNotes('');
      notifyUser('success', 'Billing notes added successfully.');
      setModals({ ...modals, readyForDissemination: false });
    } catch (error) {
      //console.log('Error adding the notes for this request:', error);
      notifyUser('error', 'Error adding the billing notes for this request:' + error);
    }
  };

  //File Upload

  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const handleFileInput = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files ? Array.from(e.target.files) : [];
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleSelectFile = () => {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) fileInput.click();
  };

  const handleUpload = async () => {
    setUploading(true);

    try {
      await uploadFiles(files, requestDetails?.requestNumber);
      notifyUser('success', 'Files uploaded successfully.');
      //message.success(response.message || 'Files uploaded successfully.');
      setFiles([]);
    } catch (error) {
      notifyUser('error', 'Upload failed.');
      //message.error('Upload failed.');
    }
    setUploading(false);
  };

  //File Upload

  // const handlePreview = async (action: boolean) => {
  //   setLoadingPreview(true);
  //   console.log('action:', action);
  //   setLoadingPreview(false);
  // };

  const fetchNotes = async () => {
    const requestId = requestDetails && requestDetails.id;

    try {
      const res = await getNotesByRequestId(requestId);
      setNotes(res[0]);
      console.log('notes data:', res[0]);
    } catch (error) {
      console.error('Error fetching notes for this request:', error);
      notifyUser('error', 'Error fetching notes for this request:' + error);
    }
  };

  //Handle dissemination of files.
  const handleDisseminate = async (disseminate: boolean) => {
    const action = disseminate ? 'Dissemination' : 'Preview';
    let sendEmail;
    let requestStatus;
    let previewUrl;

    if (disseminate) {
      //disseminate files.
      setLoadingPreview(false);
      setLoadingDisseminate(true);
      sendEmail = true;
      requestStatus = 'Completed';
    } else {
      //preview dissemination
      setLoadingPreview(true);
      setLoadingDisseminate(false);
      sendEmail = false;
      requestStatus = requestDetails?.requestStatusName;
    }

    try {
      const res = await getDisseminationRequestPayload(requestDetails?.id, sendEmail);
      console.log('CALL: getDisseminationRequestPayload Response:', res);

      const disseminationResponse = await upsertDisseminationRequest(res.payload, sendEmail);
      previewUrl = `${disseminationResponse.data.requestUrl}`;
      console.log('call: upsertDisseminationRequest Response:', disseminationResponse);

      //Preview request - Open new tab for preview of files.
      if (previewUrl != null && !disseminate) {
        //  If request is being previewed,
        //  1. Open a new tab with the url from the microservice
        //  listing all the files for the dissemination.
        setLoadingPreview(false);
        window.open(previewUrl, '_blank');
      }

      notifyUser('success', `${action} Request Triggered.`);
    } catch (error) {
      setLoadingPreview(false);
      setLoadingDisseminate(false);
      notifyUser('warning', `Unable to process the ${action} request. Error: ${error}`);
    }

    //Save any new notes that is entered while disseminating.
    if (newNotes.length > 0 && disseminate) {
      console.log('CALL: addCustomerRequestNotes');
      try {
        const res = await addCustomerRequestNotes({
          customerRequestId: requestDetails?.id,
          notes: newNotes,
          createdBy: account?.username, //'andrew.heaven@aer.ca',
        });
        console.log('res:', res.data);
        setNewNotes('');
      } catch (error) {
        setLoadingPreview(false);
        setLoadingDisseminate(false);
        notifyUser('warning', `Unable to add the notes for the ${action} request. Error: ${error}`);
      }
    }

    //Update the Request Status after dissemination
    if (disseminate) {
      console.log('CALL: setCustomerRequestStatus');
      try {
        const res = await setCustomerRequestStatus(requestDetails?.id, requestStatus);
        console.log('customer request status update - res:', res);
        console.log('END');
      } catch (error) {
        notifyUser(
          'warning',
          `Unable to set the request status to completed when processing the dissemination request. Error: ${error}`
        );
      }
    }

    //Redirect user to the main manage irm route after dissemination.
    //Otherwise, stay on the dissemination modal for preview.
    if (disseminate) {
      console.log('CALL: navigation to /dissemination/irm');
      setTimeout(() => {
        setLoadingDisseminate(false);
        navigate('/dissemination/irm');
      }, 4000);
    }
  };

  //handle dissemination confirmation
  const handleDisseminationConfirmation = () => {
    setDisseminationConfirmation(!disseminationConfirmation);
  };

  useEffect(() => {
    fetchNotes();
    setSelectedBillingStatus(requestDetails?.billingStatusName);
    fetchBillingStatuses();
  }, [requestDetails]); // Empty dependency array to run only once on mount

  return (
    <>
      {/* Send for Billing */}
      <Modal
        title="Billing Management - Send for Billing"
        //centered
        open={modals.readyForBilling}
        onCancel={() => {
          setModals({ ...modals, readyForBilling: false });
        }}
        width="500px"
        footer={[
          <>
            <Button
              onClick={() => {
                setModals({ ...modals, readyForBilling: false });
              }}
            >
              Close
            </Button>
            <Button
              type="primary"
              disabled={newNotes.length == 0}
              key="save"
              onClick={() => {
                handleAddBillingNotes();
              }}
            >
              {/* {isReadyForBilling ? 'Update Billing Status' : 'Send for Billing'} */}
              Send for Billing
            </Button>
          </>,
        ]}
      >
        <Divider
          type="horizontal"
          style={{ width: '5px', marginTop: '7px', marginBottom: '10px' }}
        ></Divider>
        <Row gutter={24}>
          <Col span={24}>
            <div>
              Choose Billing Status
              <FormItem>
                <Select
                  placeholder="Select a Billing Status"
                  value={selectedBillingStatus}
                  onChange={(value) => {
                    handleChangeBillingStatus(value);
                  }}
                >
                  {Array.isArray(billingStatus) &&
                    billingStatus.map((billing) => (
                      <Select.Option key={billing.id} value={billing.id}>
                        {' '}
                        {/* Use Select.Option here */}
                        {billing.name}
                      </Select.Option>
                    ))}
                </Select>
              </FormItem>
              <p></p>
              {isReadyForBilling ? 'Add Billing Notes' : 'Add Notes for Billing'}
              <TextArea
                style={{ height: 160 }}
                rows={4}
                value={newNotes}
                placeholder="Maximum length is 4000"
                maxLength={4000}
                onChange={handleChangeNewNotes}
              ></TextArea>
            </div>
            <br></br>
          </Col>
        </Row>
        <Divider
          type="horizontal"
          style={{ width: '5px', marginTop: '7px', marginBottom: '10px' }}
        ></Divider>
      </Modal>

      {/* CRC - for Processing Dissemination */}
      <Modal
        title="Billing Management - Review & Disseminate"
        //centered
        open={modals.readyForDissemination}
        //cancelText="Close"
        onCancel={() => {
          setModals({ ...modals, readyForDissemination: false });
        }}
        width="990px"
        footer={[
          <>
            <Button
              onClick={() => {
                setModals({ ...modals, readyForDissemination: false });
              }}
            >
              Close
            </Button>
            <Button
              type="primary"
              disabled={newNotes.length == 0}
              key="save"
              onClick={() => {
                handleAddRequestNotes();
              }}
            >
              Save and Close
            </Button>
            <Button
              key="preview"
              type="primary"
              disabled={
                cartCount > 0 && requestDetails?.requestStatusName !== 'Completed' ? false : true
              }
              loading={loadingPreview}
              onClick={() => {
                //handlePreview(false);
                handleDisseminate(false);
              }}
            >
              <FileSearchOutlined /> Preview
            </Button>
            <Button
              key="submit"
              type="primary"
              disabled={
                (selectedBillingStatus !== 'Billed' &&
                  selectedBillingStatus !== 'Billing not Required') ||
                cartCount == 0 ||
                !disseminationConfirmation
              }
              loading={loadingDisseminate}
              onClick={() => {
                handleDisseminate(true);
              }}
            >
              <SendOutlined /> Disseminate
            </Button>
          </>,
        ]}
      >
        <Divider
          type="horizontal"
          style={{ width: '5px', marginTop: '7px', marginBottom: '10px' }}
        ></Divider>
        <Row gutter={2}>
          <Col span={7}>
            <div>{requestDetails?.description}</div>
            <br></br>
            <span style={{ fontWeight: '500' }}>Request Details</span>
            <p>
              Total File Count: {cartCount}
              <br></br>
              Total File Size: {'TBD'}
            </p>
            <span style={{ fontWeight: '500' }}>Customer Information</span>
            <br></br>
            {requestDetails?.contactName}
            <br></br>
            {requestDetails?.contactEmail}
            <br></br>
            {requestDetails?.contactPhone}
            <br></br>
          </Col>
          <Divider type="vertical" style={{ height: 'auto', alignSelf: 'stretch' }}></Divider>
          <Col span={7}>
            <span style={{ fontWeight: '500' }}>Upload Related Document(s)</span>
            <br></br> <i>(ie. invoices...etc)</i>
            <br></br>
            <br></br>
            <div>
              <input
                type="file"
                multiple
                onChange={handleFileInput}
                style={{ display: 'none' }}
                id="fileInput"
              />
              <label htmlFor="fileInput" className="file-input-label">
                <Button icon={<UploadOutlined />} onClick={handleSelectFile} type="default">
                  Select File(s)
                </Button>
              </label>
              {/* <Button
                  style={{ marginLeft: 10 }}
                  icon={<UploadOutlined />}
                  onClick={handleUpload}
                  disabled={files.length === 0}
                  loading={uploading}
                >
                  {uploading ? 'Uploading' : 'Upload'}
                </Button> */}
              <Button
                icon={<UploadOutlined />}
                style={{ display: 'inline-flex', marginLeft: 7 }}
                type="primary"
                onClick={handleUpload}
                disabled={files.length === 0}
                loading={uploading}
              >
                {uploading ? 'Uploading' : 'Upload'}
              </Button>

              <br></br>
              <br></br>
              <div style={{ fontSize: '0.82em' }}>
                {files.map((file, index) => (
                  <div key={index} className="file-item" style={{ margin: 1 }}>
                    <span>{file.name}</span>
                    <Button
                      //onClick={() => deleteFile(file.name)}
                      icon={<DeleteOutlined />}
                      size="small"
                      type="text"
                      danger
                    />
                  </div>
                ))}
              </div>
              {/* <Button
                type="primary"
                onClick={handleUpload}
                disabled={!file}
                style={{ marginTop: 16 }}
              >
                Upload to Blob
              </Button> */}
            </div>
          </Col>
          <Divider type="vertical" style={{ height: 'auto', alignSelf: 'stretch' }}></Divider>
          <Col span={9}>
            <div>
              <span style={{ fontWeight: '500' }}>Update Billing Status</span>
              <FormItem>
                <Select
                  disabled={requestDetails?.requestStatusName === 'Completed'}
                  placeholder="Select a Billing Status"
                  value={selectedBillingStatus}
                  onChange={(value) => {
                    handleChangeBillingStatus(value);
                  }}
                >
                  {Array.isArray(billingStatus) &&
                    billingStatus.map((billing) => (
                      <Select.Option key={billing.id} value={billing.id}>
                        {' '}
                        {/* Use Select.Option here */}
                        {billing.name}
                      </Select.Option>
                    ))}
                </Select>
              </FormItem>
              <p></p>
              Add Billing Notes <i>(Note: This is not sent to the customer)</i>
              <TextArea
                rows={4}
                value={newNotes}
                style={{ height: 170 }}
                placeholder="Max length is 4000"
                maxLength={4000}
                onChange={handleChangeNewNotes}
              ></TextArea>
            </div>
            <br></br>
            <span style={{ fontWeight: '500' }}>Dissemination Confirmation</span>
            <br></br>
            <span style={{ fontSize: '0.85em' }}>
              The selected and additional files will be disseminated to the customer. The customer
              will received an email with the link to download the files for each product selected.
              <br></br>
            </span>
            <br></br>
            <Checkbox
              disabled={
                cartCount > 0 && requestDetails?.requestStatusName !== 'Completed' ? false : true
              }
              onClick={() => {
                handleDisseminationConfirmation();
              }}
            >
              Yes, I confirm the dissemination.
            </Checkbox>
            <p></p>
          </Col>
        </Row>
        <Divider
          type="horizontal"
          style={{ width: '5px', marginTop: '7px', marginBottom: '10px' }}
        ></Divider>
      </Modal>

      {/* Dissemination */}

      {/* Dissemination */}

      <Tooltip title={'Send for Billing Processing'}>
        <Button
          style={{
            backgroundColor: isReadyForBilling(requestDetails) && cartCount > 0 ? 'green' : 'white',
            color: isReadyForBilling(requestDetails) && cartCount > 0 ? 'white' : 'gray',
            margin: '0 5px 0 10px',
            // display: isReadyForBilling(requestDetails) ? 'inline-flex' : 'none',
          }}
          disabled={!isReadyForBilling(requestDetails) && cartCount > 0}
          size="middle"
          // type="primary"
          onClick={() => {
            setModals({ ...modals, readyForBilling: true });
          }}
        >
          <CheckCircleOutlined /> Send for Billing
        </Button>
      </Tooltip>

      <Tooltip title={'Review Request & Disseminate'}>
        <Button
          style={{
            backgroundColor:
              isReadyForDissemination(requestDetails) && cartCount > 0 ? 'green' : 'white',
            color: isReadyForDissemination(requestDetails) && cartCount > 0 ? 'white' : 'gray',
            margin: '0 5px 0 5px',
            // display:
            //   isReadyForDissemination(requestDetails) || cartCount > 0 ? 'inline-flex' : 'none',
          }}
          size="middle"
          // className="ant-btn-success"
          disabled={!isReadyForDissemination(requestDetails) || cartCount === 0}
          onClick={() => {
            setModals({ ...modals, readyForDissemination: true });
          }}
        >
          <SendOutlined /> Review & Disseminate
        </Button>
      </Tooltip>
    </>
  );
};

export default ReadyForBillingForm;
