import React, { useEffect, useState } from 'react';
import '../../App.css';
import { config } from '../../config';
import { getAppVersion } from '../../services/api';
const environment = config.REACT_APP_ENV;

export const AppFooter: React.FC = () => {
  const [version, setVersion] = useState<string>('');

  const fetchVersion = async () => {
    try {
      const response = await getAppVersion(); // Adjust URL based on your setup
      setVersion(response.data.version); // Update the state with the version info
    } catch (error) {
      console.error('Error fetching version:', error);
    }
  };

  // Use the effect hook to fetch version when the component loads
  useEffect(() => {
    fetchVersion();
  }, []);

  return (
    <>
      <div className="Landing-page-footer">
        <strong>
          {environment?.toUpperCase()} - {version} - Protected B{' '}
        </strong>
        | © {new Date().getFullYear()} Dissemination Tool. All Rights Reserved.
      </div>
    </>
  );
};
