import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select, Space } from 'antd';
import { getBillingStatuses, getRequestStatuses, getRequestTypes } from '../../../../services/api';

const { Option } = Select;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 20 },
};

const tailLayout = {
  wrapperCol: { offset: 12, span: 10 },
};

interface SearchFormProps {
  onSearch: (searchParams: { [key: string]: string }) => void;
}

interface RequestType {
  id: string;
  name: string;
}

interface RequestStatus {
  id: string;
  name: string;
}

interface BillingStatus {
  id: string;
  name: string;
}

const ManageIRMRequestSearchForm: React.FC<SearchFormProps> = ({ onSearch }) => {
  const [form] = Form.useForm();

  const [requestTypes, setRequestTypes] = useState<RequestType[]>([]);
  const [requestStatuses, setRequestStatuses] = useState<RequestStatus[]>([]);
  const [billingStatuses, setBillingStatuses] = useState<BillingStatus[]>([]);

  const fetchData = async () => {
    const requestTypesResponse = await getRequestTypes();
    const requestStatusesResponse = await getRequestStatuses();
    const billingStatusesResponse = await getBillingStatuses();
    setRequestTypes(requestTypesResponse.data);
    setRequestStatuses(requestStatusesResponse.data);
    setBillingStatuses(billingStatusesResponse.data);
  };

  // Fetch request types and file types from the database
  useEffect(() => {
    fetchData();
  }, []);

  const initialFields = {
    requestNumber: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    companyName: '',
    requestType: '',
    requestStatus: '',
    billingStatus: '',
  };

  const [searchFields, setSearchFields] = useState(initialFields);

  const handleChange = (name: string, value: string) => {
    setSearchFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleSearch = () => {
    onSearch(searchFields);
    //console.log(searchFields);
  };

  const onReset = () => {
    console.log('reset');
    form.resetFields();
    setSearchFields(initialFields);
    fetchData();
    onSearch(searchFields);
  };

  return (
    <>
      <Form
        {...layout}
        form={form}
        name="control-hooks"
        //onFinish={onFinish}
        style={{ maxWidth: 600 }}
        size="middle"
      >
        <Form.Item name="requestNumber" label="IRM Number">
          <Input
            name="requestNumber"
            placeholder="Request Number"
            value={searchFields.requestNumber}
            onChange={(e) => handleChange('requestNumber', e.target.value)}
          />
        </Form.Item>
        <Form.Item name="contactEmail" label="Customer Email">
          <Input
            name="contactEmail"
            placeholder="Contact Email"
            value={searchFields.contactEmail}
            onChange={(e) => handleChange('contactEmail', e.target.value)}
          />
        </Form.Item>
        <Form.Item name="contactName" label="Customer Name">
          <Input
            name="contactName"
            placeholder="Contact Name"
            value={searchFields.contactName}
            onChange={(e) => handleChange('contactName', e.target.value)}
          />
        </Form.Item>
        <Form.Item name="contactPhone" label="Customer Phone">
          <Input
            name="contactPhone"
            placeholder="Contact Email"
            value={searchFields.contactPhone}
            onChange={(e) => handleChange('contactPhone', e.target.value)}
          />
        </Form.Item>
        <Form.Item name="companyName" label="Company">
          <Input
            name="companyName"
            placeholder="Company Name"
            value={searchFields.companyName}
            onChange={(e) => handleChange('companyName', e.target.value)}
          />
        </Form.Item>

        <Form.Item name="requestType" label="Request Type">
          <Select
            placeholder="Request Type"
            value={searchFields.requestType}
            onChange={(value) => handleChange('requestType', value)}
          >
            {Array.isArray(requestTypes) &&
              requestTypes.map((requestType) => (
                <Option key={requestType.id} value={requestType.id}>
                  {requestType.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="requestStatus" label="Request Status">
          <Select
            placeholder="Request Status"
            value={searchFields.requestStatus}
            onChange={(value) => handleChange('requestStatus', value)}
          >
            {Array.isArray(requestStatuses) &&
              requestStatuses.map((requestStatus) => (
                <Option key={requestStatus.id} value={requestStatus.id}>
                  {requestStatus.name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item name="billingStatus" label="Billing Status">
          <Select
            placeholder="Billing Status"
            value={searchFields.billingStatus}
            onChange={(value) => handleChange('billingStatus', value)}
          >
            {Array.isArray(billingStatuses) &&
              billingStatuses.map((billingStatus) => (
                <Option key={billingStatus.id} value={billingStatus.id}>
                  {billingStatus.name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Space>
            <Button type="default" onClick={onReset}>
              Reset
            </Button>
            <Button type="primary" htmlType="submit" onClick={handleSearch}>
              Search
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default ManageIRMRequestSearchForm;
