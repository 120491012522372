import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { config } from './config';
import { pca } from './authConfig';

const httpLink = new HttpLink({
  uri: config.REACT_APP_SERVICE_URI,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const authLink = setContext(async (_, { headers }) => {
  const account = pca.getActiveAccount();
  if (!localStorage.getItem('accessToken')) {
    try {
      const tokenResponse = await pca.acquireTokenSilent({
        account: account || undefined,
        scopes: ['profile', 'openid', 'email', 'User.Read'],
      });
      localStorage.setItem('accessToken', tokenResponse.accessToken);
    } catch (err) {
      // ignore this as non logged in Users are allowed
    }
  }
  const token = localStorage.getItem('accessToken');

  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

export default apolloClient;
