import React, { useState } from 'react';
import type { RadioChangeEvent, UploadProps } from 'antd';
import { Button, Col, Radio, Row, Space, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { UploadOutlined } from '@ant-design/icons';
import notifyUser from '../../../../utils/notifyUser';

const WellSearchFormByImport = () => {
    const [importOptionValue, setImportOptionValue] = useState(1);
    const [copyPasteUWIValue, setCopyPasteUWIValue] = useState('');
    const [copyPasteLicenceValue, setCopyPasteLicenceValue] = useState('');
    const [fileList, setFileList] = useState([]);

    // Method to check if the file is an Excel file
    const checkFileType = (file: File): boolean => {
        return file.type === 'application/vnd.ms-excel' ||
            file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file.type === 'application/vnd.ms-excel.sheet.macroEnabled.12';
    };

    // Reusable beforeUpload handler
    const beforeUploadHandler = (file: File): boolean | string => {

        const isExcel = checkFileType(file); // Check if it's an Excel file
        if (!isExcel) {
            notifyUser('warning', `${file.name} is not a valid Excel file`);
            //message.error(`${file.name} is not a valid Excel file - ${file.type}`);
            return Upload.LIST_IGNORE; // Return Upload.LIST_IGNORE if not valid
        }
        return true; // Return true if the file is valid
    };

    // propsPrettyUwi using the reusable beforeUpload handler
    const propsPrettyUwi: UploadProps = {
        beforeUpload: beforeUploadHandler,
        onChange: (info) => {
            setFileList(info.fileList);
            console.log(info.fileList);
        },
    };

    // propsDBUwi using the reusable beforeUpload handler
    const propsDBUwi: UploadProps = {
        beforeUpload: beforeUploadHandler,
        onChange: (info) => {
            setFileList(info.fileList);
            console.log(info.fileList);
        },
    };

    // propsLicence using the reusable beforeUpload handler
    const propsLicence: UploadProps = {
        beforeUpload: beforeUploadHandler,
        onChange: (info) => {
            setFileList(info.fileList);
            console.log(info.fileList);
        },
    };

    const onChange = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setImportOptionValue(e.target.value);
    };
    return (
        <>
            <div>
                <span>Choose an import option</span><br></br>
                <Row>
                    <Col span={11}>
                        <Radio.Group onChange={onChange} value={importOptionValue}>
                            <Space direction="vertical">
                                <Radio value={1}>Import Pretty UWis</Radio>
                                <Radio value={2}>Import DB UWIs</Radio>
                                <Radio value={3}>Import By Licence</Radio>
                                <Radio value={4}>Copy/Paste UWIs (Pretty)</Radio>
                                <Radio value={5}>Copy/Paste Licence</Radio>
                            </Space>
                        </Radio.Group>
                    </Col>
                    <Col span={13}>

                        {/* Search by Excel Import - Pretty UWI */}
                        <div style={{ display: (importOptionValue == 1 ? 'block' : 'none') }}>
                            <Upload {...propsPrettyUwi}>
                                <Button icon={<UploadOutlined />}>Upload Excel File Only</Button>
                            </Upload>

                            <br></br>
                            <Button type='primary' disabled={fileList.length == 0 ? true : false}>Perform Search</Button>

                        </div>

                        {/* Search by Excel Import - DB UWI */}
                        <div style={{ display: (importOptionValue == 2 ? 'block' : 'none') }}>
                            <Upload {...propsDBUwi}>
                                <Button icon={<UploadOutlined />}>Select UWI List (xls, xlsx)</Button>
                            </Upload>

                            <br></br>
                            <Button type='primary' disabled={fileList.length == 0 ? true : false}>Perform Search</Button>

                        </div>

                        {/* Search by Excel Import - Licence */}
                        <div style={{ display: (importOptionValue == 3 ? 'block' : 'none') }}>
                            <Upload {...propsLicence}>
                                <Button icon={<UploadOutlined />}>Select Licence List (xls, xlsx)</Button>
                            </Upload>

                            <br></br>
                            <Button type='primary' disabled={fileList.length == 0 ? true : false}>Perform Search</Button>

                        </div>

                        {/* Search by Copy/Paste - UWI */}
                        <div style={{ display: (importOptionValue == 4 ? 'block' : 'none') }}>
                            <TextArea
                                value={copyPasteUWIValue}
                                onChange={(e) => setCopyPasteUWIValue(e.target.value)}
                                placeholder="Copy/paste your list of uwis separated by commas."
                                autoSize={{ minRows: 3, maxRows: 7 }}
                            />
                            <br></br><br></br>
                            <Button type='default'>Reset</Button>&nbsp;
                            <Button type='primary'>Perform Search</Button>

                        </div>

                        {/* Search by Copy/Paste - Licence */}
                        <div style={{ display: (importOptionValue == 5 ? 'block' : 'none') }}>
                            <TextArea
                                value={copyPasteLicenceValue}
                                onChange={(e) => setCopyPasteLicenceValue(e.target.value)}
                                placeholder="Copy/paste your list of licences separated by commas."
                                autoSize={{ minRows: 3, maxRows: 7 }}
                            />
                            <br></br><br></br>
                            <Button type='default'>Reset</Button>&nbsp;
                            <Button type='primary'>Perform Search</Button>
                        </div>
                    </Col>


                </Row>

            </div>
        </>
    );
};

export default WellSearchFormByImport;