import axios from 'axios';
import { config } from '../config';

//=======================QUERIES=====================================

// get the server domain root api
const apiUrl = config.API_URL;

// Download

// Fetch selected blob file.
export const downloadFile = async (container: string, fileName: string) => {
  const downloadedFile = await axios.get(`${apiUrl}/download/${container}/${fileName}`, {
    responseType: 'blob',
  });

  const blob = new Blob([downloadedFile.data], { type: downloadedFile.headers['content-type'] });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;

  return link;
};

export const uploadFiles = async (fileList: File[], requestNumber: string): Promise<any> => {
  const formData = new FormData();
  fileList.forEach((file) => {
    formData.append('files', file);
  });
  const response = await axios.post(
    `${apiUrl}/files/upload?requestNumber=${requestNumber}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  return response.data;
};

export const getAdditionalFiles = async (folderName: string) => {
  try {
    const response = await axios.get(`${apiUrl}/files/getFiles/folder/${folderName}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching files:', error);
    return null;
  }
};

export const deleteAdditionalFile = async (folderName: string, fileName: string) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/files/getFiles/folder/${folderName}/${fileName}`
    );
    return response.status === 200 ? 'Success' : 'Unexpected Error';
  } catch (error) {
    console.error('Error fetching files:', error);
    return null;
  }
};
