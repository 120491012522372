import axios from 'axios';
import { config } from '../config';

//=======================QUERIES=====================================

// get the server domain root api
const apiUrl = config.API_URL;

//Get Build Version
export const getAppVersion = async () => {
  return axios.get(`${apiUrl}/version`);
};

// REQUEST TYPE

// Fetch all Request Types available.
export const getRequestTypes = async () => {
  //console.log(`api url: ${apiUrl}`);
  return await axios.get(`${apiUrl}/requestType`);
};

// REQUEST STATUS

// Fetch all Request Statuses available.
export const getRequestStatuses = async () => {
  return await axios.get(`${apiUrl}/requestStatus`);
};

// BILLING STATUS

// Fetch all Billing Status available.
export const getBillingStatuses = async () => {
  //console.log(`api url: ${apiUrl}`);
  return await axios.get(`${apiUrl}/billingStatus`);
};

export const getIdBillingStatusByName = async (name: string) => {
  return await axios.get(`${apiUrl}/billingStatus/getIdByName/${name}`);
};

// PAYMENT TYPE

// Fetch all Payment Type available.
export const getPaymentTypes = async () => {
  return await axios.get(`${apiUrl}/paymentType`);
};

// Fetch all Payment Type available.
export const getIdPaymentTypeByName = async (name: string) => {
  return await axios.get(`${apiUrl}/paymentType/getIdByName/${name}`);
};

//Fetch Id for Request Status by Name.
//This can be used to set the status of the request in the Customer Request to the status id required.
// Available status names: New, Incompleted, Completed, Stopped.
//For new requests being generated, the "New" status is added to each request by status id.
export const getIdRequestStatusByName = async (name: string) => {
  return await axios.get(`${apiUrl}/requestStatus/getIdByName/${name}`);
};

/* FILE TYPE */

//Fetch all the File Types available.
export const getFileTypes = async () => {
  return await axios.get(`${apiUrl}/fileType`);
};

//Get the container name by the File Type Id.
export const getContainerNameByFileTypeId = async (id: string) => {
  return await axios.get(`${apiUrl}/fileType/getContainerName/${id}`);
};

/* CUSTOMER REQUEST */

//Load initial customer requests.
export const getInitialCustomerRequests = async () => {
  return await axios.get(`${apiUrl}/customerRequest/`);
};

//Load request details by request number.
export const getRequestDetailsByRequestNumber = async (requestNumber: string) => {
  return await axios.get(`${apiUrl}/customerRequest/irm/${requestNumber}`);
};

//Load File Types by request number
export const getFileTypesByRequestNumber = async (requestNumber: string) => {
  return await axios.get(`${apiUrl}/customerRequestFileType/irm/${requestNumber}`);
};

//Check if the request number already exists.
export const checkCustomerRequestExists = async (requestNumber: string) => {
  return await axios.get(`${apiUrl}/customerRequest/check/${requestNumber}`);
};

/* CUSTOMER REQUEST FILE */

//Load all files by requestId - used for the Shopping Cart badge.
export const getFilesByRequestId = async (requestId: string) => {
  const fileList = await axios.get(`${apiUrl}/customerRequestFile/filesByRequestId/${requestId}`);
  return fileList.data;
};

//Load file count by requestId - used for the Shopping Cart badge.
export const getFileCountByRequestId = async (requestId: string) => {
  const fileCount = await axios.get(
    `${apiUrl}/customerRequestFile/fileCountByRequestId/${requestId}`
  );
  return fileCount.data;
};

//Generate Dissemination Request Payload
export const getDisseminationRequestPayload = async (requestId: string, sendEmail: boolean) => {
  const payload = await axios.get(
    `${apiUrl}/customerRequest/disseminate/generatePayload/${requestId}/${sendEmail}`
  );
  return payload.data;
};

//Get Dissemination Request Status from Microservice
export const getDisseminationRequestStatus = async (requestId: string) => {
  const payload = await axios.get(`${apiUrl}/customerRequest/disseminate/getStatus/${requestId}`);
  return payload.data;
};

//===========NOTES
//Load all Notes by requestId
export const getNotesByRequestId = async (requestId: string) => {
  const noteList = await axios.get(`${apiUrl}/customerRequestNote/notesByRequestId/${requestId}`);
  return noteList.data;
};

//===========CORP QUERIES
export const getWellsByUWI = async (query: any) => {
  return await axios.post(`${apiUrl}/corp/wellLog/searchByUwi`, {
    params: query,
  });
  //return await axios.get(`${apiUrl}/corp/wellLog/searchWellBy/uwi/`, { params: query });
};

//===========WELL LOG FILES QUERIES
export const getAssociatedWellLogFiles = async (uwis: any, docType: any) => {
  //console.log('Query Well Log Files: ', query);
  return await axios.get(`${apiUrl}/wellLog/uwi/${uwis}/${docType}`);
};

//=======================POST/MUTATIONS=====================================

//Perform search on Customer Requests based on search criteria.
export const searchCustomerRequests = async (searchParams: any) => {
  return await axios.post(`${apiUrl}/customerRequest/search`, { params: searchParams });
};

//Pause Dissemination
export const pauseDisseminationRequest = async (requestId: string) => {
  return await axios.put(`${apiUrl}/customerRequest/pause/${requestId}`);
};

//Resend Dissemination
export const resendDisseminationRequest = async (requestId: string) => {
  return await axios.put(`${apiUrl}/customerRequest/resend/${requestId}`);
};

//Update the customer request status
export const setCustomerRequestStatus = async (requestId: string, status: string) => {
  return await axios.put(`${apiUrl}/customerRequest/updateRequestStatus/${requestId}/${status}`);
};

//Update the customer request status
export const setCustomerRequestBillingStatus = async (
  requestId: string,
  billingStatusId: string
) => {
  return await axios.put(
    `${apiUrl}/customerRequest/updateBillingStatus/${requestId}/${billingStatusId}`
  );
};

//Delete selected customer request.
export const deleteCustomerRequest = async (requestId: string) => {
  return await axios.delete(`${apiUrl}/customerRequest/${requestId}`);
};

//Delete selected customer request notes.
export const deleteCustomerRequestNotes = async (noteId: string) => {
  return await axios.delete(`${apiUrl}/customerRequestNote/${noteId}`);
};

//Delete Dissemination Request from Microservice
export const deleteDisseminationRequest = async (requestId: string) => {
  return await axios.delete(`${apiUrl}/customerRequest/disseminate/deleteRequest/${requestId}`);
};

//Create new customer request
export const createCustomerRequest = async (params: any) => {
  //console.log(params);
  return await axios.post(`${apiUrl}/customerRequest`, {
    params: params,
  });
};

//Create the associated file types selected from
export const createCustomerRequestFileType = async (params: any) => {
  return await axios.post(`${apiUrl}/customerRequestFileType`, {
    customerRequestId: params.customerRequestId,
    fileTypeId: params.fileTypeId,
    createdBy: params.createdBy,
  });
};

/* CUSTOMER REQUEST FILE - Add Files to Cart - Works */
export const addCustomerRequestFiles = async (filesSelection: any[]) => {
  return await axios.post(`${apiUrl}/customerRequestFile/addFilesByUWI`, {
    params: filesSelection,
  });
};

/* CUSTOMER REQUEST FILE - Remove Files from Cart - Works */
export const removeCustomerRequestFiles = async (filesSelection: any[]) => {
  console.log(filesSelection)
  return await axios.delete(`${apiUrl}/customerRequestFile/removeFilesByUWI`, {
    data: filesSelection,
  });
};

/* CUSTOMER REQUEST NOTES */
//Add New Notes
export const addCustomerRequestNotes = async (newNotesParams: any) => {
  //return 'notes';
  return await axios.post(`${apiUrl}/customerRequestNote/addNotes`, {
    params: newNotesParams,
  });
};

//Edit Notes
export const editCustomerRequestNotes = async (newNotesParams: any) => {
  //return 'notes';
  return await axios.put(`${apiUrl}/customerRequestNote/editNotes`, {
    params: newNotesParams,
  });
};

//Update Billing Notes in Description Column
export const addCustomerRequestBillingNotes = async (newBillingNotesParams: any) => {
  //return 'notes';
  return await axios.post(`${apiUrl}/customerRequest/addBillingNotes`, {
    params: newBillingNotesParams,
  });
};

//Upsert Dissemination Request
export const upsertDisseminationRequest = async (payload: any, sendEmail: boolean) => {
  return await axios.post(`${apiUrl}/customerRequest/disseminate`, {
    query: payload,
    sendEmail: sendEmail,
  });
};
