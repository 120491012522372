import { UserOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { Avatar } from 'antd';
import React, { useEffect, useState } from 'react';

export const UserInfo: React.FC = () => {
  const { instance } = useMsal();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const currentAccount = instance.getActiveAccount();
    //console.log('currentAccount:', currentAccount);
    if (currentAccount) {
      setName(currentAccount.idTokenClaims.name);
      setEmail(currentAccount.username);
    }
  }, [instance]);

  return (
    <>
      <div style={{
        display: 'inline-block',
        textAlign: 'right',
        color: 'black',
        marginTop: '0px',
        marginLeft: '2px',
        marginRight: '7px'
      }}>
        <div style={{
          display: 'block',
          fontWeight: 'bold',
          fontSize: '0.85em',
          marginBottom: '2px',
          padding: '0 1px',
          lineHeight: '1.2',
          zIndex: 1000,

        }}>
          {name}
        </div>
        <div style={{
          display: 'block',
          padding: '0 2px',
          fontSize: '0.78em',
          lineHeight: '1.2'
        }}>
          {email}
        </div>
      </div>

      <div
        style={{
          position: 'relative',
          marginTop: '0px',
          marginRight: '8px',
          display: 'inline',
          border: '0px solid #222'
        }}><Avatar icon={<UserOutlined />} />
      </div>
    </>
  );
};
