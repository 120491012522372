import React, { createContext, useContext, useMemo, ReactNode } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import axios from 'axios';
import { getAssociatedWellLogFiles, getWellsByUWI } from '../services/api';

const queryClient = new QueryClient();
const DataContext = createContext<any>(null); // Adjust the type if you know the exact data structure

// Define types for well and file data
export interface WellInfo {
  uwi: string;
  historicalUwi: string;
  licence: string;
  confStatus: string;
  confRelease: string;
  wellName: string;
  finalDrillDate: string;
  finalTd: number;
  source: string;
  township: string;
  meridian: string;
  range: string;
  section: string;
  lsd: string;
  files: FileInfo[];
}

export interface FileInfo {
  ID: string;
  UWI: string;
  Source: string;
  DocumentType: string;
  Licence: string;
  FileName: string;
  RunDate: string;
  Start: string;
  Stop: string;
  Step: string;
  TVDMD: string;
  ServiceCompany: string;
  LegacyCode: string;
  LogType: string;
  fileTypeId: string; //not from dataset results.
  ConfStatus: string;
}

// Fetch well information from server A
const fetchWellInfo = async (searchList: string[], searchType: string): Promise<WellInfo[]> => {
  //search Types: uwi, licence

  console.log('searchList:', searchList, 'searchType:', searchType);

  const response =
    searchType === 'uwi' ? await getWellsByUWI([searchList]) : await getWellsByUWI([searchList]); //replace this with

  //console.log('well data:', response.data);
  //return response.data[0];
  return Array.isArray(response.data[0]) ? response.data[0] : [];
};

// Helper function to format the UWI based on the conditions
// This is to format the well uwi for greater than 9. if the well uwi is 13, ensure a 0 is prefixed to the event sequence ( <= 9 )
const formatUwi = (uwi: string): string => {
  if (uwi.length === 13) {
    return uwi.endsWith('0') ? uwi + '0' : uwi.slice(0, -1) + '0' + uwi.slice(-1);
  }
  return uwi; // Return as is if length is already 14
};

const formatUwiArray = (uwiArray: string[]): string[] => {
  return uwiArray.map((uwi) => {
    // Only format if the length is exactly 13
    if (uwi.length === 13) {
      // Check if the last character is '0' or if the event sequence is 9 or less
      const lastChar = parseInt(uwi.slice(-1), 10);
      if (lastChar <= 9) {
        return uwi + '0'; // Append '0' at the end for sequences 0-9
      }
    }
    return uwi; // Return as is if length is already 14 or event sequence is > 9
  });
};

// Fetch files for a list of UWIs from server B
const fetchFilesByUWIs = async (uwiList: string[], docType: string): Promise<FileInfo[]> => {
  const response = await getAssociatedWellLogFiles(formatUwiArray(uwiList), docType);
  console.log('WellFileDataProvider: fetchFilesByUWIs:', response.data);
  //return Array.isArray(response.data) ? response.data : [];
  return Array.isArray(response.data)
    ? response.data.map((file: FileInfo) => ({ ...file })) // Attach fileTypeId
    : [];
};

// Combine well and file data based on UWI
// const combineData = (wellInfo: WellInfo[], files: FileInfo[] = [], fileTypeId: string) => {
//   // Ensure files is an array before using .filter
//   return wellInfo.map(well => ({
//     ...well,
//     //files: Array.isArray(files) ? files.filter(file => file.UWI == well.uwi) : [],
//     files: files.filter(file => file.UWI === formatUwi(well.uwi)),
//     fileTypeId,
//   }));
// };

// Combine well and file data based on UWI, adding WellInfo fields to each file
const combineData = (fileTypeId: string, wellInfo: WellInfo[], files: FileInfo[] = []) => {
  return wellInfo.map((well) => ({
    ...well,
    // Attach WellInfo fields to each file
    files: files
      .filter((file) => file.UWI == formatUwi(well.uwi))
      .map((file) => ({
        ...file,
        Licence: well.licence, // Add WellInfo's licence to each FileInfo
        ConfStatus: well.confStatus, // Add WellInfo's confStatus to each FileInfo
        fileTypeId,
      })),
    //fileTypeId,
  }));
};

// Data provider component
export const DataProvider = ({
  children,
  fileTypeId,
  searchList,
  searchType,
  docType,
}: {
  children: ReactNode;
  fileTypeId: string;
  searchList: string[];
  searchType: string;
  docType: string;
}) => {
  // Fetch wells data
  const { data: wellInfo } = useQuery(
    ['wellInfo', searchList, searchType],
    () => fetchWellInfo(searchList, searchType),
    { enabled: searchList.length > 0, staleTime: 60000 }
  );

  // const { data: files } = useQuery(
  //   ['files', wellInfo],
  //   () => fetchFilesByUWIs(wellInfo?.map(well => well.uwi) || []),
  //   { enabled: !!wellInfo, staleTime: 60000 }
  // );

  // 2. Fetch files based on UWIs only when wellInfo has data
  const { data: files } = useQuery<FileInfo[]>(
    ['files', wellInfo, docType],
    () => fetchFilesByUWIs(wellInfo?.map((well) => well.uwi) || [], docType),
    { enabled: Array.isArray(wellInfo) && wellInfo.length > 0, staleTime: 60000 }
  );

  // Logging fetched data to check if they are populated
  console.log('Fetched WellInfo:', wellInfo);
  console.log('Fetched Files:', files);

  // Combine data once both wellInfo and files are available
  const combinedData = useMemo(() => {
    if (wellInfo && files) {
      const data = combineData(fileTypeId, wellInfo, files);
      console.log('Combined Data:', data);
      return data;
      //if (wellInfo && files) return combineData(fileTypeId, wellInfo, files);
    }
  }, [fileTypeId, wellInfo, files]);

  return <DataContext.Provider value={combinedData}>{children}</DataContext.Provider>;
};

// Custom hook for accessing combined data
export const useData = () => useContext(DataContext);
