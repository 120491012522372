import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import React from 'react';
import appRoutes from './services/routes';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import apolloClient from './apolloClient';
import { ProtectedRoute } from './components/ProtectedRoute';
import { UserProvider } from './contexts/UserContext';
 
const queryClient = new QueryClient();
 
const App = ({ msalInstance }: any) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={apolloClient}>
        <MsalProvider instance={msalInstance}>
          <UserProvider>
            <Router>
              <Routes>
                {appRoutes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      route.requiredRoles ? (
                        <AuthenticatedTemplate>
                          <ProtectedRoute requiredRoles={route.requiredRoles}>
                            <route.component />
                          </ProtectedRoute>
                        </AuthenticatedTemplate>
                      ) : (
                        <route.component />
                      )
                    }
                  />
                ))}
              </Routes>
            </Router>
          </UserProvider>
        </MsalProvider>
      </ApolloProvider>
    </QueryClientProvider>
  );
};
 
export default App;