/* eslint-disable max-lines */
import React, { ReactHTMLElement, useEffect, useState } from 'react';
import AppLayout from '../../../components/Layout/AppLayout';
import { AppFooter } from '../../../components/Layout/AppFooter';
import {
  Card,
  Row,
  Col,
  Button,
  Divider,
  Tag,
  Timeline,
  Tabs,
  Drawer,
  Form,
  Input,
  Spin,
  Select,
  InputNumber,
  Statistic,
  Tooltip,
} from 'antd';
import type { TabsProps } from 'antd';
import { config } from '../../../config';
//import WellSearchResults from '../../../components/dissemination/irm/WellSelectionSearchResults_bk';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CheckCircleFilled,
  ClockCircleOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import WellSelectionPageOptions from '../../../components/dissemination/irm/WellSelectionPageOptions';
import {
  getFileTypesByRequestNumber,
  getRequestDetailsByRequestNumber,
  getWellsByUWI,
  getFileCountByRequestId,
} from '../../../services/api';

import RequestActions from '../../../components/dissemination/irm/RequestActions';
import Breadcrumbs from '../../../components/Layout/Breadcrumbs';
import WellSearchFormByImport from '../../../components/dissemination/irm/form/WellLogSearchByImportForm';
import RequestedProducts from '../../../components/dissemination/irm/RequestedProductsList';
import UploadAdditionalFile from '../../../components/dissemination/irm/form/UploadAdditionalFileForm';
import WellSearchResults from '../../../components/dissemination/irm/WellSelectionSearchResults';
import { DataProvider } from '../../../providers/WellFileDataProvider';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import WellSearchFormByLicence from '../../../components/dissemination/irm/form/WellLogSearchByLicenceForm';
import WellSearchFormByArea from '../../../components/dissemination/irm/form/WellLogSearchByAreaForm';
import WellSearchFormBySubscription from '../../../components/dissemination/irm/form/WellLogSearchBySubscriptionForm';
import FileSynchronizationForm from '../../../components/dissemination/irm/form/FileSynchronizationForm';
import ModifyRequestProductListForm from '../../../components/dissemination/irm/form/ModifyRequestProductListForm';
import notifyUser from '../../../utils/notifyUser';
import {
  validateAndFormatEs,
  validateAndFormatLe,
  validateAndFormatLsd,
  validateAndFormatM,
  validateAndFormatRg,
  validateAndFormatSe,
  validateAndFormatTwp,
} from '../../../utils/inputUwiValidators';
import ReadyForBillingForm from '../../../components/dissemination/irm/form/BillingManagementForm';
import RequestNotesForm from '../../../components/dissemination/irm/form/RequestNotesForm';

const { Option } = Select;

const queryClient = new QueryClient();

interface FileType {
  id: string;
  name: string;
  containerName: string;
}

interface RequestProps {
  requestNumber: string;
}

interface RequestDetails {
  id: string;
  requestNumber: string;
  description: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  companyName: string;
  requestTypeName: string;
  requestStatusName: string;
  billingStatusId: string;
  billingStatusName: string;
  paymentTypeName: string;
  requestFileTypes: [
    {
      requestFileTypeId: string;
      requestFileTypeName: string;
    }
  ];
}

interface RequestFiles {
  id: string;
  customerRequestId: string;
  fileTypeId: string;
  fileName: string;
  filePath: string;
  fileSize: string;
  associatedUWI: string;
  associatedLicence: string;
  confidentialityStatus: string;
  containerName: string;
}
export interface WellInfo {
  uwi: string;
  historicalUwi: string;
  licence: string;
  confStatus: string;
  confRelease: string;
  wellName: string;
  finalDrillDate: string;
  finalTd: number;
  source: string;
  township: string;
  meridian: string;
  range: string;
  section: string;
  lsd: string;
}

interface RequestedFileTypes {
  id: string;
  name: string;
}

interface InputStatusUWI {
  twp: '' | 'error' | 'warning' | null;
  m: '' | 'error' | 'warning' | null;
  rg: '' | 'error' | 'warning' | null;
  se: '' | 'error' | 'warning' | null;
  lsd: '' | 'error' | 'warning' | null;
  le: '' | 'error' | 'warning' | null;
  es: '' | 'error' | 'warning' | null;
}

const WellFileSelection: React.FC = () => {
  const initialStateUWISearch = {
    le: '',
    lsd: '',
    se: '',
    twp: '',
    rg: '',
    m: '',
    es: '',
  };

  const navigate = useNavigate();

  //Search Parameters - UWI
  const [searchParamsUWI, setSearchParamsUWI] = useState(initialStateUWISearch);

  const [searchResults, setSearchResults] = useState<Array<{ [key: string]: any }>>([]);
  //const [fileResults, setFileResults] = useState<Array<{ [key: string]: any }>>([]);

  //used to set the file count for the request on the cart badge.
  //const [requestFileCount, setRequestFileCount] = useState<number | null>(null);

  const { requestNumber } = useParams<{ requestNumber: string }>();

  const [loadingRequestedProducts, setLoadingRequestedProducts] = useState<boolean>(false);
  const [loadingRequestDetails, setLoadingRequestDetails] = useState<boolean>(false);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  //const [loadingFileResults, setLoadingFileResults] = useState<boolean>(false);

  const [fileTypes, setFileTypes] = useState<FileType[]>([]);
  const [requestDetails, setRequestDetails] = useState<RequestDetails>(null);
  const [selectedDocType, setSelectedDocType] = useState('ALL');
  const [selectedFileTypeId, setSelectedFileTypeId] = useState<string | undefined>(undefined);
  const [selectedFileType, setSelectedFileType] = useState<string | undefined>(undefined);
  const [selectedFileTypeContainerName, setSelectedFileTypeContainerName] =
    useState<any>(undefined);
  const [cartCount, setCartCount] = useState(0);
  const [cartData, setCartData] = useState<RequestFiles[]>([null]);
  const [validationMsg, setValidationMsg] = useState(`
        The requested product, the Township(TWP), Range(RG) and Meridian(M) are required fields.
        `);

  const [applyListUWI, setApplyListUWI] = useState([]);
  const [searchType, setSearchType] = useState('uwi');
  const [searchList, setSearchList] = useState([]);

  const [inputStatusUwi, setInputStatusUwi] = useState<InputStatusUWI>({
    twp: '',
    m: '',
    rg: '',
    se: '',
    lsd: '',
    le: '',
    es: '',
  });

  const convertDbUwi = (uwi: string | undefined | null): string => {
    const twp = uwi.slice(0, 3);
    const m = uwi.slice(3, 4);
    const rg = uwi.slice(4, 6);
    const se = uwi.slice(6, 8);
    const lsd = uwi.slice(8, 10);
    const le = uwi.slice(10, 12);
    const es = uwi.slice(12, 14);

    // For the event sequence that are greater than 9, they are included in the file results
    // from the welllogview SQL View. The below condition is to display an extra 0 at the beginning
    // of the sequence if the value is less than 10 (1 in length).
    const esUpdated = es.length === 1 ? `0${es}` : es; // factor in G9 Wells
    return `${le}/${lsd}-${se}-${twp}-${rg}W${m}/${esUpdated}`;
  };

  // Fetch FilE Types/Products associated with the current customer request.
  const fetchRequestedFileTypes = async () => {
    //setLoadingRequestedProducts(true);
    try {
      const response = await getFileTypesByRequestNumber(requestNumber);
      setFileTypes(response.data);
      console.log('requested file types data:', response.data);
    } catch (error) {
      console.error('Error fetching file types:', error);
    }
  };

  // Fetch all the customer request details for the selected requestNumber
  const fetchRequestDetails = async () => {
    setLoadingRequestDetails(true);
    try {
      const response = await getRequestDetailsByRequestNumber(requestNumber);
      setRequestDetails(response.data);
    } catch (error) {
      console.error('Error fetching request details:', error);
    } finally {
      setLoadingRequestDetails(false);
    }
  };

  //Document Types = ALL, RASTER, LAS
  const handleDocTypeChange = (value: string) => {
    setSelectedDocType(value);
    //alert(value);
    console.log('docType:', value);
  };

  // Function to check if all required fields and dropdown are filled
  const areRequiredFieldsFilled =
    searchParamsUWI.twp && searchParamsUWI.rg && searchParamsUWI.m && selectedFileType;

  // Define a mapping of input names to validation functions

  const validationMap: { [key: string]: (value: string) => string } = {
    twp: validateAndFormatTwp,
    rg: validateAndFormatRg,
    m: validateAndFormatM,
    se: validateAndFormatSe,
    lsd: validateAndFormatLsd,
    le: validateAndFormatLe,
    es: validateAndFormatEs,
  };

  // handle the changes for each input for the uwi. twp, rg, m, se, lsd, le, es
  const handleInputChangeUWI = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setSearchParamsUWI((prevParams) => ({
      ...prevParams,
      //[name]: value,
      [name]: value,
    }));

    const currentInput = e.target;
    // Check if max length reached, then move to next input
    const maxLength = currentInput.getAttribute('maxlength');
    if (maxLength && currentInput.value.length >= parseInt(maxLength)) {
      moveToNextInput(currentInput);
    }
  };

  // Function to move focus to the next input
  const moveToNextInput = (currentInput: HTMLInputElement) => {
    // Ensure we're grabbing the inputs in the right order and scope
    const formElements = Array.from(document.querySelectorAll('input[name]')) as HTMLInputElement[];

    // Find the index of the current input within this array
    const currentIndex = formElements.findIndex((input) => input.name === currentInput.name);

    if (currentIndex !== -1 && currentIndex < formElements.length - 1) {
      const nextInput = formElements[currentIndex + 1];
      console.log('tabbed');
      nextInput?.focus();
    } else {
      console.log('no tab');
    }
  };

  // On Blur of the UWI inputs, validate values and also format if necessary by prefixing with 0's.
  const handleBlurUWI = (name: string) => {
    const rawValue = searchParamsUWI[name as keyof InputStatusUWI] || '';
    const validateAndFormat = validationMap[name];

    // Apply validation and formatting only on blur
    const formattedValue = validateAndFormat ? validateAndFormat(rawValue) : rawValue;

    setSearchParamsUWI((prevParams) => ({
      ...prevParams,
      [name]: formattedValue,
    }));

    setInputStatusUwi({
      ...inputStatusUwi,
      twp: name === 'twp' && formattedValue === '' ? 'error' : '',
      m: name === 'm' && formattedValue === '' ? 'error' : '',
      rg: name === 'rg' && formattedValue === '' ? 'error' : '',
      se: name === 'se' && formattedValue === '' ? 'error' : '',
      lsd: name === 'lsd' && formattedValue === '' ? 'error' : '',
      le: name === 'le' && formattedValue === '' ? 'error' : '',
      es: name === 'es' && formattedValue === '' ? 'error' : '',
    });

    console.log('onblur of twp: ', inputStatusUwi.twp);
  };

  //handle the array for the UWI Search List - Search by UWI
  const handleAddSearchToListUWI = () => {
    let uwi = '';
    if (searchParamsUWI.twp) {
      uwi += `${searchParamsUWI.twp}`;
    } else {
      uwi += '???';
    }
    if (searchParamsUWI.m) {
      uwi += `${searchParamsUWI.m}`;
    } else {
      uwi += '?';
    }
    if (searchParamsUWI.rg) {
      uwi += `${searchParamsUWI.rg}`;
    } else {
      uwi += '??';
    }
    if (searchParamsUWI.se) {
      uwi += `${searchParamsUWI.se}`;
    } else {
      uwi += '??';
    }
    if (searchParamsUWI.lsd) {
      uwi += `${searchParamsUWI.lsd}`;
    } else {
      uwi += '???';
    }
    if (searchParamsUWI.le) {
      uwi += `${searchParamsUWI.le}`;
    } else {
      uwi += '?';
    }
    if (searchParamsUWI.es) {
      uwi += `${searchParamsUWI.es}`;
    } else {
      uwi += '%%';
    }

    setApplyListUWI([...applyListUWI, uwi]);
    handleResetUWI();
    //console.log('uwi: ' + applyListUWI);
  };

  //Split the Emails into a list.
  const emails = requestDetails?.contactEmail ?? ''; // Check if contactEmail exists, fallback to empty string
  const emailList = emails ? emails.split(',') : [];

  //remove item from Apply List
  const handleRemoveSearchListItemUWI = (itemToRemove: string) => {
    setApplyListUWI((prevItems) => prevItems.filter((item) => item !== itemToRemove));
    setSearchResults([]);
  };

  // Perform search by UWI from the applyListUWI array.
  const handleSearch = async (searchType: string) => {
    setValidationMsg('');
    setSearchType(searchType);
    setLoadingSearch(true);
    try {
      searchType === 'uwi'
        ? //setSearchList(['052401??000%%%', '05540%%030%%%']) :
          setSearchList([applyListUWI])
        : //replace this with the licence apply list when developed. ie applyListLicence.
          setSearchList([applyListUWI]);

      setTimeout(() => {
        setLoadingSearch(false);
      }, 6000);
    } catch (error) {
      console.error('Failed to fetch search results', error);
      setLoadingSearch(false);
      throw error;
    }
  };

  const handleResetUWI = () => {
    setSearchParamsUWI(initialStateUWISearch); // Clear the search query
    setSearchResults([]); // Clear the search results
    setLoadingSearch(false);
  };

  // Function to fetch the file count for the selected Customer Request Id
  const fetchRequestFileCount = async () => {
    try {
      const cartFileCount = await getFileCountByRequestId(requestDetails?.id);
      setCartCount(cartFileCount);
    } catch (error) {
      console.error('Error fetching file count:', error);
    }
  };

  const updateCartCount = (newCount: number) => {
    setCartCount(newCount);
  };

  useEffect(() => {
    //console.log('File Type Container Name: ', selectedFileTypeContainerName);

    fetchRequestedFileTypes();
    fetchRequestDetails(); // You can keep this, but ensure it doesn't cause re-renders

    const fetchData = async () => {
      await fetchRequestFileCount(); // Call the function to fetch and update state
    };

    fetchData(); // Fetch when requestDetails change
  }, [
    requestDetails?.id,
    selectedFileType,
    selectedFileTypeId,
    selectedFileTypeContainerName,
    areRequiredFieldsFilled,
    validationMsg,
    applyListUWI,
    searchResults,
  ]);

  const handleFileTypeChange = (value: any) => {
    // Find the selected file type object by its value
    const selectedFileTypeObj = fileTypes.find((fileType) => fileType.name === value);
    if (selectedFileTypeObj) {
      const title = selectedFileTypeObj.containerName; // Get the title
      const id = selectedFileTypeObj.id; // Get the key/id

      //yourMethod(title, value);
      setSelectedFileTypeId(id);
      setSelectedFileType(value);
      setSelectedFileTypeContainerName(title);
    }
    setSelectedFileType(value); // Update the selected value state
  };

  /*
    const handleFileTypeChange = (value: string, title: any) => {
        setSelectedFileType(value);
        setSelectedFileTypeId(title.key);
        setSelectedFileTypeContainerName(title);
        console.log('title:', title);
    };*/

  // load the request details
  const requestContext = {
    id: requestDetails?.id,
    contactName: requestDetails?.contactName,
    contactEmail: requestDetails?.contactEmail,
    contactPhone: requestDetails?.contactPhone,
    companyName: requestDetails?.companyName,
    requestNumber: requestDetails?.requestNumber,
    requestTypeName: requestDetails?.requestTypeName,
    requestStatusName: requestDetails?.requestStatusName,
    billingStatusName: requestDetails?.billingStatusName,
    paymentTypeName: requestDetails?.paymentTypeName,
    //cartCount: requestDetails && requestDetails.cartCount,
  };

  // Disable "Ready For Billing"
  const disableReadyForBilling =
    requestContext?.requestStatusName == 'Completed' ||
    requestContext?.billingStatusName == 'Billed' ||
    requestContext?.billingStatusName == 'Billing Not Required' ||
    requestContext?.paymentTypeName == 'Payment Not Required' ||
    cartCount == 0
      ? 'none'
      : 'inline-block';

  const disableReviewDisseminate =
    disableReadyForBilling != 'none' && cartCount > 0 ? 'inline-block' : 'none';

  const itemsTabWellLog: TabsProps['items'] = [
    {
      key: '1',
      label: 'UWI',
      children: (
        <>
          <Form layout="inline">
            <Form.Item>
              <Row gutter={1}>
                <Col style={{ width: 65 }}>
                  <Input
                    min="1"
                    max="8"
                    style={{ textAlign: 'center', width: 60 }}
                    name="le"
                    maxLength={2}
                    placeholder="LE"
                    status={inputStatusUwi.le}
                    value={searchParamsUWI.le}
                    //onBlur={() => handleBlurUWI('le')}
                    onChange={handleInputChangeUWI} // Update query directly from parent state
                  />
                </Col>
                <Col style={{ width: 65 }}>
                  <Input
                    style={{ textAlign: 'center', width: 60 }}
                    name="lsd"
                    maxLength={2}
                    placeholder="LSD"
                    status={inputStatusUwi.lsd}
                    value={searchParamsUWI.lsd}
                    //onBlur={() => handleBlurUWI('lsd')}
                    onChange={handleInputChangeUWI} // Update query directly from parent state
                  />
                </Col>
                <Col style={{ width: 60 }}>
                  <Input
                    style={{ textAlign: 'center', width: 55 }}
                    name="se"
                    maxLength={2}
                    placeholder="SEC"
                    status={inputStatusUwi.se}
                    value={searchParamsUWI.se}
                    //onBlur={() => handleBlurUWI('se')}
                    onChange={handleInputChangeUWI} // Update query directly from parent state
                  />
                </Col>
                <Col style={{ width: 60 }}>
                  <Input
                    style={{ textAlign: 'center', width: 55 }}
                    required
                    name="twp"
                    maxLength={3}
                    minLength={3}
                    placeholder="TWP"
                    status={inputStatusUwi.twp}
                    value={searchParamsUWI.twp}
                    //onBlur={() => handleBlurUWI('twp')}
                    onChange={handleInputChangeUWI} // Update query directly from parent state
                  />
                </Col>
                <Col style={{ width: 60 }}>
                  <Input
                    style={{ textAlign: 'center', width: 55 }}
                    required
                    name="rg"
                    maxLength={2}
                    placeholder="RG"
                    status={inputStatusUwi.rg}
                    value={searchParamsUWI.rg}
                    //onBlur={() => handleBlurUWI('rg')}
                    onChange={handleInputChangeUWI} // Update query directly from parent state
                  />
                </Col>

                <Col style={{ width: 55 }}>
                  <Input
                    min="4"
                    max="6"
                    style={{ textAlign: 'center', width: 50 }}
                    required
                    name="m"
                    maxLength={1}
                    placeholder="M"
                    status={inputStatusUwi.m}
                    value={searchParamsUWI.m}
                    //onBlur={() => handleBlurUWI('m')}
                    onChange={handleInputChangeUWI} // Update query directly from parent state
                  />
                </Col>
                <Col style={{ width: 55 }}>
                  <Input
                    style={{ textAlign: 'center', width: 50 }}
                    name="es"
                    maxLength={2}
                    placeholder="ES"
                    status={inputStatusUwi.es}
                    value={searchParamsUWI.es}
                    //onBlur={() => handleBlurUWI('es')}
                    onChange={handleInputChangeUWI} // Update query directly from parent state
                  />
                </Col>
                <Col span={1}>
                  <Button
                    type="default"
                    onClick={handleAddSearchToListUWI}
                    disabled={!areRequiredFieldsFilled}
                  >
                    Apply
                  </Button>
                </Col>
              </Row>
              {!areRequiredFieldsFilled ? (
                <div style={{ fontSize: '0.85em', color: '#1e82d9' }}>{validationMsg}</div>
              ) : (
                ''
              )}
              {applyListUWI.length >= 1 ? (
                <>
                  <br></br>
                  <div>
                    <span style={{ fontWeight: '500' }}>Search List</span>
                  </div>
                  {applyListUWI.map((itemUWI, index) => (
                    <span key={index} style={{ fontSize: '1.0em' }}>
                      <Row
                        style={{
                          border: '1px solid #f2f2f2',
                          width: '240px',
                          padding: '2px 6px',
                        }}
                      >
                        <Col style={{ width: '210px' }}>{convertDbUwi(itemUWI)}</Col>
                        <Col style={{ width: '10px' }}>
                          <DeleteOutlined
                            style={{ fontSize: '15px', color: 'red' }}
                            onClick={() => handleRemoveSearchListItemUWI(itemUWI)}
                          />
                        </Col>
                      </Row>
                    </span>
                  ))}
                </>
              ) : (
                ''
              )}
              <br></br>
            </Form.Item>
            <Row>
              <Col>
                <Button
                  onClick={handleResetUWI}
                  disabled={requestContext.requestStatusName == 'Completed' ? true : false}
                >
                  Reset
                </Button>
                &nbsp;
                <Button
                  type="primary"
                  onClick={() => {
                    handleSearch('uwi');
                  }}
                  loading={loadingSearch}
                  disabled={
                    requestContext.requestStatusName == 'Completed' || applyListUWI.length === 0
                      ? true
                      : false
                  }
                >
                  Perform Search
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      ),
    },
    {
      key: '2',
      label: 'LICENCE',
      children: (
        <>
          <WellSearchFormByLicence />
        </>
      ),
    },
    {
      key: '3',
      label: 'AREA',
      children: (
        <>
          <WellSearchFormByArea />
        </>
      ),
    },
    {
      key: '4',
      label: 'IMPORT',
      children: (
        <>
          <WellSearchFormByImport />
        </>
      ),
    },
    {
      key: '5',
      label: 'SUBSCRIPTION',
      children: (
        <>
          <WellSearchFormBySubscription />
        </>
      ),
      //disabled: false,
    },
  ];

  return (
    <>
      <AppLayout />
      <div className="content">
        <Breadcrumbs
          items={[
            { href: '/dissemination', title: 'File Dissemination' },
            { href: '/dissemination/irm', title: 'Manage IRM Request' },
            { title: 'IRM' + requestNumber },
          ]}
        />
        <p></p>
        {/* Header Card */}
        <Card size="small" style={{ marginBottom: '3px' }}>
          <Row gutter={0} align="middle" justify="space-between">
            <Col style={{ width: 50 }}>
              <Button
                size="middle"
                type="default"
                style={{ width: '50px' }}
                onClick={() => navigate('/dissemination/irm')}
              >
                Back
              </Button>
            </Col>
            <Divider type="vertical" style={{ height: 'auto', alignSelf: 'stretch' }}></Divider>
            <Col style={{ flex: 1, textAlign: 'left' }}>
              {' '}
              {/* Middle Column */}
              <span style={{ fontSize: '1.5em', fontWeight: '400' }}>IRM{requestNumber}</span>
            </Col>
            <Col style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <WellSelectionPageOptions
                cartCount={cartCount}
                requestId={requestContext.id}
                requestNumber={requestNumber}
                requestStatus={requestContext.requestStatusName}
              />
              {/* {!disableReadyForBilling ? (
                    <div className="user-info">
                      Welcome back, {account.idTokenClaims.name.split(' ')[0]}
                    </div>
                  ) : (
                    'Welcome back.'
                  )} */}

              <UploadAdditionalFile requestNumber={requestContext.requestNumber} />
              <RequestNotesForm requestDetails={requestDetails} />
              <ReadyForBillingForm requestDetails={requestDetails} cartCount={cartCount} />
              <Divider type="vertical" style={{ height: 'auto', alignSelf: 'stretch' }}></Divider>
              <FileSynchronizationForm />
            </Col>
          </Row>
        </Card>
        {/* Header Card - end */}

        {/* Details Card */}
        <Card size="default" style={{ height: 'auto' }}>
          <Row gutter={24}>
            <Col span={10}>
              <Row>
                <Col span={18}>
                  <span style={{ fontSize: '1.2em', fontWeight: '300' }}>
                    Perform Product Search
                  </span>
                </Col>
                {requestContext.requestStatusName === 'Completed' ? (
                  ''
                ) : (
                  <>
                    <Col span={3}>
                      <ModifyRequestProductListForm />
                    </Col>
                  </>
                )}
              </Row>

              <Row style={{ marginTop: 15 }}>
                <Col span={8}>
                  <span style={{ color: 'red' }}>*</span>
                  <span style={{ fontWeight: '500' }}> 1) Select Requested Product</span>
                  <br></br>
                  <Select
                    disabled={requestContext.requestStatusName === 'Completed'}
                    style={{ width: 200 }}
                    placeholder="Select a file type"
                    onChange={handleFileTypeChange}
                    value={selectedFileType}
                  >
                    {fileTypes.map((fileType) => (
                      <Option
                        key={fileType.id}
                        title={fileType.containerName}
                        value={fileType.name}
                      >
                        {fileType.name}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col span={6}>
                  <span style={{ color: 'red' }}>*</span>
                  <span style={{ fontWeight: '500' }}> 2) Document Type</span>
                  <br></br>
                  <Select
                    disabled={requestContext.requestStatusName === 'Completed'}
                    style={{ width: 130 }}
                    value={selectedDocType}
                    onChange={handleDocTypeChange}
                  >
                    <Option key={1} value="ALL">
                      ALL
                    </Option>
                    <Option key={2} value="RASTER">
                      RASTER
                    </Option>
                    <Option key={3} value="LAS">
                      LAS
                    </Option>
                  </Select>
                </Col>
              </Row>

              <br></br>

              {selectedFileType === 'Well Log' ? (
                <>
                  <p></p>
                  <span style={{ color: 'red' }}>*</span>
                  <span style={{ fontWeight: '500' }}> 3) Search {selectedFileType} By</span>
                  <br></br>
                  <Tabs
                    size="small"
                    tabPosition="top"
                    defaultActiveKey="1"
                    items={itemsTabWellLog}
                  />
                </>
              ) : (
                ''
              )}
            </Col>

            <Divider type="vertical" style={{ height: 'auto', alignSelf: 'stretch' }}></Divider>

            <Col style={{ width: 200, display: 'flex', flexDirection: 'column' }}>
              <Row gutter={24} style={{ marginTop: '0px' }}>
                <Col>
                  <span style={{ fontWeight: '500' }}>Customer Details</span>
                  <br></br>
                  <i>
                    <span style={{ fontSize: '0.90em' }}>
                      {requestContext.contactName}
                      <br></br>
                      {emailList.length === 0
                        ? 'No emails specified'
                        : emailList.map((email, index) => (
                            <span key={index}>
                              {email}
                              <br></br>
                            </span>
                          ))}
                      {requestContext.contactPhone ? <>{requestContext.contactPhone}</> : ''}
                      {requestContext.companyName ? (
                        <>
                          <br></br>
                          {requestContext.companyName}
                        </>
                      ) : (
                        ''
                      )}
                    </span>
                  </i>
                </Col>
                {/* <Divider type='horizontal'
                                    style={{ width: '5px', marginTop: '7px', marginBottom: '10px' }}></Divider> */}

                <Col span={24} style={{ marginTop: 7 }}>
                  <span style={{ fontWeight: '500' }}>Request Status</span>
                  <br></br>
                  <div>
                    <Tag
                      style={{ marginTop: '0px', fontSize: '0.95em', paddingBottom: 3 }}
                      color={
                        requestContext.requestStatusName == 'Completed'
                          ? '#139A43'
                          : requestContext.requestStatusName == 'Incompleted'
                          ? 'gold'
                          : requestContext.requestStatusName == 'Paused'
                          ? '#f50'
                          : '#108ee9'
                      }
                    >
                      {requestContext.requestStatusName}
                    </Tag>
                  </div>
                  <br></br>
                  <span style={{ fontWeight: '500' }}>Billing Status</span>
                  <br></br>
                  <div>
                    <Tag
                      style={{ marginTop: '0px', fontSize: '0.95em', paddingBottom: 3 }}
                      color={
                        requestContext.billingStatusName == 'Billed' ||
                        requestContext.billingStatusName == 'Billing Not Required' ||
                        requestContext.billingStatusName == 'Ready for Billing'
                          ? '#139A43'
                          : requestContext.billingStatusName == 'Waiting for Payment'
                          ? 'gold'
                          : requestContext.billingStatusName == 'Not Set'
                          ? '#f50'
                          : '#108ee9'
                      }
                    >
                      {requestContext.billingStatusName}
                    </Tag>
                  </div>
                  <br></br>
                  <span style={{ fontWeight: '500' }}>Payment Type</span>
                  <br></br>
                  <div>
                    <Tag
                      style={{ marginTop: '0px', fontSize: '0.95em', paddingBottom: 3 }}
                      color={
                        requestContext.paymentTypeName == 'Payment Not Required'
                          ? '#139A43'
                          : '#108ee9'
                      }
                    >
                      {requestContext.paymentTypeName}
                    </Tag>
                  </div>
                </Col>
              </Row>
            </Col>
            {/* <Divider type='vertical' style={{ height: 'auto', alignSelf: 'stretch' }}></Divider> */}

            <Col span={3}>
              <Row>
                <Col style={{ width: '140px', border: '1px solid white' }}>
                  <Col span={24}>
                    <span style={{ fontWeight: '500' }}>Requested Products</span>
                    <br></br>
                    {requestContext ? (
                      <span>
                        <Spin spinning={loadingRequestedProducts} tip="Loading...">
                          {fileTypes.length === 0
                            ? 'None selected'
                            : fileTypes.map((fileType) => (
                                <span key={fileType.id} style={{ fontSize: '0.88em' }}>
                                  <i>{fileType.name},&nbsp;</i>
                                </span>
                              ))}
                        </Spin>
                      </span>
                    ) : (
                      <p>No record found</p>
                    )}
                  </Col>
                </Col>
                {/* <Divider type='vertical' style={{ height: 'auto', alignSelf: 'stretch' }}></Divider> */}
                {/* tabs were here. */}
              </Row>
            </Col>

            <Col span={3}>
              <Row gutter={2}>
                <Col span={24}>
                  <span style={{ fontWeight: '500' }}>Progress Details</span>
                  <br></br>
                  <br></br>
                  <Timeline
                    style={{ marginBottom: '-50px', marginTop: '5px' }}
                    items={[
                      {
                        dot: <CheckCircleFilled />,
                        color: 'green',
                        children: 'Request Creation',
                      },
                      {
                        color:
                          requestContext.requestStatusName === 'Completed' ||
                          requestContext.billingStatusName == 'Ready for Billing'
                            ? 'green'
                            : 'blue',
                        dot:
                          requestContext.requestStatusName === 'Completed' ||
                          requestContext.billingStatusName == 'Ready for Billing' ? (
                            <CheckCircleFilled className="timeline-clock-icon" />
                          ) : (
                            <ClockCircleOutlined className="timeline-clock-icon" />
                          ),

                        //color: 'green',
                        children: 'File Selection',
                      },
                      {
                        color: requestContext.requestStatusName === 'Completed' ? 'green' : 'blue',
                        dot:
                          requestContext.requestStatusName === 'Completed' ||
                          requestContext.billingStatusName === 'Billed' ||
                          requestContext.billingStatusName === 'Billing Not Required' ? (
                            <CheckCircleFilled className="timeline-clock-icon" />
                          ) : (
                            <ClockCircleOutlined className="timeline-clock-icon" />
                          ),

                        //color: 'green',
                        children: 'Billing',
                      },
                      {
                        color: requestContext.requestStatusName === 'Completed' ? 'green' : 'blue',
                        dot:
                          requestContext.requestStatusName === 'Completed' ? (
                            <CheckCircleFilled className="timeline-clock-icon" />
                          ) : (
                            ''
                          ),
                        children: 'Dissemination',
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Col>
            <Divider type="vertical" style={{ height: 'auto', alignSelf: 'stretch' }}></Divider>
            <Col span={3}>
              <span style={{ fontWeight: '500' }}>Request Summary</span>
              <Statistic title="Total Requested Files" value={cartCount} />
              <Statistic title="Overall File Size" value={'To Be Populated'} />
            </Col>
          </Row>
        </Card>
        {/* Details Card - end */}

        {/* Search Results Card */}
        <Row
          gutter={24}
          style={{
            display:
              requestDetails && requestDetails.requestStatusName === 'Completed' ? 'none' : '',
          }}
        >
          <Col span={24}>
            <p></p>
            {/* <Card size='small' bordered={false}
                            style={{ width: '100%', padding: '0px', marginTop: '0px' }} >

                            <WellSearchResults
                                selectedFileTypeId={selectedFileTypeId}
                                selectedFileTypeContainerName={selectedFileTypeContainerName}
                                requestDetails={requestDetails}
                                searchResults={searchResults}
                                cartCount={cartCount}
                                updateCartCount={updateCartCount}
                            />
                        </Card> */}
            <Card
              size="small"
              bordered={false}
              style={{ width: '100%', padding: '0px', marginTop: '0px' }}
            >
              <QueryClientProvider client={queryClient}>
                <DataProvider
                  fileTypeId={selectedFileTypeId}
                  searchList={searchList}
                  searchType={searchType}
                  docType={selectedDocType}
                >
                  <WellSearchResults
                    requestDetails={requestDetails}
                    selectedFileTypeContainerName={selectedFileTypeContainerName}
                    cartCount={cartCount}
                    updateCartCount={updateCartCount}
                  />
                </DataProvider>
              </QueryClientProvider>
            </Card>
          </Col>
        </Row>
        {/* Search Results Card - end */}

        <Row
          gutter={24}
          style={{
            display:
              requestDetails && requestDetails.requestStatusName === 'Completed' ? 'block' : 'none',
          }}
        >
          <Col span={24}>
            <p></p>
            <Card
              size="small"
              bordered={false}
              style={{ width: '100%', padding: '0px', marginTop: '0px' }}
            >
              <RequestedProducts
                requestDetails={requestDetails}
                fileTypes={fileTypes}
                cartCount={cartCount}
                updateCartCount={updateCartCount}
                cartData={cartData}
                setCartData={setCartData}
              />
            </Card>
          </Col>
        </Row>
      </div>

      {/* Request Action Card */}
      {requestDetails && requestDetails.requestStatusName != 'Completed' ? (
        <>
          <RequestActions
            requestDetails={requestDetails}
            cartCount={cartCount}
            updateCartCount={updateCartCount}
            fileTypes={fileTypes}
          />
        </>
      ) : (
        ''
      )}
      {/* Request Action Card - end */}

      <AppFooter />
    </>
  );
};

export default WellFileSelection;
