import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { config } from './config';
 
export const pca = new PublicClientApplication({
  auth: {
    clientId: config.REACT_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${config.REACT_APP_PCA_TENANT_ID}`,
    redirectUri: '/',
  },
});
 
pca.addEventCallback((event: any) => {
  if (event.eventType == EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account);
    if (event.payload.accessToken) {
      localStorage.setItem('accessToken', event.payload.accessToken);
    }
    console.log('Access Token:', event.payload.accessToken);
  }
});