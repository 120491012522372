import React from 'react';

const WellSearchFormByLicence: React.FC = () => {
  return (
    <div>
      Placeholder for Licence Filter.
    </div>
  );
};

export default WellSearchFormByLicence;
