import React from 'react';
import '../Components.css';
import logo from '../../assets/logo.png';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Avatar, Button, Divider } from 'antd';
import { UserInfo } from '../UserInfo';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import MainMenu from './MainMenu';

const { Header } = Layout;

const AppLayout: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const handleSignOut = (event: unknown) => {
    console.log(event);
    console.log('Logging out...');
    localStorage.removeItem('accessToken');
    instance.logoutRedirect();
  };

  const handleLogin = () => {
    instance.loginRedirect({
      scopes: ['profile', 'openid', 'email', 'User.Read'],
    });
  };
  return (
    <>
      <Header className="header">
        <div className="logo">
          <Link to={'/'}>
            <img
              src={logo}
              alt="AER Logo"
              style={{ height: '45px', marginLeft: '-40px', marginRight: '5px', marginTop: '-2px' }}
            />
          </Link>
          <Divider type='vertical' style={{ background: '#eee', height: '40px', top: '0px' }} />
          <span style={{
            marginLeft: '5px',
            position: 'relative',
            top: '-9px',
            color: '#777',
            fontSize: '1.1em',
            fontWeight: 'bold',
            fontFamily: 'Segoe UI'
          }}>DISSEMINATION TOOL</span><br></br>
          <span style={{
            marginLeft: '-188px',
            marginTop: '22px',
            position: 'relative',
            color: '#555',
            fontSize: '0.8em',
            fontWeight: 'normal',
            fontFamily: 'Segoe UI'
          }}>Disseminations, Compilations and Subscriptions</span>
        </div>
        <MainMenu />
        {isAuthenticated ? (
          <div>
            <UserInfo />
            <Button type="primary" icon={<LogoutOutlined />} onClick={handleSignOut} />
          </div>
        ) : (
          <Button type="primary" onClick={handleLogin}>
            Login
          </Button>
        )}
      </Header>

    </>
  );
};

export default AppLayout;
