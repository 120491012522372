/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import AppLayout from '../../components/Layout/AppLayout';
import { AppFooter } from '../../components/Layout/AppFooter';
import {
    Card, Row, Col, Button,
    Tabs, Form, Input, Select,
} from 'antd';
import type { TabsProps } from 'antd';
//import { config } from '../../config';
import WellSearchResults from '../../components/productSearch/WellSearchResults';
//import { useNavigate } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import {
    getWellsByUWI,
    getFileCountByRequestId,
    getFileTypes
} from '../../services/api';
import Breadcrumbs from '../../components/Layout/Breadcrumbs';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DataProvider } from '../../providers/WellFileDataProvider';

// get the server domain root api
//const apiUrl = config.API_URL;

const queryClient = new QueryClient();

const { Option } = Select;

interface FileType {
    id: string;
    name: string;
    containerName: string;
}

interface RequestDetails {
    id: string;
    requestNumber: string;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    companyName: string;
    requestTypeName: string;
    requestStatusName: string;
    requestFileTypes: [{
        requestFileTypeId: string;
        requestFileTypeName: string;
    }];
    cartCount: number;
    updateCartCount: (newCount: number) => void;
}

interface RequestedFileTypes {
    id: string;
    name: string;
}

const WellFileSelection: React.FC = () => {

    const initialStateUWISearch = {
        le: '',
        lsd: '',
        se: '',
        twp: '',
        rg: '',
        m: '',
        es: '',
    };

    //const navigate = useNavigate();

    //Search Parameters - UWI
    const [searchParamsUWI, setSearchParamsUWI] = useState(initialStateUWISearch);


    const [searchResults, setSearchResults] = useState<Array<{ [key: string]: any }>>([]);
    //const [fileResults, setFileResults] = useState<Array<{ [key: string]: any }>>([]);

    //used to set the file count for the request on the cart badge.
    //const [requestFileCount, setRequestFileCount] = useState<number | null>(null);

    //const { requestNumber } = useParams<{ requestNumber: string }>();

    //const [loadingRequestedProducts, setLoadingRequestedProducts] = useState<boolean>(false);
    //const [loadingRequestDetails, setLoadingRequestDetails] = useState<boolean>(false);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    //const [loadingFileResults, setLoadingFileResults] = useState<boolean>(false);
    const [fileTypes, setFileTypes] = useState<FileType[]>([]);
    const [requestDetails, setRequestDetails] = useState<RequestDetails>(null);
    const [selectedFileTypeId, setSelectedFileTypeId] = useState<string | undefined>(undefined);
    const [selectedFileType, setSelectedFileType] = useState<string | undefined>(undefined);
    const [selectedFileTypeContainerName, setSelectedFileTypeContainerName] = useState<any>(undefined);
    const [cartCount, setCartCount] = useState(0);
    const [validationMsg, setValidationMsg] = useState(`
        The requested product, the Township(TWP), Range(RG) and Meridian(M) are required fields.
        `);
    const [applyListUWI, setApplyListUWI] = useState([]);
    const [searchType, setSearchType] = useState('uwi');
    const [searchList, setSearchList] = useState([]);



    const convertDbUwi = (uwi: string | undefined | null): string => {
        const twp = uwi.slice(0, 3);
        const m = uwi.slice(3, 4);
        const rg = uwi.slice(4, 6);
        const se = uwi.slice(6, 8);
        const lsd = uwi.slice(8, 10);
        const le = uwi.slice(10, 12);
        const es = uwi.slice(12, 14);

        // For the event sequence that are greater than 9, they are included in the file results
        // from the welllogview SQL View. The below condition is to display an extra 0 at the beginning
        // of the sequence if the value is less than 10 (1 in length).
        const esUpdated = es.length === 1 ? `0${es}` : es; // factor in G9 Wells
        return `${le}/${lsd}-${se}-${twp}-${rg}W${m}/${esUpdated}`;
    };



    // Fetch FilE Types/Products associated with the current customer request.
    const fetchFileTypes = async () => {
        //setLoadingRequestedProducts(true);
        try {
            const response = await getFileTypes();
            setFileTypes(response.data);
            console.log('requested file types data:', response.data);
        } catch (error) {
            console.error('Error fetching file types:', error);
        }
    };


    // // Fetch all the customer request details for the selected requestNumber
    // const fetchRequestDetails = async () => {
    //     setLoadingRequestDetails(true);
    //     try {
    //         const response = await getRequestDetailsByRequestNumber(requestNumber);
    //         setRequestDetails(response.data);
    //     } catch (error) {
    //         console.error('Error fetching request details:', error);
    //     } finally {
    //         setLoadingRequestDetails(false);
    //     }
    // };




    // Function to check if all required fields and dropdown are filled
    const areRequiredFieldsFilled = searchParamsUWI.twp && searchParamsUWI.rg && searchParamsUWI.m && selectedFileType;

    // handle the changes for each input for the uwi. twp, rg, m, se, lsd, le, es
    const handleInputChangeUWI = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSearchParamsUWI((prevParams) => ({
            ...prevParams,
            [name]: value,
        }));

    };

    //handle the array for the UWI Search List - Search by UWI
    const handleSearchAddToListUWI = () => {

        let uwi = '';
        if (searchParamsUWI.twp) { uwi += `${searchParamsUWI.twp}`; } else { uwi += '???'; };
        if (searchParamsUWI.m) { uwi += `${searchParamsUWI.m}`; } else { uwi += '?'; };
        if (searchParamsUWI.rg) { uwi += `${searchParamsUWI.rg}`; } else { uwi += '??'; };
        if (searchParamsUWI.se) { uwi += `${searchParamsUWI.se}`; } else { uwi += '??'; };
        if (searchParamsUWI.lsd) { uwi += `${searchParamsUWI.lsd}`; } else { uwi += '???'; };
        if (searchParamsUWI.le) { uwi += `${searchParamsUWI.le}`; } else { uwi += '?'; };
        if (searchParamsUWI.es) { uwi += `${searchParamsUWI.es}`; } else { uwi += '%%'; };

        setApplyListUWI([...applyListUWI, uwi]);
        handleResetUWI();
        //console.log('uwi: ' + applyListUWI);
    };

    //remove item from Apply List
    const handleRemoveSearchListItemUWI = (itemToRemove: string) => {
        setApplyListUWI((prevItems) => prevItems.filter(item => item !== itemToRemove));
        setSearchResults([]);
        //console.log(applyListUWI);
    };

    // Perform search by UWI from the applyListUWI array.
    const handleSearchUWI = async () => {
        setValidationMsg('');
        try {
            setLoadingSearch(true);
            const res = await getWellsByUWI([applyListUWI]);
            setSearchResults(res.data[0]);

            setTimeout(() => {
                setLoadingSearch(false);
            }, 2000);

        } catch (error) {
            console.error('Failed to fetch search results', error);
            throw error;
        }
    };

    // const handleAssociatedWellFiles = async (uwi: string) => {
    //     try {
    //         setLoadingFileResults(true);
    //         //get the associated files for the selected well.
    //         const res = await fetchAssociatedWellLogFiles(uwi);
    //         setFileResults(res.data[0][0]);

    //         setLoadingFileResults(false);
    //     } catch (error) {
    //         console.error('Failed to fetch search results', error);
    //         throw error;
    //     }
    // };

    const handleResetUWI = () => {
        setSearchParamsUWI(initialStateUWISearch); // Clear the search query
        setSearchResults([]); // Clear the search results
        setLoadingSearch(false);
    };

    // // Function to fetch request details
    // const fetchRequestDetails = async () => {
    //     try {
    //         const details = await getRequestDetailsByRequestNumber(requestNumber);
    //         if (JSON.stringify(details) !== JSON.stringify(requestDetails)) {
    //             setRequestDetails(details.data); // Only update if the data has changed
    //         }
    //     } catch (error) {
    //         console.error('Error fetching request details:', error);
    //     }
    // };

    // Function to fetch the file count for the selected Customer Request Id
    const fetchRequestFileCount = async () => {
        try {
            //if (requestDetails) {
            const cartFileCount = await getFileCountByRequestId(requestDetails && requestDetails.id);
            //console.log('filecount from wellselectionpage:', cartFileCount);
            //return cartFileCount;
            setCartCount(cartFileCount);
            //setCartFileCount(cartFileCount); // Update state

            //}
            // Log the fetched file count instead of the state value

        } catch (error) {
            console.error('Error fetching file count:', error);
        }
    };

    const updateCartCount = (newCount: number) => {
        setCartCount(newCount);
    };

    useEffect(() => {

        console.log('File Type Container Name: ', selectedFileTypeContainerName);

        fetchFileTypes();
        //fetchRequestDetails(); // You can keep this, but ensure it doesn't cause re-renders

        const fetchData = async () => {
            await fetchRequestFileCount();  // Call the function to fetch and update state
        };

        fetchData();  // Fetch when requestDetails change

    }, [
        requestDetails && requestDetails.id,
        selectedFileType,
        selectedFileTypeId,
        selectedFileTypeContainerName,
        areRequiredFieldsFilled,
        validationMsg,
        applyListUWI,
        searchResults
    ]);

    const handleFileTypeChange = (value: string, title: any) => {
        setSelectedFileType(value);
        setSelectedFileTypeId(title.key);
        setSelectedFileTypeContainerName(title);
    };

    // load the request details
    const requestContext = {
        id: requestDetails && requestDetails.id,
        contactName: requestDetails && requestDetails.contactName,
        contactEmail: requestDetails && requestDetails.contactEmail,
        contactPhone: requestDetails && requestDetails.contactPhone,
        companyName: requestDetails && requestDetails.companyName,
        requestNumber: requestDetails && requestDetails.requestNumber,
        requestTypeName: requestDetails && requestDetails.requestTypeName,
        requestStatusName: requestDetails && requestDetails.requestStatusName,
        //cartCount: requestDetails && requestDetails.cartCount,
    };

    const itemsTabWellLog: TabsProps['items'] = [
        {
            key: '1',
            label: 'UWI',
            children:
                <>
                    <Form layout="inline">
                        <Form.Item>
                            <Row gutter={1}>
                                <Col style={{ width: 65 }}>
                                    <Input min='1' max='8' style={{ textAlign: 'center', width: 60 }}
                                        name='le'
                                        maxLength={3}
                                        placeholder="LE"
                                        value={searchParamsUWI.le}
                                        onChange={handleInputChangeUWI} // Update query directly from parent state
                                    />
                                </Col>
                                <Col style={{ width: 65 }}>
                                    <Input style={{ textAlign: 'center', width: 60 }}
                                        name='lsd'
                                        maxLength={3}
                                        placeholder="LSD"
                                        value={searchParamsUWI.lsd}
                                        onChange={handleInputChangeUWI} // Update query directly from parent state
                                    />
                                </Col>
                                <Col style={{ width: 50 }}>
                                    <Input style={{ textAlign: 'center', width: 45 }}
                                        name='se'
                                        maxLength={2}
                                        placeholder="SE"
                                        value={searchParamsUWI.se}
                                        onChange={handleInputChangeUWI} // Update query directly from parent state
                                    />
                                </Col>
                                <Col style={{ width: 60 }}>
                                    <Input style={{ textAlign: 'center', width: 55 }}
                                        required
                                        name='twp'
                                        maxLength={3}
                                        placeholder="TWP"
                                        value={searchParamsUWI.twp}
                                        onChange={handleInputChangeUWI} // Update query directly from parent state
                                    />
                                </Col>
                                <Col style={{ width: 60 }}>
                                    <Input style={{ textAlign: 'center', width: 55 }}
                                        required
                                        name='rg'
                                        maxLength={2}
                                        placeholder="RG"
                                        value={searchParamsUWI.rg}
                                        onChange={handleInputChangeUWI} // Update query directly from parent state
                                    />
                                </Col>

                                <Col style={{ width: 55 }}>
                                    <Input min='4' max='6' style={{ textAlign: 'center', width: 50 }}
                                        required
                                        name='m'
                                        maxLength={1}
                                        placeholder="M"
                                        value={searchParamsUWI.m}
                                        onChange={handleInputChangeUWI} // Update query directly from parent state
                                    />
                                </Col>
                                <Col style={{ width: 55 }}>
                                    <Input style={{ textAlign: 'center', width: 50 }}
                                        name='es'
                                        maxLength={2}
                                        placeholder="ES"
                                        value={searchParamsUWI.es}
                                        onChange={handleInputChangeUWI} // Update query directly from parent state
                                    />
                                </Col>
                                <Col span={1}>
                                    <Button type='default' onClick={handleSearchAddToListUWI}
                                        disabled={!areRequiredFieldsFilled}>Apply</Button>
                                </Col>
                            </Row>
                            {!areRequiredFieldsFilled ?
                                <div style={{ fontSize: '0.85em', color: '#1e82d9' }}>
                                    {validationMsg}
                                </div> : ''
                            }
                            {applyListUWI.length >= 1 ? (
                                <>
                                    <br></br>
                                    <div><span style={{ fontWeight: '500' }}>Search List</span></div>
                                    {applyListUWI.map((itemUWI, index) => (
                                        <span key={index} style={{ fontSize: '1.0em' }}>
                                            <Row
                                                style={{
                                                    border: '1px solid #f2f2f2', width: '240px', padding: '2px 6px'
                                                }}>
                                                <Col style={{ width: '210px' }}>{convertDbUwi(itemUWI)}</Col>
                                                <Col style={{ width: '10px' }}><DeleteOutlined
                                                    style={{ fontSize: '15px', color: 'red' }}
                                                    onClick={() => handleRemoveSearchListItemUWI(itemUWI)} /></Col>
                                            </Row>
                                        </span>
                                    ))}
                                </>
                            ) : (
                                ''
                            )}
                            <br></br>
                        </Form.Item>
                        <Row>
                            <Col span={24}>
                                <Button onClick={handleResetUWI}
                                    disabled={requestContext.requestStatusName == 'Completed' ? true : false}>
                                    Reset
                                </Button>
                                &nbsp;
                                <Button type="primary" onClick={handleSearchUWI} loading={loadingSearch}
                                    disabled={requestContext.requestStatusName ==
                                        'Completed' || applyListUWI.length === 0 ? true : false}>
                                    Search
                                </Button>

                            </Col>
                        </Row>

                    </Form>
                </>,
        },
        {
            key: '2',
            label: 'LICENCE',
            children: 'Placeholder for Licence Filter.',
        },
        {
            key: '3',
            label: 'AREA',
            children: 'Placeholder for Area Filter.',
        },
        {
            key: '4',
            label: 'IMPORT',
            children: 'Placeholder for Import Filter.',
        },
        {
            key: '5',
            label: 'SUBSCRIPTION',
            children: 'Placeholder for Subscription Filter.',
            //disabled: false,
        },
    ];

    return (
        <>

            <AppLayout />
            <div className='content'>
                <Breadcrumbs
                    items={[
                        { href: '/dissemination', title: 'File Dissemination' },
                        { href: '/productsearch', title: 'General Product Search' },
                    ]}
                />
                <p></p>
                <p className='Page-heading'>General Product Search</p>
                <Card size='small' style={{ height: 'auto' }}>
                    <Row gutter={6}>
                        <Col span={24}>
                            <Row >

                                <Col span={24}>
                                    <span style={{ color: 'red' }}>*</span>
                                    <span style={{ fontWeight: '500' }}> 1) Select Product</span>

                                    <br></br>
                                    <Select
                                        disabled={requestContext.requestStatusName === 'Completed'}
                                        style={{ width: 200 }}
                                        placeholder="Select a file type"
                                        onChange={handleFileTypeChange}
                                        value={selectedFileType}
                                    >
                                        {fileTypes.map((fileType) => (
                                            <Option
                                                key={fileType.id}
                                                title={fileType.containerName}
                                                value={fileType.name}>
                                                {fileType.name}
                                            </Option>
                                        ))}
                                    </Select>

                                    <br></br>
                                    {selectedFileType === 'Well Log' ?
                                        <>
                                            <br></br>
                                            <span style={{ color: 'red' }}>*</span>
                                            <span style={{ fontWeight: '500' }}> 2) Search {selectedFileType} By
                                            </span><br></br>
                                            <Tabs size='small' tabPosition='top'
                                                defaultActiveKey="1" items={itemsTabWellLog} />
                                        </>
                                        : ''}
                                </Col>

                            </Row>
                        </Col>

                    </Row>
                </Card>
                <Row gutter={24}>
                    <Col span={24}>
                        <p></p>
                        <Card size='small' bordered={false}
                            style={{ width: '100%', padding: '0px', marginTop: '0px' }} >
                            <WellSearchResults
                                selectedFileTypeId={selectedFileTypeId}
                                selectedFileTypeContainerName={selectedFileTypeContainerName}
                                requestDetails={requestDetails}
                                searchResults={searchResults}
                                cartCount={cartCount}
                                updateCartCount={updateCartCount}
                            />
                            {/* <QueryClientProvider client={queryClient}>
                                <DataProvider
                                    fileTypeId={selectedFileTypeId}
                                    searchList={searchList}
                                    searchType={searchType}>
                                    <WellSearchResults />
                                </DataProvider>
                            </QueryClientProvider> */}
                        </Card>
                    </Col>
                </Row>
            </div >
            <AppFooter />

        </>
    );
};

export default WellFileSelection;