import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';
import { getUserRoles } from '../utils/auth';
 
interface UserContextType {
  account: AccountInfo | null;
  roles: string[];
  isLoading: boolean;
};
const UserContext = createContext<UserContextType>({
  account: null,
  roles: [],
  isLoading: true,
});
 
export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { instance } = useMsal();
  const account = useAccount();
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState<string[]>([]);
 
  useEffect(() => {
    const currentAccount = account || instance.getActiveAccount();
    if (currentAccount) {
      const userRoles = getUserRoles(currentAccount);
      setRoles(userRoles);
    }
    setIsLoading(false);
  }, [account, instance]);
 
  return (
    <UserContext.Provider value={{ account, roles, isLoading }}>{children}</UserContext.Provider>
  );
};
 
export const useUser = () => useContext(UserContext);