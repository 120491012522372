import React, { useState } from 'react';
import AppLayout from '../../../components/Layout/AppLayout';
import { AppFooter } from '../../../components/Layout/AppFooter';
import { Card, Row, Col } from 'antd';
import CreateIRMRequestForm from '../../../components/dissemination/irm/form/CreateRequestForm';
import RequestSearchForm from '../../../components/dissemination/irm/form/RequestSearchForm';
import RequestSearchResults from '../../../components/dissemination/irm/RequestSearchResults';
import { searchCustomerRequests } from '../../../services/api';
import Breadcrumbs from '../../../components/Layout/Breadcrumbs';

const ManageIRMRequest: React.FC = () => {

  const [searchResults, setSearchResults] = useState<Array<{ [key: string]: any }>>([]);

  const handleSearch = async (searchParams: { [key: string]: string }) => {
    try {
      const res = await searchCustomerRequests(searchParams);
      const requests = res.data.map((request: any) => ({
        ...request,
        key: request.requestNumber, // Ensure unique key for each record
      }));
      setSearchResults(requests);
    } catch (error) {
      console.error('Failed to fetch search results', error);
      throw error;
    }
  };

  return (
    <>
      <AppLayout />
      <div className='content'>
        <Breadcrumbs
          items={[
            { href: '/dissemination', title: 'File Dissemination' },
            { href: '/dissemination/irm', title: 'Manage IRM Request' },
          ]}
        />

        <p className='Page-heading'>
          Manage IRM Request
          <span style={{ float: 'right', }}>
            <CreateIRMRequestForm /></span>
        </p>


        <Row gutter={12}>
          <Col span={6}>
            <p></p>
            <Card size='small' title="Request Search" bordered={false} style={{ width: '100%' }}>
              <RequestSearchForm onSearch={handleSearch} />
            </Card>
          </Col>
          <Col span={18}>
            <p></p>
            <Card size='small' bordered={false} style={{ width: '100%', padding: '0px' }} >
              <RequestSearchResults searchResults={searchResults} />
            </Card>
          </Col>
        </Row>
      </div>
      <AppFooter />
    </>
  );
};

export default ManageIRMRequest;