/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import {
  DeleteOutlined,
  DownOutlined,
  FileSearchOutlined,
  PauseOutlined,
  ReloadOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  Button,
  Modal,
  Divider,
  Spin,
  Checkbox,
  message,
  Tag,
  Flex,
  Dropdown,
  Space,
} from 'antd';
import type { MenuProps, TableColumnsType } from 'antd';
import {
  deleteCustomerRequest,
  deleteDisseminationRequest,
  getInitialCustomerRequests,
  pauseDisseminationRequest,
  resendDisseminationRequest,
} from '../../../services/api';
import notifyUser from '../../../utils/notifyUser';

interface DataType {
  key: string;
  id: string;
  requestNumber: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  requestTypeName: string;
  requestStatusName: string;
  billingStatusName: string;
  paymentTypeName: string;
  isDisseminationPaused: boolean;
  requestCreatedOn: string;
  //description: string;
}

interface ResultsTableProps {
  searchResults: Array<{ [key: string]: any }>;
}
interface ModalsState {
  delete: boolean;
  pause: boolean;
  resend: boolean;
}
interface RequestContext {
  number: string;
  id: string;
}

const ManageIRMRequestSearchResults: React.FC<ResultsTableProps> = ({ searchResults }) => {
  const [irmRequests, setIRMRequests] = useState([]);
  const navigate = useNavigate();

  //modal handling
  const [modals, setModals] = useState<ModalsState>({
    delete: false,
    pause: false,
    resend: false,
  });

  const [requestNumberContext, setRequestNumberContext] = useState('');
  const [requestIdContext, setRequestIdContext] = useState('');
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const [confirmResend, setConfirmResend] = useState(false);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [processing, setProcessing] = useState<boolean>(false);
  const [msg, setMsg] = message.useMessage();

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Request Number',
      dataIndex: '',
      key: 'requestNumber',
      width: 80,
      sorter: (a: any, b: any) => a.requestNumber.localeCompare(b.requestNumber),
      render: (record) => (
        <>
          <span style={{ fontSize: '0.85em' }}>{record.requestNumber}</span>
        </>
      ),
    },
    {
      title: 'Customer Name',
      dataIndex: '',
      key: 'contactName',
      width: 190,
      sorter: (a: any, b: any) => a.contactName.localeCompare(b.contactName),
      render: (record) => (
        <>
          <span style={{ fontSize: '0.85em' }}>{record.contactName}</span>
        </>
      ),
    },
    {
      title: 'Request Type',
      dataIndex: '',
      key: 'requestTypeName',
      width: 120,
      sorter: (a: any, b: any) => a.requestTypeName.localeCompare(b.requestTypeName),
      render: (record) => (
        <>
          <span style={{ fontSize: '0.85em' }}>{record.requestTypeName}</span>
        </>
      ),
    },
    {
      title: 'Request Status',
      dataIndex: '',
      key: 'x',
      width: 120,
      sorter: (a: any, b: any) => a.requestStatusName.localeCompare(b.requestStatusName),
      render: (record) => (
        <>
          <Flex gap="12px 0" wrap>
            <Tag
              bordered={true}
              color={
                record.requestStatusName === 'Completed'
                  ? '#139A43'
                  : record.requestStatusName === 'Incompleted'
                  ? 'gold'
                  : record.requestStatusName === 'Paused'
                  ? '#f50'
                  : '#108ee9'
              }
            >
              {record.requestStatusName}
            </Tag>
          </Flex>
        </>
      ),
    },
    {
      title: 'Billing Status',
      dataIndex: '',
      key: 'x',
      width: 120,
      sorter: (a: any, b: any) => a.billingStatusName.localeCompare(b.billingStatusName),
      render: (record) => (
        <>
          <Flex gap="12px 0" wrap>
            <Tag
              bordered={true}
              color={
                record.billingStatusName === 'Completed'
                  ? '#139A43'
                  : record.billingStatusName === 'Incompleted'
                  ? 'gold'
                  : record.billingStatusName === 'Paused'
                  ? '#f50'
                  : '#108ee9'
              }
            >
              {record.billingStatusName}
            </Tag>
          </Flex>
        </>
      ),
    },
    // {
    //     title: 'Dissemination Status', dataIndex: 'isDisseminationPaused', key: 'isDisseminationPaused',
    //     width: 170,
    //     sorter: (a: any, b: any) => a.isDisseminationPaused.localeCompare(b.isDisseminationPaused),
    // },
    {
      title: 'Created On',
      dataIndex: '',
      key: 'requestCreatedOn',
      width: 120,
      sorter: (a: any, b: any) => a.requestCreatedOn.localeCompare(b.requestCreatedOn),
      render: (record) => (
        <>
          <span style={{ fontSize: '0.85em' }}>{record.requestCreatedOn}</span>
        </>
      ),
    },
    {
      title: 'Options',
      dataIndex: '',
      key: 'x',
      width: 200,
      render: (record) => {
        const items: MenuProps['items'] = [
          {
            label: 'Pause Dissemination',
            key: '1',
            icon: <PauseOutlined />,
            disabled: record.requestStatusName === 'Completed' ? false : true,
            onClick: () => handlePauseModal(record.requestNumber, record.id),
          },
          {
            label: 'Resend Dissemination',
            key: '2',
            icon: <ReloadOutlined />,
            disabled:
              record.requestStatusName === 'Incompleted' || record.requestStatusName === 'New'
                ? true
                : false,
            onClick: () => handleResendModal(record.requestNumber, record.id),
          },
          {
            label: 'Delete Request',
            key: '3',
            icon: <DeleteOutlined />,
            danger: true,
            onClick: () => handleDeleteModal(record.requestNumber, record.id),
          },
        ];

        const menuProps = {
          items,
        };

        return (
          <>
            <Button
              size="middle"
              type="default"
              icon={<FileSearchOutlined />}
              onClick={() => {
                navigate('/dissemination/irm/' + record.requestNumber);
              }}
            >
              Manage
            </Button>
            &nbsp;
            {/* <Button
                            size='small'
                            //danger={(record.requestStatusName == 'Paused' ? false : true)}

                            style={{
                                marginLeft: '5px',
                                display: (record.requestStatusName === 'New'
                                    || record.requestStatusName === 'Incompleted') ?
                                    'none' : 'inline-flex',
                                color: (record.requestStatusName === 'Paused' ?
                                    'green' : 'red')
                            }}
                            icon={(record.requestStatusName === 'Paused' || record.requestStatusName === null)
                                ? <ReloadOutlined />
                                : <PauseOutlined />
                            }
                            onClick={() => (record.requestStatusName == 'Paused' ?
                                handleResendModal(record.requestNumber, record.id) :
                                handlePauseModal(record.requestNumber, record.id))}

                        // disabled={(record.requestStatusName === 'Incompleted'
                        //     || record.requestStatusName != 'Paused') ?
                        //     true : false}
                        >
                            {(record.requestStatusName == 'Completed' ? 'Pause' : 'Resend')}
                        </Button> */}
            {/* <Button
                            size='small'
                            danger
                            style={{
                                marginLeft: '5px'
                            }}
                            icon={< DeleteOutlined />}
                            onClick={() => handleDeleteModal(record.requestNumber, record.id)}></Button> */}
            <Dropdown menu={menuProps}>
              <Button size="middle" icon={<DownOutlined />}>
                {/* <Space>
                                    <DownOutlined />
                                </Space> */}
              </Button>
            </Dropdown>
          </>
        );
      },
    },
  ];

  //============ON DOM LOAD
  const fetchAllRequests = async () => {
    try {
      const res = await getInitialCustomerRequests();
      const requests = res.data[0].map((request: any) => ({
        ...request,
        key: request.requestNumber, // Ensure unique key for each record
      }));
      setIRMRequests(requests);
    } catch (error) {
      //console.debug(error);
    } finally {
      setLoadingData(false);
    }
    //return true; // Indicates asynchronous response
  };

  useEffect(() => {
    fetchAllRequests();
  }, []);

  //============EVENT HANDLE

  //Request Deletion
  const handleDeleteModal = (requestNumber: string, requestId: string) => {
    setConfirmDeletion(false);
    setRequestNumberContext(requestNumber);
    setRequestIdContext(requestId);
    setModals({ ...modals, delete: true });
  };

  const handleConfirmDeletion = () => {
    setConfirmDeletion(!confirmDeletion);
  };

  const handleDelete = async (requestIdContext: string) => {
    setProcessing(true);
    try {
      const res = await deleteCustomerRequest(requestIdContext);

      //res.status == 200?
      notifyUser('success', res.data.message);
      /*
            if (res.status != 200) {
                notifyUser('warning', res.data.message);
            } else {
                const resms = await deleteDisseminationRequest(requestIdContext);
                resms.status === 200 ?
                    notifyUser('success', res.data.message) : notifyUser('success', resms.data.message);
            }
                    */
    } catch (error) {
      notifyUser('error', 'Unable to delete the customer request: ' + error);
    } finally {
      setProcessing(false);
    }

    // Close the modal and refresh the list.
    setModals({ ...modals, delete: false });
    //setModalDelete(false);
    fetchAllRequests();
    //reset the confirmation check.
    setConfirmDeletion(false);

    return true; // Indicates asynchronous response
  };

  //Pause Dissemination
  const handlePauseModal = (requestNumber: string, requestId: string) => {
    setRequestNumberContext(requestNumber);
    setRequestIdContext(requestId);
    setModals({ ...modals, pause: true });
  };

  const handlePause = async (requestId: string) => {
    setProcessing(true);
    try {
      // Set the dissemination request to paused and trigger Dissemination MS inactivate the link to the download.
      await pauseDisseminationRequest(requestId);
      notifyUser('success', 'Pause Dissemination triggered successfully.');

      //Refetch requests
      fetchAllRequests();
    } catch (error) {
      notifyUser(
        'error',
        'Unable to pause dissemination request at this time. Please contact technical support for assistance.'
      );
    } finally {
      setProcessing(false);
    }

    //Close Modal
    setModals({ ...modals, pause: false });

    return true; // Indicates asynchronous response
  };

  //Resend Dissemination
  const handleResendModal = (requestNumber: string, requestId: string) => {
    setConfirmResend(false);
    setRequestNumberContext(requestNumber);
    setRequestIdContext(requestId);
    setModals({ ...modals, resend: true });
  };

  const handleConfirmResend = () => {
    setConfirmResend(!confirmResend);
  };

  const handleResend = async (requestId: string) => {
    // Re-trigger the dissemination for the IRM requested.

    setProcessing(false);
    try {
      await resendDisseminationRequest(requestId);
      notifyUser('success', 'Dissemination resent successfully for IRM' + requestNumberContext);

      //Refetch requests.
      fetchAllRequests();
    } catch (error) {
      notifyUser(
        'error',
        `Unable to resend dissemination request at this time.
                Please contact technical support for assistance.`
      );
    } finally {
      setProcessing(false);
    }

    // Close modal
    setModals({ ...modals, resend: false });

    return true; // Indicates asynchronous response
  };

  const handleCancel = () => {
    setModals({ ...modals, delete: false });
    setConfirmDeletion(false);
    console.log(confirmDeletion);
  };

  return (
    <>
      {setMsg}
      <Modal
        title={'Request Deletion: ' + 'IRM' + requestNumberContext}
        open={modals.delete}
        onCancel={() => {
          handleCancel();
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              handleCancel();
            }}
          >
            Cancel
          </Button>,
          <Button
            loading={processing}
            key="submit"
            disabled={!confirmDeletion}
            type="primary"
            danger
            onClick={() => handleDelete(requestIdContext)}
          >
            Process Deletion
          </Button>,
        ]}
      >
        <Divider type="horizontal" className="dividerColor, dividerTop"></Divider>

        <p>
          Are you sure you want to perform the deletion for request {'IRM' + requestNumberContext}?
        </p>
        <p>Please note that all associated file records for this request will be deleted.</p>
        <p>
          Additionally, any files that may have been disseminated for this request will be made
          unavilable immediately to the customer.
        </p>
        <br></br>
        <Checkbox
          checked={confirmDeletion}
          onChange={() => {
            handleConfirmDeletion();
          }}
        >
          Yes, I confirm the deletion.
        </Checkbox>
        <Divider type="horizontal" className="dividerColor, dividerBottom"></Divider>
      </Modal>

      <Modal
        title={'Pause Dissemination: ' + 'IRM' + requestNumberContext}
        open={modals.pause}
        onCancel={() => {
          handleCancel();
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setModals({ ...modals, pause: false });
            }}
          >
            Cancel
          </Button>,
          <Button
            loading={processing}
            key="submit"
            type="primary"
            danger
            onClick={() => handlePause(requestIdContext)}
          >
            Pause Dissemination
          </Button>,
        ]}
      >
        <Divider type="horizontal" className="dividerColor, dividerTop"></Divider>
        <p>Are you sure you want to pause the dissemination for {'IRM' + requestNumberContext}?</p>
        <p>
          Please note that all associated files for this request will be unavailable to the
          customer.
        </p>
        <Divider type="horizontal" className="dividerColor, dividerBottom"></Divider>
      </Modal>

      <Modal
        title={'Resend Dissemination: ' + 'IRM' + requestNumberContext}
        open={modals.resend}
        onCancel={() => {
          handleCancel();
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setModals({ ...modals, resend: false });
            }}
          >
            Cancel
          </Button>,
          <Button
            loading={processing}
            key="submit"
            disabled={!confirmResend}
            type="primary"
            onClick={() => handleResend(requestIdContext)}
          >
            Resend Dissemination
          </Button>,
        ]}
      >
        <Divider type="horizontal" className="dividerColor, dividerTop"></Divider>
        <p>Are you sure you want to resend the dissemination for {'IRM' + requestNumberContext}?</p>
        <p>
          Please note that all associated files for this request will be available to the customer.
        </p>
        <br></br>
        <Checkbox checked={confirmResend} onChange={handleConfirmResend}>
          Yes, I want to resend the dissemination.
        </Checkbox>
        <Divider type="horizontal" className="dividerColor, dividerBottom"></Divider>
      </Modal>

      {/* {loadingData ? (
                <Spin spinning={loadingData} tip="Loading..." />
            ) : ( */}
      <Spin spinning={loadingData} tip="Loading...">
        <Table
          size="small"
          columns={columns}
          expandable={{
            expandedRowRender: (record) => (
              <p key={record.requestNumber} style={{ margin: 0 }}>
                Customer Email: {record.contactEmail}
                {record.contactPhone ? ` | Customer Phone: ${record.contactPhone}` : ''}
              </p>
            ),
            rowExpandable: (record) => record.requestNumber !== 0,
          }}
          dataSource={searchResults.length != 0 ? searchResults : irmRequests}
        />
      </Spin>
      {/* )} */}
    </>
  );
};

export default ManageIRMRequestSearchResults;
