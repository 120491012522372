/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import {
  DollarOutlined,
  FileSearchOutlined,
  LeftCircleOutlined,
  QuestionCircleOutlined,
  RightCircleOutlined,
  SendOutlined,
  ShoppingOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  FloatButton,
  Modal,
  Empty,
  Button,
  Divider,
  Row,
  Col,
  Statistic,
  Checkbox,
  Input,
  Select,
  Flex,
} from 'antd';
import {
  getBillingStatuses,
  getDisseminationRequestPayload,
  getFilesByRequestId,
  getPaymentTypes,
  setCustomerRequestStatus,
  upsertDisseminationRequest,
} from '../../../services/api';
import RequestCartList from './RequestCartList';
import notifyUser from '../../../utils/notifyUser';
import { Form, useNavigate } from 'react-router-dom';
import FormItem from 'antd/es/form/FormItem';

// get the server domain root api
//const apiUrl = config.API_URL;

interface RequestDetails {
  id: string;
  requestNumber: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  companyName: string;
  requestTypeName: string;
  requestStatusName: string;
  requestFileTypes: [
    {
      requestFileTypeId: string;
      requestFileTypeName: string;
    }
  ];
}

interface RequestDetailsProps {
  requestDetails: RequestDetails;
  cartCount: number;
  updateCartCount: (newCount: number) => void;
  fileTypes: FileType[];
}

interface RequestFiles {
  id: string;
  customerRequestId: string;
  fileTypeId: string;
  fileName: string;
  filePath: string;
  fileSize: string;
  associatedUWI: string;
  associatedLicence: string;
  confidentialityStatus: string;
  containerName: string;
}

interface FileType {
  id: string;
  name: string;
  containerName: string;
}

interface BillingStatus {
  id: string;
  name: string;
}

interface PaymentType {
  id: string;
  name: string;
}

interface ModalsState {
  fileSync: boolean;
  cart: boolean;
  help: boolean;
  disseminateFiles: boolean;
}

const { TextArea } = Input;

const MainRequestActions: React.FC<RequestDetailsProps> = ({
  requestDetails,
  cartCount,
  updateCartCount,
  fileTypes,
}) => {
  const [loadingCart, setLoadingCart] = useState(false);
  const [loadingDisseminate, setLoadingDisseminate] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [selectedBillingStatus, setSelectedBillingStatus] = useState(undefined);
  const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([]);
  const [billingStatus, setBillingStatus] = useState<BillingStatus[]>([]);

  //const [modalDisseminateFiles, setModalDisseminateFiles] = useState(false);

  const [disseminationConfirmation, setDisseminationConfirmation] = useState(false);

  const navigate = useNavigate();

  //modal handling
  const [modals, setModals] = useState<ModalsState>({
    fileSync: false,
    cart: false,
    help: false,
    disseminateFiles: false,
  });

  const [cartData, setCartData] = useState<RequestFiles[]>([null]);

  const fetchBillingStatuses = async () => {
    try {
      const res = await getBillingStatuses();
      setBillingStatus(res.data);
    } catch (error) {
      console.error('Error fetching billing statuses:', error);
    }
  };

  const fetchPaymentTypes = async () => {
    try {
      const paymentTypesResponse = await getPaymentTypes();
      setPaymentTypes(paymentTypesResponse.data);
    } catch (error) {
      console.error('Error fetching payment types:', error);
    }
  };

  useEffect(() => {
    //console.log('selected:', selectedBillingStatus);
    fetchPaymentTypes;
    fetchBillingStatuses();
    fetchRequestFiles();
  }, [selectedBillingStatus]); // Empty dependency array to run only once on mount

  // const handleBillingModal = async () => {
  //   const res = await getBillingStatuses();
  //   setBillingStatusList(res.data);
  //   console.log('billing status:', billingStatusList);
  // };

  const handleChangeBillingStatus = (value: string) => {
    setSelectedBillingStatus(value);
  };

  const handleCartModal = () => {
    //setModalHelp(false);
    //setModalDisseminateFiles(false);
    //setModalCart(state);
    setModals({ ...modals, fileSync: false, disseminateFiles: false, help: false });
    setModals({ ...modals, cart: true });
    //if (state) {
    fetchRequestFiles();
    //}
  };

  //Close all modals.
  const handleCancel = () => {
    //setModalCart(false);
    setModals({ ...modals, fileSync: false, cart: false, disseminateFiles: false, help: false });

    //setModalDisseminateFiles(false);
    //setModalHelp(false);
    console.log('Closing modal');
  };

  const handleSync = () => {
    //alert('File Synchronization Triggered!');
    notifyUser('success', '(Demo) - File Synchronization Triggered!');
  };

  //Handle the cart content and go to the dissemination modal to display summary details.
  const handleCart = () => {
    setLoadingCart(true);
    setTimeout(() => {
      setLoadingCart(false);
      setModals({ ...modals, cart: false, disseminateFiles: true });
      //setModalDisseminateFiles(true);
      //setModalCart(false);
    }, 500);
  };
  // //Handle previewing of the documents.
  // const handlePreviewOk = () => {
  //   setLoadingPreview(true);
  //   setTimeout(() => {
  //     setLoadingPreview(false);
  //     alert('Processing preview');
  //     handleCancel();
  //   }, 3000);
  // };

  //handle dissemination confirmation
  const handleDisseminationConfirmation = () => {
    setDisseminationConfirmation(!disseminationConfirmation);
  };

  //Handle preview of files.
  const handlePreview = async () => {
    setLoadingPreview(true);

    const sendEmail = false;

    try {
      const res = await getDisseminationRequestPayload(requestDetails.id, sendEmail);
      const disseminationResponse = await upsertDisseminationRequest(res.payload, sendEmail);

      if (disseminationResponse != null) {
        //If request is being previewed,
        //  1. Open a new tab with the url from the microservice
        //  listing all the files for the dissemination.

        const url = `${disseminationResponse.data.requestUrl}`;
        //const url = (`https://appsdev.aer.ca/file-disseminator${disseminationResponse.data.requestUrl}`);
        window.open(url, '_blank');
        //console.log('Dissemination Response:', url);
      }

      //notifyUser('success', 'Dissemination Request Submitted.');
    } catch (error) {
      notifyUser('warning', 'Unable to preview the request. Error: ' + error);
    }

    setTimeout(() => {
      setLoadingPreview(false);
      //setModalDisseminateFiles(false);
    }, 3000);
  };

  //Handle dissemination of files.
  const handleDisseminate = async () => {
    setLoadingDisseminate(true);

    const sendEmail = true;

    try {
      const res = await getDisseminationRequestPayload(requestDetails.id, sendEmail);

      const disseminationResponse = await upsertDisseminationRequest(res.payload, sendEmail);

      if (disseminationResponse != null) {
        /*If request is disseminated,
            1. Set the request to completed
            2. Redirect user to the manage IRM request page.
                  
          */
        await setCustomerRequestStatus(requestDetails.id, 'Completed');

        //const url = (`https://appsdev.aer.ca/file-disseminator${disseminationResponse.data.requestUrl}`);
        //window.open(url, '_blank');
        //console.log('Dissemination Response:', url);
      }

      notifyUser('success', 'Dissemination Request Submitted.');
    } catch (error) {
      notifyUser('warning', 'Unable to disseminate the request. Error: ' + error);
    }

    setTimeout(() => {
      setLoadingDisseminate(false);
      //setModalDisseminateFiles(false);
      setModals({ ...modals, disseminateFiles: false });
      navigate('/dissemination/irm');
    }, 4000);
  };

  const fetchRequestFiles = async () => {
    const res = await getFilesByRequestId(requestDetails?.id);
    console.log('res: ', res);
    setCartData(res);
  };

  useEffect(() => {
    if (requestDetails?.id) {
      fetchRequestFiles();
    }
  }, []);

  return (
    <>
      <FloatButton.Group
        shape="square"
        style={{
          position: 'fixed',
          top: 125,
          right: 45,
          width: 41,
          backgroundColor: '#E3E7F1',
          maxHeight: '123px', // Adjust this to control the height
          overflowY: 'clip', // Enables scrolling if there are more buttons than fit within maxHeight
        }}
      >
        {/* <FloatButton
          icon={<SendOutlined />}
          tooltip={<div>Disseminate Files</div>}
          onClick={() => {
            setModals({ ...modals, disseminateFiles: true });
          }}
        /> */}
        <FloatButton
          icon={<SyncOutlined />}
          tooltip={<div>Trigger File Synchronization</div>}
          onClick={handleSync}
        />
        <FloatButton
          icon={<ShoppingOutlined />}
          tooltip={<div>Your Requested Files</div>}
          badge={{ count: cartCount, color: 'green', overflowCount: 9999 }}
          onClick={() => {
            fetchRequestFiles();

            setModals({ ...modals, cart: true });
          }}
        />

        <FloatButton
          icon={<QuestionCircleOutlined />}
          tooltip={<div>Help</div>}
          onClick={() => {
            setModals({ ...modals, help: true });
          }}
        />
      </FloatButton.Group>

      <Modal
        title="Billing Management"
        //centered
        open={modals.fileSync}
        //cancelText="Close"
        onCancel={() => {
          setModals({ ...modals, fileSync: false });
        }}
        width="900px"
        footer={[
          <Button
            type="primary"
            key="save"
            onClick={() => {
              setModals({ ...modals, fileSync: false });
            }}
          >
            Save
          </Button>,
        ]}
      >
        <Divider
          type="horizontal"
          style={{ width: '5px', marginTop: '7px', marginBottom: '10px' }}
        ></Divider>
        <Row>
          <Col span={12}>Notes</Col>
          <Col span={12}>
            <strong>Change Billing Status</strong>
            <br></br>
            <FormItem>
              <Select
                placeholder="Select a payment type"
                value={selectedBillingStatus}
                onChange={(value) => {
                  handleChangeBillingStatus(value);
                }}
              >
                {Array.isArray(billingStatus) &&
                  billingStatus.map((billing) => (
                    <Select.Option key={billing.id} value={billing.name}>
                      {' '}
                      {/* Use Select.Option here */}
                      {billing.name}
                    </Select.Option>
                  ))}
              </Select>
            </FormItem>
            <p></p>
            Add Billing Details<br></br>
            <TextArea rows={4} placeholder="maxLength is 6" maxLength={6}>
              {selectedBillingStatus}
            </TextArea>
          </Col>
        </Row>
        <Divider
          type="horizontal"
          style={{ width: '5px', marginTop: '7px', marginBottom: '10px' }}
        ></Divider>
      </Modal>

      <Modal
        title="Requested Files - Summary"
        // centered
        open={modals.cart}
        //open={modalCart}
        onCancel={() => {
          setModals({ ...modals, cart: false });
        }}
        width="1240px"
        footer={[
          <>
            <Divider
              type="horizontal"
              style={{ width: '5px', marginTop: '7px', marginBottom: '10px' }}
            ></Divider>
            <Button
              key="back"
              onClick={() => {
                setModals({ ...modals, cart: false });
              }}
            >
              Close
            </Button>
            {/* <Button
              key="submit"
              type="primary"
              disabled={
                cartCount > 0 && requestDetails && requestDetails.requestStatusName != 'Completed'
                  ? false
                  : true
              }
              loading={loadingCart}
              onClick={handleCart}
            >
              Continue <RightCircleOutlined />
            </Button> */}
          </>,
        ]}
      >
        <Divider
          type="horizontal"
          style={{ width: '5px', marginTop: '7px', marginBottom: '10px' }}
        ></Divider>

        <Row gutter={24}>
          <Col span={18}>
            <RequestCartList
              requestDetails={requestDetails}
              fileTypes={fileTypes}
              cartCount={cartCount}
              updateCartCount={updateCartCount}
              cartData={cartData}
              setCartData={setCartData}
            />
          </Col>
          <Col span={6}>
            Summary
            <Statistic title="Total Files added to Request" value={cartCount} />
            <br></br>
            <Statistic title="Overall File Size" value={'To Be Populated'} />
          </Col>
        </Row>

        <br></br>
        <br></br>
      </Modal>

      <Modal
        title="Dissemination - Summary"
        //centered
        open={modals.disseminateFiles}
        okText="Disseminate Files"
        onCancel={() => {
          setModals({ ...modals, disseminateFiles: false });
        }}
        width="700px"
        footer={[
          <>
            <Divider
              type="horizontal"
              style={{ width: '5px', marginTop: '7px', marginBottom: '10px' }}
            ></Divider>
            <Button key="back" onClick={handleCartModal}>
              <LeftCircleOutlined /> Requested Files
            </Button>
            <Button
              key="preview"
              type="primary"
              disabled={
                cartCount > 0 && requestDetails && requestDetails.requestStatusName !== 'Completed'
                  ? false
                  : true
              }
              loading={loadingPreview}
              onClick={handlePreview}
            >
              <FileSearchOutlined /> Preview
            </Button>
            <Button
              key="submit"
              type="primary"
              disabled={
                cartCount > 0 &&
                disseminationConfirmation &&
                requestDetails &&
                requestDetails.requestStatusName !== 'Completed'
                  ? false
                  : true
              }
              loading={loadingDisseminate}
              onClick={handleDisseminate}
            >
              <SendOutlined /> Disseminate
            </Button>
          </>,
        ]}
      >
        <Divider
          type="horizontal"
          style={{ width: '5px', marginTop: '7px', marginBottom: '10px' }}
        ></Divider>
        {cartData.length === 0 ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={'No files selected for dissemination.'}
          />
        ) : (
          <>
            <Row gutter={24}>
              <Col span={12}>
                <span style={{ fontWeight: '500' }}>Request Details</span>
                <p>
                  Total File Count: {cartCount}
                  <br></br>
                  Total File Size: {'TBD'}
                </p>

                <span style={{ fontWeight: '500' }}>Customer Information</span>
                <br></br>
                {requestDetails && requestDetails.contactName}
                <br></br>
                {requestDetails && requestDetails.contactEmail}
                <br></br>
                {requestDetails && requestDetails.contactPhone}
                <br></br>
              </Col>
              <Col span={12}>
                <span style={{ fontWeight: '500' }}>Confirmation</span>
                <br></br>
                <span style={{ fontSize: '0.85em' }}>
                  The selected files will be disseminated to the customer. The customer will
                  received an email with the link to download the files for each product selected.
                  <br></br>
                </span>
                <br></br>
                <br></br>
                <Checkbox
                  disabled={
                    cartCount > 0 &&
                    requestDetails &&
                    requestDetails.requestStatusName !== 'Completed'
                      ? false
                      : true
                  }
                  onClick={() => {
                    handleDisseminationConfirmation();
                  }}
                >
                  Yes, I confirm the dissemination.
                </Checkbox>
              </Col>
            </Row>
          </>
        )}
      </Modal>

      <Modal
        title="Help & Support"
        //centered
        open={modals.help}
        //cancelText="Close"
        onCancel={() => handleCancel()}
        width="900px"
        footer={[
          <Button
            key="back"
            onClick={() => {
              setModals({ ...modals, help: false });
            }}
          >
            Close
          </Button>,
        ]}
      >
        Help information to follow.
      </Modal>
    </>
  );
};

export default MainRequestActions;
