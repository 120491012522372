import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  message,
  Modal,
  notification,
  Popconfirm,
  Row,
  Table,
  Tooltip,
} from 'antd';
import {
  CloseOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { deleteAdditionalFile, getAdditionalFiles, uploadFiles } from '../../../../services/blob';
import notifyUser from '../../../../utils/notifyUser';

interface RequestProps {
  requestNumber: string;
}

const UploadAdditionalFile: React.FC<{ requestNumber: string }> = ({ requestNumber }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [storedfiles, setStoredFiles] = useState<string[]>([]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getFilesFromStorage = async (): Promise<any> => {
    const response = await getAdditionalFiles(requestNumber);
    const files = response?.files?.map((file: any) => file.split('/')[1]);
    setStoredFiles(files);
  };

  const dataSource = storedfiles?.map((file: any) => ({ file, key: file }));
  useEffect(() => {
    if (requestNumber) {
      getFilesFromStorage();
    }
  }, [requestNumber]);
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const handleFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files ? Array.from(e.target.files) : [];
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleUpload = async () => {
    setUploading(true);

    try {
      const response = await uploadFiles(files, requestNumber);
      notifyUser('success', 'Files uploaded successfully.');
      setFiles([]);
      getFilesFromStorage();
    } catch (error) {
      notifyUser('error', 'Upload failed.');
    }
    setUploading(false);
  };

  const deleteFile = async (file: any) => {
    const res = await deleteAdditionalFile(requestNumber, encodeURIComponent(file.key));
    if (res === 'Success') {
      notifyUser('success', 'File deleted successfully!');
      getFilesFromStorage();
    } else {
      notifyUser('error', 'Unexpected error happened!');
    }
  };
  const handleClick = () => {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) fileInput.click();
  };

  const handleRemove = (fileName: any) => {
    const remainingFiles = files?.filter((file) => file?.name !== fileName);
    setFiles(remainingFiles);
  };

  const columns = [
    {
      title: 'File Name',
      dataIndex: 'file',
      key: 'file',
    },
    {
      title: 'Delete',
      key: 'deleteIcon',
      width: 50,
      onCell: () => {
        return {
          onClick: (event: any) => {
            event.stopPropagation();
          },
        };
      },
      render: (_: any, file: any) => (
        <Popconfirm
          title="Delete File"
          description={'Are you sure to delete this file?'}
          onConfirm={() => deleteFile(file)}
          okText="Yes"
          cancelText="No"
        >
          <Button
            icon={<DeleteOutlined />}
            size={'small'}
            style={{ backgroundColor: 'white', color: 'red' }}
          />
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Tooltip title="Upload Additional Files for this Request">
        <Button
          onClick={showModal}
          style={{ margin: '0 5px' }}
          size="middle"
          type="primary"
          icon={<CloudUploadOutlined />}
        >
          Upload Additional Files
        </Button>
      </Tooltip>
      <Modal
        width={1200}
        title="Upload Additional Files"
        open={isModalOpen}
        onClose={() => handleCancel()}
        onCancel={() => handleCancel()}
        footer={[
          <>
            <Button
              key="cancel"
              onClick={() => {
                handleCancel();
              }}
            >
              Close
            </Button>
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={files.length === 0}
              loading={uploading}
            >
              {uploading ? 'Uploading' : 'Upload File(s)'}
            </Button>
          </>,
        ]}
      >
        <Divider type="horizontal" className="dividerColor, dividerTop"></Divider>
        <Row style={{ flexFlow: 'nowrap' }}>
          <Col span={14}>
            {storedfiles?.length > 0 && (
              <Table
                size="small"
                title={() => 'Existing Additional Files'}
                columns={columns}
                dataSource={dataSource}
              ></Table>
            )}
          </Col>
          <Divider type="vertical" style={{ height: 'auto', alignSelf: 'stretch' }}></Divider>
          <Col span={10}>
            <div className="file-uploader">
              <input
                type="file"
                multiple
                onChange={handleFileInput}
                style={{ display: 'none' }}
                id="fileInput"
              />
              <label htmlFor="fileInput" className="file-input-label">
                <Button icon={<UploadOutlined />} onClick={handleClick} type="primary">
                  Select File(s)
                </Button>
              </label>

              <div className="file-list">
                {files.map((file, index) => (
                  <div key={index} className="file-item">
                    <span>{file.name}</span>
                    <Button
                      onClick={() => handleRemove(file.name)}
                      icon={<CloseOutlined />}
                      size="small"
                      type="text"
                      danger
                    />
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <Divider type="horizontal" className="dividerColor, dividerTop"></Divider>
      </Modal>
    </>
  );
};

export default UploadAdditionalFile;
