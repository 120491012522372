import React from 'react';
import AppLayout from '../../components/Layout/AppLayout';
import { AppFooter } from '../../components/Layout/AppFooter';

const ManageSubscription: React.FC = () => {
  return (
    <>
      <AppLayout />
      <div className='content'>
        <p className='Page-heading'>Manage Subscriptions</p>
      </div>
      <AppFooter />
    </>
  );
};

export default ManageSubscription;