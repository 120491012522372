/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Spin, Flex, Tag, Row, Col } from 'antd';
import type { TableColumnsType } from 'antd';
import { format } from 'date-fns';

import notifyUser from '../../utils/notifyUser';
import type { ColumnsType } from 'antd/es/table';
import {
  addCustomerRequestFiles,
  getAssociatedWellLogFiles,
  getContainerNameByFileTypeId,
  removeCustomerRequestFiles,
} from '../../services/api';
import { DownloadOutlined } from '@ant-design/icons';
import convertDbUwi from '../../utils/convertDbUwi';
import { downloadFile } from '../../services/blob';
import useToken from 'antd/es/theme/useToken';
import { userInfo } from 'os';
//import { BlobServiceClient } from '@azure/storage-blob';

interface WellInfo {
  key: string;
  id: string;
  uwi: string;
  licence: string;
  confStatus: string;
  confRelease: string;
  wellName: string;
  finalDrillDate: string;
  finalTd: string;
  fileCount: number;
  files?: FileInfo[];
  selectedFiles?: string[];
  loadingFiles?: boolean; // Indicates if files are being fetched
}

interface FileInfo {
  id: string;
  uwi: string;
  docType: string;
  fileName: string;
  size: string;
  runDate: string;
  start: string;
  stop: string;
  step: string;
  tvdm: string;
  serviceCompany: string;
  legacyCo: string;
  logType: string;
  selected?: boolean;
}

interface RequestDetailsProps {
  id: string;
  requestNumber: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  companyName: string;
  requestTypeName: string;
  requestStatusName: string;
  requestFileTypes: [
    {
      requestFileTypeId: string;
      requestFileTypeName: string;
    }
  ];
}
interface ResultsTableProps {
  searchResults: Array<{ [key: string]: any }>;
  requestDetails: RequestDetailsProps;
  selectedFileTypeId: string | undefined;
  selectedFileTypeContainerName: string | undefined;
  cartCount: number;
  updateCartCount: (newCount: number) => void;
}

const WellSearchResults: React.FC<ResultsTableProps> = ({
  searchResults,
  requestDetails,
  selectedFileTypeId,
  selectedFileTypeContainerName,
  cartCount,
  updateCartCount,
}) => {
  //const [initialLoad, setInitialLoad] = useState([]);
  //const [wellResults, setWellResults] = useState([]);
  const [wellsData, setWellsData] = useState<WellInfo[]>([]);
  const navigate = useNavigate();
  //const [selectedWells, setSelectedWells] = useState<Set<string>>(new Set());
  const [selectedFiles, setSelectedFiles] = useState<Set<string>>(new Set());
  const [notificationMsg, setNotificationMsg] = useState('');
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const wellColumns: TableColumnsType<WellInfo> = [
    {
      title: 'UWI',
      dataIndex: '',
      key: 'q',
      width: 180,

      sorter: (a: any, b: any) => {
        const valA = a.uwi || ''; // Default to empty string if null/undefined
        const valB = b.uwi || ''; // Default to empty string if null/undefined
        return valA.localeCompare(valB);
      },
      render: (record: any) => {
        return (
          <>
            <span
              style={{ color: record.confidentialityStatus == 'Confidential' ? 'red' : 'black' }}
            >
              {record && convertDbUwi(record.uwi)}
            </span>
          </>
        );
      },
    },
    {
      title: 'Historical UWI',
      dataIndex: '',
      key: 'historicalUwi',
      width: 180,
      sorter: (a: any, b: any) => {
        const valA = a.historicalUwi || '';
        const valB = b.historicalUwi || '';
        return valA.localeCompare(valB);
      },
      render: (record: any) => {
        return (
          <>
            {
              <span
                style={{ color: record.confidentialityStatus == 'Confidential' ? 'red' : 'black' }}
              >
                {record && record.historicalUwi ? convertDbUwi(record.historicalUwi) : '-'}
              </span>
            }
          </>
        );
      },
    },
    {
      title: 'Licence',
      dataIndex: 'licence',
      key: 'licence',
      width: 100,
      sorter: (a: any, b: any) => {
        const valA = a.licence || '';
        const valB = b.licence || '';
        return valA.localeCompare(valB);
        //a.licence.localeCompare(b.licence)
      },
    },
    {
      title: 'Conf Status',
      dataIndex: '',
      key: 'x',
      width: 150,
      sorter: (a: any, b: any) => {
        const valA = a.confStatus || '';
        const valB = b.confStatus || '';
        return valA.localeCompare(valB);
      },
      render: (record) => (
        <>
          <Flex gap="12px 0" wrap>
            <Tag
              bordered={true}
              color={
                record.confStatus === 'Confidential' || record.confStatus === 'Confidential Below'
                  ? '#f50'
                  : '#139A43'
              }
            >
              {record.confStatus}
            </Tag>
          </Flex>
        </>
      ),
    },
    {
      title: 'Conf Release',
      dataIndex: '',
      key: 'confRelease',
      width: 170,
      sorter: (a: any, b: any) => {
        const valA = a.confRelease || '';
        const valB = b.confRelease || '';
        return valA.localeCompare(valB);
      },
      render: (record) => {
        return (
          <>
            {
              <span
                style={{ color: record.confidentialityStatus == 'Confidential' ? 'red' : 'black' }}
              >
                {record && record.confRelease
                  ? format(new Date(record.confRelease), 'MMMM d, yyyy')
                  : '-'}
              </span>
            }
          </>
        );
      },
    },
    {
      title: 'Well Name',
      dataIndex: '',
      key: 'wellName',
      //width: 120,
      sorter: (a: any, b: any) => {
        const valA = a.wellName || '';
        const valB = b.wellName || '';
        return valA.localeCompare(valB);
      },
      render: (record) => {
        return (
          <>
            {
              <span
                style={{ color: record.confidentialityStatus == 'Confidential' ? 'red' : 'black' }}
              >
                {record && record.wellName ? record.wellName : '-'}
              </span>
            }
          </>
        );
      },
    },
    {
      title: 'Finish Drill Date',
      dataIndex: '',
      key: 'finalDrillDate',
      width: 150,
      sorter: (a: any, b: any) => {
        const valA = a.finalDrillDate || '';
        const valB = b.finalDrillDate || '';
        return valA.localeCompare(valB);
      },
      render: (record: any) => {
        return record && record.finalDrillDate
          ? format(new Date(record.finalDrillDate), 'MMMM d, yyyy')
          : '-';
      },
    },
    {
      title: 'Final TD',
      dataIndex: '',
      key: 'finalTD',
      width: 100,
      sorter: (a: any, b: any) => {
        const valA = a.finalTd || '';
        const valB = b.finalTd || '';
        return valA.localeCompare(valB);
      },
      render: (record: any) => {
        return record && record.finalTd ? record.finalTd : '-';
      },
    },
    {
      title: 'Files',
      dataIndex: 'fileCount',
      key: 'fileCount',
      width: 90,
    },
    // {
    //     title: '',
    //     render: (record: WellInfo) => (
    //         <Checkbox
    //             disabled={record.confStatus === 'Non Confidential' ? false : true}
    //             checked={!!record.selectedFiles?.length}
    //             onChange={(e) => handleSelectWell(record.uwi, e.target.checked)}
    //         />
    //     ),
    // },
    // {
    //     title: 'Add to Cart',
    //     width: 90,
    //     dataIndex: '',
    //     key: 'x',
    //     render: (_, record) => (
    //         <Checkbox
    //             disabled={record.confStatus === 'Confidential' ||
    //                 record.confStatus === 'Confidential Below' ? true : false}
    //             onChange={(e) => handleSelectWell(record.uwi, e.target.checked)}
    //         >

    //         </Checkbox>
    //     )
    // },
  ];

  const fileColumns: ColumnsType<FileInfo> = [
    {
      title: 'Doc Type',
      dataIndex: '',
      width: 120,
      key: 'docType',
      render: (record: any) => {
        return (
          <>
            {record && record.docType ? (
              <span style={{ fontSize: '0.84em' }}>{record.docType}</span>
            ) : (
              ''
            )}
          </>
        );
      },
      sorter: (a: any, b: any) => {
        const valA = a.docType || '';
        const valB = b.docType || '';
        return valA.localeCompare(valB);
      },
    },
    {
      title: 'File Name',
      dataIndex: '',
      width: 390,
      key: 'fileName',
      render: (record: any) => {
        return (
          <>
            {record && record.fileName ? (
              <span style={{ fontSize: '0.84em' }}>{record.fileName}</span>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Size',
      dataIndex: '',
      width: 80,
      key: 'size',
      render: (record: any) => {
        return (
          <>
            {record && record.size ? (
              <span style={{ fontSize: '0.84em' }}>{record.size}</span>
            ) : (
              '-'
            )}
          </>
        );
      },
      sorter: (a: any, b: any) => {
        const valA = a.size || '';
        const valB = b.size || '';
        return valA.localeCompare(valB);
      },
    },
    {
      title: 'Run Date',
      width: 160,
      dataIndex: '',
      key: 'runDate',
      render: (record: any) => {
        return (
          <>
            {record && record.runDate ? (
              <span style={{ fontSize: '0.84em' }}>
                {format(new Date(record.runDate), 'MMMM d, yyyy')}{' '}
              </span>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Start',
      width: 70,
      dataIndex: '',
      key: 'start',
      render: (record: any) => {
        return (
          <>
            {record && record.start ? (
              <span style={{ fontSize: '0.84em' }}>{record.start}</span>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Stop',
      width: 70,
      dataIndex: '',
      key: 'stop',
      render: (record: any) => {
        return (
          <>
            {record && record.stop ? (
              <span style={{ fontSize: '0.84em' }}>{record.stop}</span>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Step',
      width: 70,
      dataIndex: '',
      key: 'step',
      render: (record: any) => {
        return (
          <>
            {record && record.step ? (
              <span style={{ fontSize: '0.84em' }}>{record.step}</span>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'TVDM',
      width: 90,
      dataIndex: '',
      key: 'tvdmd',
      render: (record: any) => {
        return (
          <>
            {record && record.tvdmd ? (
              <span style={{ fontSize: '0.84em' }}>{record.tvdmd}</span>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      title: 'Service Company',
      width: 340,
      dataIndex: '',
      key: 'serviceCompany',
      sorter: (a: any, b: any) => {
        const valA = a.serviceCompany || ''; // Default to empty string if null/undefined
        const valB = b.serviceCompany || ''; // Default to empty string if null/undefined
        return valA.localeCompare(valB);
      },
      render: (record: any) => (
        <>
          {record && record.serviceCompany ? (
            <span style={{ fontSize: '0.84em' }}>{record.serviceCompany}</span>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Legacy Co',
      width: 110,
      dataIndex: '',
      key: 'legacyCo',
      render: (record: any) => (
        <>
          {record && record.legacyCo ? (
            <span style={{ fontSize: '0.84em' }}>{record.legacyCo}</span>
          ) : (
            '-'
          )}
        </>
      ),
    },
    { title: 'Log Type', dataIndex: 'logType', width: 190, key: 'logType' },

    {
      title: '',
      render: (file: FileInfo) => (
        <>
          <DownloadOutlined
            style={{ fontSize: '1.2em', cursor: 'pointer' }}
            title={'Download ' + file.fileName + ' for ' + file.uwi}
            onClick={() => {
              handleDownloadFile(file.fileName);
            }}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    // Ensure searchResults exist before proceeding
    console.log('search length: ', searchResults.length);
    if (searchResults.length === 0) return;

    // Function to fetch files for each well
    const fetchFilesForWell = async (wellUwi: string) => {
      try {
        const response = await getAssociatedWellLogFiles(wellUwi, 'ALL');
        //const response = await fetchFilesByUwi(wellUwi); // Replace with your API call to fetch files by UWI
        return response.data || []; // Assuming the API returns the files in the `data` field
      } catch (error) {
        console.error(`Error fetching files for UWI: ${wellUwi}`, error);
        return [];
      }
    };

    // Function to fetch files for all wells
    const loadFilesForWells = async () => {
      // Iterate over searchResults and fetch associated files
      const formattedDataPromises = searchResults.map(async (result) => {
        setLoadingData(true);
        //const files = await fetchFilesForWell(result.uwi); // Fetch files for each well

        return {
          key: result.uwi, // Assuming each result has a uwi
          id: result.id,
          uwi: result.uwi,
          licence: result.licence,
          confStatus: result.confStatus,
          confRelease: result.confRelease,
          wellName: result.wellName,
          finalDrillDate: result.finalDrillDate,
          finalTd: result.finalTd,
          //fileCount: files.length || '0', // Set the file count based on fetched files
          //files: files, // Store the fetched files
          fileCount: 0,
          files: [],
          loadingFiles: false, // Set loading to false since files are already fetched
        };
      });

      // Wait for all wells to have their files fetched
      const formattedData = await Promise.all(formattedDataPromises);

      // Update state after all data is fetched
      setWellsData(formattedData);
      setLoadingData(false);
    };

    // Trigger the file fetching process
    loadFilesForWells();
  }, [searchResults, selectedFileTypeId, selectedFileTypeContainerName, requestDetails]);

  //============EVENT HANDLE

  // handling file download
  const handleDownloadFile = async (fileName: string) => {
    try {
      const res = await getContainerNameByFileTypeId(selectedFileTypeId);
      const link = await downloadFile(res.data.containerName, fileName);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up the object URL after the download
      window.URL.revokeObjectURL(link.href);

      //window.open(downloadFile);
      notifyUser('success', 'Downloading file...');
    } catch (error) {
      notifyUser('warning', 'Unable to download file.');
    }

    return true; // Indicates asynchronous response
  };

  const handleWellSelection = async (well: WellInfo, selected: boolean) => {
    const updatedSelectedFiles = new Set(selectedFiles);

    // Ensure well.files is an array and iterate over each file object.
    // IMPORTANT: Exclude Confidential and Confidential Below wells/files
    // (Only insert Non Confidential files into the cart, (customerRequestFile table)).

    if (well.confStatus === 'Non Confidential' && selected && Array.isArray(well.files)) {
      well.files.forEach(async (file: FileInfo) => {
        updatedSelectedFiles.add(file.id); // Add file ID to selected files

        const fileSelection = [
          {
            customerRequestId: requestDetails.id,
            fileName: file.fileName,
            associatedUWI: well.uwi,
            associatedLicenceNumber: well.licence,
            confidentialityStatus: well.confStatus,
            containerName: selectedFileTypeContainerName,
            fileTypeId: selectedFileTypeId,
            createdBy: 'andrew.heaven@aer.ca',
          },
        ];

        const res = await addCustomerRequestFiles(fileSelection);

        //fetchRequestFileCount(); //refresh the cart file count.
        setNotificationMsg(() => res.data.message);
      });

      //console.log('Adding well file length: ', cartCount + well.files.length);
      updateCartCount(cartCount + well.files.length);
      notifyUser('success', 'Files added to the cart.');
    } else if (Array.isArray(well.files)) {
      well.files.forEach(async (file: FileInfo) => {
        updatedSelectedFiles.delete(file.id); // Remove file ID from selected files

        const fileSelection = [{
          //delete files based on these (where) conditions
          customerRequestId: requestDetails.id,
          associatedUWI: well.uwi,
          fileName: file.fileName,
          fileTypeId: selectedFileTypeId,
        }];

        const res = await removeCustomerRequestFiles(fileSelection);
        //fetchRequestFileCount(); //refresh the cart file count.)
        setNotificationMsg(() => res.data.message);
      });

      //console.log('Subtracting well file length: ', cartCount - well.files.length);
      updateCartCount(cartCount - well.files.length);
      notifyUser('success', 'Files removed from the cart.');
    }

    //fetchRequestFileCount(); //refresh the cart file count.)
    setSelectedFiles(updatedSelectedFiles);
  };

  const handleFileSelection = async (file: FileInfo, selected: boolean, well: WellInfo) => {
    const updatedSelectedFiles = new Set(selectedFiles);

    if (well.confStatus === 'Non Confidential' && selected) {
      updatedSelectedFiles.add(file.id);

      const fileSelection = [
        {
          customerRequestId: requestDetails.id,
          fileName: file.fileName,
          associatedUWI: file.uwi,
          associatedLicenceNumber: well.licence,
          confidentialityStatus: well.confStatus,
          containerName: selectedFileTypeContainerName,
          fileTypeId: selectedFileTypeId,
          createdBy: 'andrew.heaven@aer.ca',
        },
      ];

      const res = await addCustomerRequestFiles(fileSelection);
      updateCartCount(cartCount + 1);
      //fetchRequestFileCount(); //refresh the cart file count.
      setNotificationMsg(() => res.data.message);

      notifyUser('success', 'File ' + file.fileName + ' was added to your cart.');
    } else {
      updatedSelectedFiles.delete(file.id);

      const fileSelection = [{
        //delete files based on these (where) conditions
        customerRequestId: requestDetails.id,
        fileName: file.fileName,
        associatedUWI: file.uwi,
        fileTypeId: selectedFileTypeId,
      }];

      const res = await removeCustomerRequestFiles(fileSelection);

      updateCartCount(cartCount - 1);
      setNotificationMsg(() => res.data.message);
      notifyUser('success', 'The file ' + file.fileName + ' was removed from your cart.');
    }

    setSelectedFiles(updatedSelectedFiles);
    console.log('updated selected files: ', updatedSelectedFiles.entries);
  };

  const areAllFilesSelectedForWell = (uwi: string): boolean => {
    const well = wellsData.find((well) => well.uwi === uwi);
    if (!well || !well.files) return false;

    // Iterate over the files and check if each file's `id` is in the selectedFiles set
    return well.files.every((file: FileInfo) => selectedFiles.has(file.id));
  };

  return (
    <>
      {/* {searchResults.length === 0 ? 'nothing' : 'loading.'} */}

      <Row gutter={0}>
        <Col span={24}>
          <p style={{ fontSize: '0.85em' }}>
            Search Result Well Count: <strong>{searchResults.length}</strong>
          </p>
        </Col>
      </Row>
      <Spin spinning={loadingData} size="large" tip="Loading Wells and Associated Files...">
        <Table
          size="small"
          columns={wellColumns}
          dataSource={Array.isArray(wellsData) ? wellsData : []}
          rowKey="uwi"
          bordered
          rowSelection={{
            selectedRowKeys: wellsData
              .filter((well) => Array.isArray(well.files)) // Only check wells that have files array
              .map((well) => well.uwi)
              .filter((uwi) => areAllFilesSelectedForWell(uwi)),
            onSelect: (well: WellInfo, selected: boolean) => handleWellSelection(well, selected),
            onSelectAll: (selected: boolean, selectedWells: WellInfo[]) => {
              selectedWells.forEach((well) => handleWellSelection(well, selected));
            },
          }}
          expandable={{
            expandedRowKeys, // Control which rows are expanded
            onExpand: (expanded, record) => {
              if (expanded) {
                // Set the expanded row key to the current record, collapsing others
                setExpandedRowKeys([record && record.uwi]);
              } else {
                // Collapse the row
                setExpandedRowKeys([]);
              }
            },
            expandedRowRender: (record: WellInfo) => (
              <Table
                size="small"
                rowSelection={{
                  selectedRowKeys: Array.isArray(record.files) // Ensure files is an array
                    ? record.files
                        .map((file: FileInfo) => file.id)
                        .filter((fileId) => selectedFiles.has(fileId))
                    : [], // Default to an empty array if no files
                  onSelect: (file: FileInfo, selected: boolean) =>
                    handleFileSelection(file, selected, record),
                  onSelectAll: (selected: boolean, selectedRows: FileInfo[]) => {
                    selectedRows.forEach((file) => handleFileSelection(file, selected, record));
                  },
                }}
                columns={fileColumns}
                dataSource={Array.isArray(record.files) ? record.files : []} // Handle cases with no files
                rowKey="id"
                pagination={false}
              />
            ),
          }}
        />
      </Spin>
    </>
  );
};

export default WellSearchResults;
