import React from 'react';
import AppLayout from '../../components/Layout/AppLayout';
import { AppFooter } from '../../components/Layout/AppFooter';
import Breadcrumbs from '../../components/Layout/Breadcrumbs';

const Index: React.FC = () => {
  return (
    <>
      <AppLayout />
      <div className='content'>
        <Breadcrumbs
          items={[
            { href: '/maintenance', title: 'File Maintenance' },
            { title: 'Dashboard' },
          ]}
        />
        <p></p>
        <p className='Page-heading'>File Maintenance</p>
      </div>
      <AppFooter />
    </>
  );
};

export default Index;