export const config = {
  REACT_APP_PCA_TENANT_ID: process.env.REACT_APP_PCA_TENANT_ID,
  REACT_APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  REACT_APP_SERVICE_URI: process.env.REACT_APP_SERVICE_URI,
  //REACT_APP_APP_QUERY_URL: process.env.REACT_APP_APP_QUERY_URL,
  REACT_APP_IAR_URL: process.env.REACT_APP_IAR_URL,

  //dissemination configs
  API_URL: process.env.REACT_APP_API_URL,

  DISSEMINATOR_MS_API_URL: process.env.REACT_DISSEMINATOR_MS_API_URL,

  //environment, app version
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  //REACT_APP_VER: process.env.REACT_APP_VER,
  REACT_STORAGE_BASEURL: process.env.REACT_STORAGE_BASEURL,
};
