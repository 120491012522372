import React from 'react';
import { Avatar, Button } from 'antd';
import logo from '../../assets/logo.png';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { UserInfo } from '../UserInfo';

export const LandingPageHeader: React.FC = () => {

    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();

    const handleSignOut = (event: unknown) => {
        console.log(event);
        console.log('Logging out...');
        localStorage.removeItem('accessToken');
        instance.logoutRedirect();
    };

    const handleLogin = () => {
        instance.loginRedirect({
            scopes: ['profile', 'openid', 'email', 'User.Read'],
        });
    };
    return (
        <>
            <div>
                <div className='Landing-page-header-logo'><img
                    src={logo}
                    alt="AER Logo"
                    style={{ height: '45px', marginLeft: '-8px', marginRight: '15px', marginTop: '10px' }}
                /></div>
                <div className='Landing-page-header-appname'>Dissemination Tool</div>
                <div className='Landing-page-header-appslogan'>
                    Process Dissemination, Subscriptions and Compilations
                </div>
                <div className='Landing-page-header-userinfo'>
                    {isAuthenticated ? (
                        <div className="user-info">

                            <UserInfo />

                            <Button type="primary" icon={<LogoutOutlined />} onClick={handleSignOut} />
                        </div>
                    ) : (
                        <Button type="primary" onClick={handleLogin}>
                            Login
                        </Button>
                    )}

                </div>
            </div>
        </>
    );
};
