import React, { useEffect, useRef, useState } from 'react';
import { Button, Table, Tabs, Tooltip } from 'antd';
import type { TabsProps } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import convertDbUwi from '../../../utils/convertDbUwi';
import {
  getContainerNameByFileTypeId,
  getFilesByRequestId,
  removeCustomerRequestFiles,
} from '../../../services/api';
import notifyUser from '../../../utils/notifyUser';
import { downloadFile } from '../../../services/blob';

interface RequestDetails {
  id: string;
  requestNumber: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  companyName: string;
  requestTypeName: string;
  requestStatusName: string;
  requestFileTypes: [
    {
      requestFileTypeId: string;
      requestFileTypeName: string;
    }
  ];
}

interface RequestDetailsProps {
  requestDetails: RequestDetails;
  cartCount: number;
  updateCartCount: (newCount: number) => void;
  fileTypes: FileType[];
  cartData: RequestFiles[];
  setCartData: (updatedData: RequestFiles[]) => void; // Add this for updating cart data
}

interface RequestFiles {
  id: string;
  customerRequestId: string;
  fileTypeId: string;
  fileName: string;
  filePath: string;
  fileSize: string;
  associatedUWI: string;
  associatedLicence: string;
  confidentialityStatus: string;
  containerName: string;
}

interface FileType {
  id: string;
  name: string;
  containerName: string;
}

const CartTableWithTabs: React.FC<RequestDetailsProps> = ({
  requestDetails,
  fileTypes,
  cartData,
  updateCartCount,
  cartCount,
  setCartData,
}) => {
  //Use States
  const [activeFileTypeId, setActiveFileTypeId] = useState<string>(fileTypes[0].id);

  // handling file download
  const handleDownloadFile = async (fileName: string) => {
    try {
      const res = await getContainerNameByFileTypeId(activeFileTypeId);
      const link = await downloadFile(res.data.containerName, fileName);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up the object URL after the download
      window.URL.revokeObjectURL(link.href);

      //window.open(downloadFile);
      notifyUser('success', 'Downloading file...');
    } catch (error) {
      notifyUser('warning', 'Unable to download file.');
    }

    return true; // Indicates asynchronous response
  };

  const fetchRequestFiles = async () => {
    const res = await getFilesByRequestId(requestDetails?.id);
    console.log('res: ', res);
    setCartData(res);
  };

  // handling file deletion
  const handleDeleteFile = async (
    uwi: string,
    requestId: string,
    fileTypeId: string,
    fileName: string
  ) => {
    const fileSelection = [{
      //delete files based on these (where) conditions
      customerRequestId: requestId,
      associatedUWI: uwi,
      fileName: fileName,
      fileTypeId: fileTypeId,
    }];

    try {
        const res = await removeCustomerRequestFiles(fileSelection);

      if (res.status === 200) {
        // Filter out the deleted file from cartData
        const updatedData = cartData.filter(
          (file) =>
            !(
              file.associatedUWI === uwi &&
              file.customerRequestId === requestId &&
              file.fileTypeId === fileTypeId &&
              file.fileName === fileName
            )
        );

        // Update the cartData state
        setCartData(updatedData);

        // Update cart count
        updateCartCount(cartCount - 1);
        notifyUser('success', 'File removed.');
      } else {
        notifyUser('warning', 'File not removed.');
      }
    } catch (error) {
      notifyUser(
        'error',
        'Unable to delete selected file due to the following error: ' + error.message
      );
      console.log('', error.message);
    }

    return true; // Indicates asynchronous response
  };

  const columns = [
    {
      title: 'File Name',
      dataIndex: '',
      key: 'fileName',
      render: (record: any) => (
        <>
          <span style={{ fontSize: '0.80em' }}>{record && record.fileName}</span>
        </>
      ),
      sorter: (a: any, b: any) => {
        const valA = a.fileName || '';
        const valB = b.fileName || '';
        return valA.localeCompare(valB);
      },
    },
    {
      title: 'Associated UWI',
      dataIndex: '',

      width: '140px',
      key: 'associatedUWI',
      render: (record: any) => (
        <>
          <span style={{ fontSize: '0.80em' }}>{record && convertDbUwi(record.associatedUWI)}</span>
        </>
      ),
      sorter: (a: any, b: any) => {
        const valA = a.associatedUWI || '';
        const valB = b.associatedUWI || '';
        return valA.localeCompare(valB);
      },
    },
    {
      title: 'Licence',
      dataIndex: '',
      width: '70px',
      key: 'associatedLicenceNumber',
      render: (record: any) => (
        <>
          <span style={{ fontSize: '0.80em' }}>{record && record.associatedLicenceNumber}</span>
        </>
      ),
      sorter: (a: any, b: any) => {
        const valA = a.associatedLicence || '';
        const valB = b.associatedLicence || '';
        return valA.localeCompare(valB);
      },
    },
    {
      title: 'Conf Status',
      dataIndex: '',
      width: '120px',
      key: 'confidentialityStatus',
      render: (record: any) => (
        <>
          <span style={{ fontSize: '0.80em' }}>{record && record.confidentialityStatus}</span>
        </>
      ),
      sorter: (a: any, b: any) => {
        const valA = a.confidentialityStatus || '';
        const valB = b.confidentialityStatus || '';
        return valA.localeCompare(valB);
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      width: '70px',
      key: 'fileName',
      render: (record: any) => (
        <>
          <Tooltip title="Download File">
            <DownloadOutlined
              disabled={
                record?.confidentialityStatus === 'Confidential' &&
                record?.confidentialityStatus === 'Confidential Below'
              }
              style={{ color: 'green', fontSize: '1.3em' }}
              onClick={() => {
                handleDownloadFile(record?.fileName);
              }}
            ></DownloadOutlined>
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip title="Remove File from Cart">
            <Button
              size="small"
              icon={<DeleteOutlined />}
              disabled={requestDetails?.requestStatusName === 'Completed' ? true : false}
              style={{ color: 'red', margin: '0px auto' }}
              onClick={() => {
                handleDeleteFile(
                  record && record.associatedUWI,
                  requestDetails.id,
                  record.fileTypeId,
                  record.fileName
                );
              }}
            ></Button>
          </Tooltip>
        </>
      ),
    },
  ];

  // Filter data based on the selected fileTypeId
  const filteredData = (fileTypeId: string) =>
    cartData.filter((file: RequestFiles) => file && file.fileTypeId === fileTypeId);

  // Define TabsProps for file type tabs
  const items: TabsProps['items'] = fileTypes.map((fileType) => ({
    key: fileType.id,
    label: fileType.name,
    children: (
      <>
        <Table size="small" dataSource={filteredData(fileType.id)} columns={columns} rowKey="id" />
      </>
    ),
  }));

  //const hasFetchedFiles = useRef(false);

  useEffect(() => {
    //console.log('Triggered useEffect, requestDetails.id:', requestDetails?.id);
    if (requestDetails?.id) {
      fetchRequestFiles();
      //hasFetchedFiles.current = true; // Mark as fetched
    }
  }, []);

  return (
    <>
      {/* Tabs for selecting file type, positioned on the left */}
      <Tabs
        size="small"
        activeKey={activeFileTypeId}
        onChange={setActiveFileTypeId} // Update the active tab (selected fileTypeId)
        tabPosition="left" // Position tabs on the left
        items={items} // Each tab has its own table
      />
    </>
  );
};

export default CartTableWithTabs;
