import React from 'react';
import AppLayout from '../components/Layout/AppLayout';

const NotFoundPage: React.FC = () => {
  //export default function NotFoundPage() {
  return (
    <>
      <AppLayout />
      <div> Page not found.</div>
    </>
  );
};

export default NotFoundPage;
