import React, { useState } from 'react';
import { message } from 'antd';

type MessageType = 'success' | 'error' | 'info' | 'warning' | 'loading';

const notifyUser = (type: MessageType, content: string) => {
  message[type]({
    content,
    duration: Number(content === 'error' ? 3 : content === 'warning' ? 6 : 6),
    style: { marginTop: '6.2vh' },
  });
};

export default notifyUser;
