/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Form, Input, Select, Checkbox, Button, Modal, Row, Col, DatePicker } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Dayjs } from 'dayjs';
import {
  getInitialCustomerRequests,
  createCustomerRequest,
  checkCustomerRequestExists,
  createCustomerRequestFileType,
  getRequestTypes,
  getIdRequestStatusByName,
  getFileTypes,
  getPaymentTypes,
  getIdBillingStatusByName,
  getIdPaymentTypeByName,
} from '../../../../services/api';
import notifyUser from '../../../../utils/notifyUser';
import { useUser } from '../../../../contexts/UserContext';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface RequestType {
  id: string;
  name: string;
}

interface RequestStatus {
  id: string;
  name: string;
}

interface FileType {
  id: string;
  name: string;
}

interface BillingStatus {
  id: string;
  name: string;
}

interface PaymentType {
  id: string;
  name: string;
}

const RequestForm: React.FC = () => {
  //user info
  const { account, roles } = useUser();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestTypes, setRequestTypes] = useState<RequestType[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus[]>([]);
  const [billingStatus, setBillingStatus] = useState<BillingStatus[]>([]);
  const [fileTypes, setFileTypes] = useState<FileType[]>([]);
  const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([]);
  const [selectedRequestType, setSelectedRequestType] = useState<string | undefined>();
  const [selectedPaymentType, setSelectedPaymentType] = useState<string | undefined>();
  const [inputValues, setInputValues] = useState({
    requestNumber: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    companyName: '',
  });
  const [selectedFileTypes, setSelectedFileTypes] = useState<string[]>([]);
  const [disseminationFrequency, setDisseminationFrequency] = useState<string | undefined>();
  const [subscriptionPeriod, setSubscriptionPeriod] = useState<[Dayjs, Dayjs] | undefined>();

  // setup navigation to the file selection page/route
  const navigate = useNavigate(); // Initialize the navigate function

  // Fetch available request types, request status (New), and file types from the database
  useEffect(() => {
    const fetchData = async () => {
      const requestTypesResponse = await getRequestTypes();
      const initialRequestStatus = await getIdRequestStatusByName('new');
      const initialBillingStatus = await getIdBillingStatusByName('Not Set');
      const fileTypesResponse = await getFileTypes();

      const paymentTypesResponse = await getPaymentTypes();
      setRequestTypes(requestTypesResponse.data);
      setRequestStatus(initialRequestStatus.data.id);
      setBillingStatus(initialBillingStatus.data.id);
      setFileTypes(fileTypesResponse.data);
      setPaymentTypes(paymentTypesResponse.data);
    };

    //console.log('selected payment type:', selectedPaymentType);
    //console.log('billing status:', billingStatus);

    fetchData();
  }, [selectedPaymentType]);

  //handle Cancel - reset form values.
  const handleCancel = async () => {
    setIsModalVisible(false);
    setInputValues({
      requestNumber: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      companyName: '',
    });
    setSelectedFileTypes([]);
    setSelectedRequestType(undefined);
    setSelectedPaymentType(undefined);
    setDisseminationFrequency(undefined);
    setSubscriptionPeriod(undefined);
  };

  // Handle form submission
  const handleSubmit = async () => {
    setLoading(true);
    const requestCheck = (await checkCustomerRequestExists(inputValues.requestNumber)).data;

    if (
      !selectedRequestType ||
      !inputValues.requestNumber ||
      !inputValues.contactName ||
      !inputValues.contactEmail ||
      selectedFileTypes.length === 0 ||
      !selectedPaymentType
    ) {
      return notifyUser('warning', 'Please fill out the required fields.');
    } else if (requestCheck.count === 1) {
      //Check if the request Number already exists. If so, return message from backend.
      setLoading(false);
      return notifyUser('warning', requestCheck.message);
    }

    //Set the billing status based on the payment type selection.
    const paymentNotRequiredId = (await getIdPaymentTypeByName('Payment Not Required')).data.id;
    const billingNotSetId = (await getIdBillingStatusByName('Not Set')).data.id;
    const billingNotRequiredId = (await getIdBillingStatusByName('Billing Not Required')).data.id;
    const billingStatusId =
      paymentNotRequiredId === selectedPaymentType ? billingNotRequiredId : billingNotSetId;

    try {
      setLoading(true);

      const response = await createCustomerRequest({
        ...inputValues,
        requestTypeId: selectedRequestType,
        requestStatusId: requestStatus,
        billingStatusId: billingStatusId,
        paymentTypeId: selectedPaymentType,
      });
      //console.log('customer request submitted');

      const requestUrl = '/dissemination/irm/' + inputValues.requestNumber;

      // Insert checkbox values into the second table (e.g., customerRequestFileType)
      const customerRequestId = response.data.id[0][0].Id;

      await Promise.all(
        Array.isArray(selectedFileTypes) &&
          selectedFileTypes.map((fileTypeId) => {
            createCustomerRequestFileType({
              customerRequestId: customerRequestId,
              fileTypeId: fileTypeId,
              createdBy: account.username, //'andrew.heaven@aer.ca',
            });
          })
      );

      // Notify user after action taken.
      notifyUser('success', 'Customer Request Created Successfully.');

      // Close the modal and reset the form
      setIsModalVisible(false);
      setLoading(false);
      getInitialCustomerRequests();

      setTimeout(() => {
        // empty the fields and reset inputs after 3secs
        setInputValues({
          requestNumber: '',
          contactName: '',
          contactEmail: '',
          contactPhone: '',
          companyName: '',
        });
        setSelectedFileTypes([]);
        setSelectedRequestType(undefined);
        setSelectedPaymentType(undefined);
        setDisseminationFrequency(undefined);
        setSubscriptionPeriod(undefined);

        //navigate to the file selection route.
        navigate(requestUrl);
      }, 3000);
    } catch (error) {
      // Notify user after action taken.
      notifyUser(
        'error',
        'Unable to create the IRM Request due to a server error: ' + error.message
      );
      console.error('Error submitting form:', error);
    }
  };

  const isDisseminationAndSubscriptionDisabled = () => {
    //const selectedType = requestTypes?.find(type => type.id === selectedRequestType);
    const selectedType = Array.isArray(requestTypes)
      ? requestTypes.find((type) => type.id === selectedRequestType)
      : undefined;
    return selectedType?.name === 'Regular' || selectedType?.name === 'Compilation';
  };

  const handleDateChange = (dates: [Dayjs, Dayjs] | null) => {
    if (dates) {
      setSubscriptionPeriod(dates);
    } else {
      setSubscriptionPeriod(undefined);
    }
  };

  return (
    <>
      <Button
        type="primary"
        size="middle"
        icon={<PlusCircleOutlined />}
        onClick={() => setIsModalVisible(true)}
      >
        Create New Request
      </Button>
      <Modal
        title="Create IRM Request"
        open={isModalVisible}
        onCancel={() => handleCancel()}
        footer={[
          <Button key="cancel" onClick={() => handleCancel()}>
            Cancel
          </Button>,
          <Button loading={loading} key="submit" type="primary" onClick={handleSubmit}>
            Start the Request
          </Button>,
        ]}
        width={1020}
        // height={400}
      >
        <Divider type="horizontal" className="dividerColor" style={{ marginTop: '0px' }}></Divider>
        <Form layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 24 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={11}>
              <p>
                <strong>Customer Information</strong>
              </p>
              <Form.Item label="Request Type" required>
                <Select
                  placeholder="Select a request type"
                  value={selectedRequestType}
                  onChange={(value) => setSelectedRequestType(value)}
                >
                  {Array.isArray(requestTypes) &&
                    requestTypes.map((type) => (
                      <Option key={type.id} value={type.id}>
                        {type.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item label="Request Number" required>
                <Input
                  id="requestNumber"
                  addonBefore="IRM"
                  value={inputValues.requestNumber}
                  onChange={(e) =>
                    setInputValues({ ...inputValues, requestNumber: e.target.value })
                  }
                />
              </Form.Item>
              <Form.Item label="Contact Name" required>
                <Input
                  value={inputValues.contactName}
                  onChange={(e) => setInputValues({ ...inputValues, contactName: e.target.value })}
                />
              </Form.Item>
              <Form.Item label="Contact Email" required>
                <Input
                  value={inputValues.contactEmail}
                  onChange={(e) => setInputValues({ ...inputValues, contactEmail: e.target.value })}
                />
              </Form.Item>
              <Form.Item label="Contact Phone">
                <Input
                  value={inputValues.contactPhone}
                  onChange={(e) => setInputValues({ ...inputValues, contactPhone: e.target.value })}
                />
              </Form.Item>
              <Form.Item label="Company Name">
                <Input
                  value={inputValues.companyName}
                  onChange={(e) => setInputValues({ ...inputValues, companyName: e.target.value })}
                />
              </Form.Item>
              <p>
                <strong>Payment</strong>
              </p>
              <Form.Item label="Payment Type" required>
                <Select
                  placeholder="Select a payment type"
                  value={selectedPaymentType}
                  onChange={(value) => setSelectedPaymentType(value)}
                >
                  {Array.isArray(paymentTypes) &&
                    paymentTypes.map((type) => (
                      <Option key={type.id} value={type.id}>
                        {type.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Divider type="vertical" style={{ height: 'auto', alignSelf: 'stretch' }}></Divider>
            <Col span={12}>
              <p>
                <span style={{ color: 'red' }}>*</span>
                <strong> Products (File Types)</strong>
              </p>
              <Form.Item label="" required>
                <Row gutter={[16, 16]}>
                  {Array.isArray(fileTypes) &&
                    fileTypes.map((fileType) => (
                      <Col key={fileType.id} span={8}>
                        <Checkbox
                          checked={selectedFileTypes.includes(fileType.id)}
                          onChange={(e) => {
                            const selected = [...selectedFileTypes];
                            if (e.target.checked) {
                              selected.push(fileType.id);
                            } else {
                              const index = selected.indexOf(fileType.id);
                              if (index > -1) {
                                selected.splice(index, 1);
                              }
                            }
                            setSelectedFileTypes(selected);
                          }}
                        >
                          {fileType.name}
                        </Checkbox>
                      </Col>
                    ))}
                </Row>
              </Form.Item>

              <p>
                <strong>Subscription Period</strong>
              </p>
              <Form.Item label="">
                <RangePicker
                  defaultValue={subscriptionPeriod}
                  onChange={handleDateChange}
                  allowEmpty
                  disabled={isDisseminationAndSubscriptionDisabled()}
                />
              </Form.Item>
              <p>
                <strong>Dissemination Frequency</strong>
              </p>
              <Form.Item label="">
                <Select
                  placeholder="Select frequency"
                  value={disseminationFrequency}
                  onChange={(value) => setDisseminationFrequency(value)}
                  allowClear
                  disabled={isDisseminationAndSubscriptionDisabled()}
                >
                  <Option value="weekly">Weekly</Option>
                  <Option value="monthly">Monthly</Option>
                  <Option value="yearly">Yearly</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Divider type="horizontal" className="dividerColor, dividerBottom"></Divider>
      </Modal>
    </>
  );
};

export default RequestForm;
