import NotFound from '../pages/NotFoundPage';
import LandingPage from '../pages/LandingPage';
import ProductSearch from '../pages/productSearch';
import DisseminationHome from '../pages/dissemination/Index';
import MaintenanceHome from '../pages/maintenance/Index';
import AdministrationHome from '../pages/administration/Index';
import ManageIRMRequest from '../pages/dissemination/irm/ManageRequestPage';
import ManageSubscription from '../pages/dissemination/ManageSubscription';
import CompleteERFRequest from '../pages/dissemination/irm/ManageRequestPage';
 
//import WellLogApp from '../../src/viewer/welllog/src/App';
 
import ShoppingCart from '../pages/ShoppingCart';
//import DisseminateFiles from '../pages/DisseminateFiles';
import Help from '../pages/Help';
 
import WellSelection from '../pages/dissemination/irm/WellSelectionPage';
 
// import ParticipantPage from './pages/ParticipantPage';
// import NewEvent from './components/EventForm';
// import ResourceForm from './components/ResourceForm';
// import ResourcesPage from './pages/ResourcesPage';
// import ViewProceedingPage from './pages/ViewProceedingPage';
 
import { Dissemination } from '../pages/Dissemination';
import { USER_ROLES } from '../utils/auth';
 
const appRoutes = [
  { path: '/', component: LandingPage, exact: true },
 
  /*====MAIN MENU */
  //Product Search - Internal Users
  { path: '/productsearch', 
    component: ProductSearch, 
    exact: true, 
    requiredRoles: [USER_ROLES.READER, USER_ROLES.DISSEMINATOR, USER_ROLES.WELL_LOG_VIEWER, USER_ROLES.LICENSE_TRANSFER_ADMIN, USER_ROLES.TECHNICAL_ADMIN]
  },
  //File Dissemination Menu
  {
    path: '/dissemination',
    component: DisseminationHome,
    exact: true,
    requiredRoles: [USER_ROLES.DISSEMINATOR],
  },
  {
    path: '/dissemination/irm',
    component: ManageIRMRequest,
    requiredRoles: [USER_ROLES.DISSEMINATOR],
  },
  { path: '/dissemination/irm/:requestNumber', component: WellSelection, exact: true,  requiredRoles: [USER_ROLES.DISSEMINATOR] },
  { path: '/dissemination/subscription', component: ManageSubscription, exact: true,  requiredRoles: [USER_ROLES.DISSEMINATOR] },
  { path: '/dissemination/compilation', component: ManageSubscription, exact: true,  requiredRoles: [USER_ROLES.DISSEMINATOR] },
  { path: '/dissemination/erf', component: CompleteERFRequest, exact: true,  requiredRoles: [USER_ROLES.DISSEMINATOR] },
 
  // File Maintenance Menu
  { path: '/maintenance', component: MaintenanceHome, exact: true, requiredRoles: [USER_ROLES.WELL_LOG_VIEWER,USER_ROLES.TECHNICAL_ADMIN] },
  //{ path: '/maintenance/welllog', component: WellLogApp, exact: true },
 
  // Administration Menu
  { path: '/administration', component: AdministrationHome, exact: true, requiredRoles: [USER_ROLES.TECHNICAL_ADMIN] },
 
  /*====MAIN FLOATING ACTION MENU */
  // Shopping Cart, Disseminate Files, Help
  { path: '/cart', component: ShoppingCart, exact: true },
  //{ path: '/disseminatefiles', component: DisseminateFiles, exact: true },
  { path: '/help', component: Help, exact: true },
 
  /*=====WELL LOG VIEWER*/
  //{ path: '/mainwelllogviewer', component: WellLogViewer, exact: true },
 
  // Not Found
  { path: '*', component: NotFound },
 
  /*
  { path: '/dissemination', component: Dissemination, exact: true },
  { path: '/dissemination/proceeding/', component: CreateProceeding, exact: true },
  { path: '/dissemination/proceeding/:proceedingId', component: ViewProceedingPage, exact: true },
  {
    path: '/dissemination/proceeding/:proceedingId/participants',
    component: ParticipantPage,
    exact: true,
  },
  { path: '/dissemination/proceeding/:proceedingId/events', component: EventsPage, exact: true },
  { path: '/dissemination/proceeding/:proceedingId/newEvent', component: NewEvent, exact: true },
  {
    path: '/dissemination/proceeding/:proceedingId/resources',
    component: ResourcesPage,
    exact: true,
  },
  {
    path: '/dissemination/proceeding/:proceedingId/resource/new',
    component: ResourceForm,
    exact: true,
  },
  {
    path: '/dissemination/proceeding/:proceedingId/resource/:resourceId',
    component: ResourceForm,
    exact: true,
  },
  { path: '*', component: NotFound },
  */
];
 
export default appRoutes;