import { AccountInfo } from '@azure/msal-browser';
 
export const USER_ROLES = {
    READER: 'Reader',
    DISSEMINATOR: 'Disseminator',
    WELL_LOG_VIEWER: 'Well Log Viewer',
    LICENSE_TRANSFER_ADMIN: 'License Transfer Admin',
    TECHNICAL_ADMIN: 'Technical Admin'
  // Add other roles as needed
};
 
export const getUserRoles = (account: AccountInfo | null): string[] => {
  if (!account?.idTokenClaims?.roles) {
    return [];
  }
  return account.idTokenClaims.roles as string[];
};
 
export const hasRole = (account: AccountInfo | null, role: string): boolean => {
  const roles = getUserRoles(account);
  return roles.some((r) => r.toLowerCase() === role.toLowerCase());
};
 