import { SyncOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import notifyUser from '../../../../utils/notifyUser';

const FileSynchronizationForm: React.FC = () => {
  const handleSync = () => {
    //alert('File Synchronization Triggered!');
    notifyUser('success', '(Demo) - File Synchronization Triggered!');
  };

  return (
    <>
      <div>
        <Tooltip title="Trigger File Synchronization">
          <Button
            style={{ backgroundColor: 'green', color: 'white' }}
            icon={<SyncOutlined />}
            onClick={handleSync}
          >
            Sync
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

export default FileSynchronizationForm;
