const convertDbUwi = (uwi: string | undefined | null): string => {
    const twp = uwi.slice(0, 3);
    const m = uwi.slice(3, 4);
    const rg = uwi.slice(4, 6);
    const se = uwi.slice(6, 8);
    const lsd = uwi.slice(8, 10);
    const le = uwi.slice(10, 12);
    const es = uwi.slice(12, 14);

    // For the event sequence that are greater than 9, they are included in the file results
    // from the welllogview SQL View. The below condition is to display an extra 0 at the beginning
    // of the sequence if the value is less than 10 (1 in length).
    const esUpdated = es.length === 1 ? `0${es}` : es; // factor in G9 Wells
    return `${le}/${lsd}-${se}-${twp}-${rg}W${m}/${esUpdated}`;
};

export default convertDbUwi;