import React, { useEffect, useState } from 'react';
import { Button, Table, Tabs, Tooltip } from 'antd';
import type { TabsProps } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import convertDbUwi from '../../../utils/convertDbUwi';
import {
  getContainerNameByFileTypeId,
  getFilesByRequestId,
  removeCustomerRequestFiles,
} from '../../../services/api';
import notifyUser from '../../../utils/notifyUser';
import { downloadFile } from '../../../services/blob';
import { useUser } from '../../../contexts/UserContext';

interface RequestDetails {
  id: string;
  requestNumber: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  companyName: string;
  requestTypeName: string;
  requestStatusName: string;
  requestFileTypes: [
    {
      requestFileTypeId: string;
      requestFileTypeName: string;
    }
  ];
}

interface RequestDetailsProps {
  requestDetails: RequestDetails;
  cartCount: number;
  updateCartCount: (newCount: number) => void;
  fileTypes: FileType[];
  cartData: RequestFiles[];
  setCartData: (updatedData: RequestFiles[]) => void; // Add this for updating cart data
}

interface RequestFiles {
  id: string;
  customerRequestId: string;
  fileTypeId: string;
  fileName: string;
  filePath: string;
  fileSize: string;
  associatedUWI: string;
  associatedLicence: string;
  confidentialityStatus: string;
  containerName: string;
}

interface FileType {
  id: string;
  name: string;
  containerName: string;
}

const RequestedProducts: React.FC<RequestDetailsProps> = ({
  requestDetails,
  fileTypes,
  cartData,
  updateCartCount,
  cartCount,
  setCartData,
}) => {
  //Use States
  //const [activeFileTypeId, setActiveFileTypeId] = useState<string>(fileTypes[0].id);
  const [activeFileTypeId, setActiveFileTypeId] = useState<string>('1');

  //user info
  const { account, roles } = useUser();

  const isTechnicalAdmin = roles?.some((role) => role === 'Technical Admin') || false;

  const fetchRequestFiles = async () => {
    try {
      const res = await getFilesByRequestId(requestDetails?.id);
      console.log('res: ', res);
      setCartData(res);
    } catch (error) {
      notifyUser('warning', 'Unable to show requested files: ' + error);
    }
  };

  // handling file download
  const handleDownloadFile = async (fileName: string) => {
    try {
      const res = await getContainerNameByFileTypeId(activeFileTypeId);
      const link = await downloadFile(res.data.containerName, fileName);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up the object URL after the download
      window.URL.revokeObjectURL(link.href);

      //window.open(downloadFile);
      notifyUser('success', 'Downloading file...');
    } catch (error) {
      notifyUser('warning', 'Unable to download file.');
    }

    return true; // Indicates asynchronous response
  };

  const columns = [
    {
      title: 'File Name',
      dataIndex: '',
      width: 500,
      key: 'fileName',
      render: (record: any) => (
        <>
          <span style={{ fontSize: '0.80em' }}>{record?.fileName}</span>
        </>
      ),
      sorter: (a: any, b: any) => {
        const valA = a.fileName || '';
        const valB = b.fileName || '';
        return valA.localeCompare(valB);
      },
    },
    {
      title: 'Associated UWI',
      dataIndex: '',

      width: '190px',
      key: 'associatedUWI',
      render: (record: any) => (
        <>
          <span style={{ fontSize: '0.80em' }}>{convertDbUwi(record?.associatedUWI)}</span>
        </>
      ),
      sorter: (a: any, b: any) => {
        const valA = a.associatedUWI || '';
        const valB = b.associatedUWI || '';
        return valA.localeCompare(valB);
      },
    },
    {
      title: 'Licence',
      dataIndex: '',
      width: '100px',
      key: 'associatedLicenceNumber',
      render: (record: any) => (
        <>
          <span style={{ fontSize: '0.80em' }}>{record?.associatedLicenceNumber}</span>
        </>
      ),
      sorter: (a: any, b: any) => {
        const valA = a.associatedLicence || '';
        const valB = b.associatedLicence || '';
        return valA.localeCompare(valB);
      },
    },
    {
      title: 'Conf Status',
      dataIndex: '',
      width: '150px',
      key: 'confidentialityStatus',
      render: (record: any) => (
        <>
          <span style={{ fontSize: '0.80em' }}>{record?.confidentialityStatus}</span>
        </>
      ),
      sorter: (a: any, b: any) => {
        const valA = a.confidentialityStatus || '';
        const valB = b.confidentialityStatus || '';
        return valA.localeCompare(valB);
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      width: '70px',
      key: 'download',
      render: (record: any) => (
        <>
          <Tooltip title="Download File">
            <DownloadOutlined
              disabled={!isTechnicalAdmin}
              style={{ color: 'green', fontSize: '1.3em' }}
              onClick={() => {
                handleDownloadFile(record && record.fileName);
              }}
            ></DownloadOutlined>
          </Tooltip>
        </>
      ),
    },
  ];

  // Filter data based on the selected fileTypeId
  const filteredData = (fileTypeId: string) =>
    cartData.filter((file: RequestFiles) => file && file.fileTypeId === fileTypeId);

  // Define TabsProps for file type tabs
  const items: TabsProps['items'] = fileTypes.map((fileType) => ({
    key: fileType.id,
    label: fileType.name,
    children: (
      <>
        <Table size="small" dataSource={filteredData(fileType.id)} columns={columns} rowKey="id" />
      </>
    ),
  }));

  useEffect(() => {
    //console.log('requestDetails?.id:', requestDetails?.id); // Debug log
    if (requestDetails?.id) {
      fetchRequestFiles();
    }
  }, [requestDetails?.id]);

  return (
    <>
      {/* Tabs for selecting file type, positioned on the left */}
      <span style={{ fontWeight: '500' }}>Requested Products & Files</span>
      <p></p>
      <Tabs
        size="small"
        activeKey={activeFileTypeId}
        onChange={setActiveFileTypeId} // Update the active tab (selected fileTypeId)
        tabPosition="left" // Position tabs on the left
        items={items} // Each tab has its own table
      />
    </>
  );
};

export default RequestedProducts;
