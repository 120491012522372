import React from 'react';
import AppLayout from '../components/Layout/AppLayout';
import { AppFooter } from '../components/Layout/AppFooter';

const ShoppingCart: React.FC = () => {
  return (
    <>
      <AppLayout />
      <div
        style={{
          marginTop: '35px', padding: '0 140px',
          border: '1px solid #222', textAlign: 'left'
        }}>
        <h1>Cart</h1>

      </div>
      <AppFooter />
    </>
  );
};

export default ShoppingCart;