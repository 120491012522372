import React from 'react';
import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

// Define the types for breadcrumb items
interface BreadcrumbItem {
    href?: string;
    title: string | React.ReactNode;
}

// Define the props interface
interface BreadcrumbsProps {
    items: BreadcrumbItem[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
    // Default home breadcrumb
    const defaultHomeBreadcrumb = {
        href: '/',
        title: <HomeOutlined />,
    };

    // Map the items array to the format Ant Design expects
    const breadcrumbItems = [
        {
            key: 'home',
            title: <a href={defaultHomeBreadcrumb.href}>{defaultHomeBreadcrumb.title}</a>,
        },
        ...items.map((item, index) => ({
            key: `breadcrumb-item-${index}`,
            title: item.href ? <a href={item.href}>{item.title}</a> : item.title,
        })),
    ];

    return (
        <Breadcrumb items={breadcrumbItems} />
    );
};

export default Breadcrumbs;