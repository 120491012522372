/* eslint-disable max-lines */
import {
  Button,
  Col,
  Divider,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd';
import type { PopconfirmProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  addCustomerRequestNotes,
  deleteCustomerRequestNotes,
  editCustomerRequestNotes,
  getNotesByRequestId,
} from '../../../../services/api';
import notifyUser from '../../../../utils/notifyUser';
import { BookOutlined, DeleteOutlined, EditOutlined, ReadOutlined } from '@ant-design/icons';
import { useUser } from '../../../../contexts/UserContext';

interface ModalsState {
  requestNotes: boolean;
}

interface Notes {
  id: string;
  notes: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
}

interface RequestDetails {
  id: string;
  requestNumber: string;
  description: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  companyName: string;
  requestTypeName: string;
  requestStatusName: string;
  billingStatusId: string;
  billingStatusName: string;
  paymentTypeName: string;
  requestFileTypes: [
    {
      requestFileTypeId: string;
      requestFileTypeName: string;
    }
  ];
}

interface RequestDetailsProp {
  requestDetails: RequestDetails;
  //cartCount: number;
  //updateCartCount: (newCount: number) => void;
}

const { TextArea } = Input;

const RequestNotesForm: React.FC<RequestDetailsProp> = ({ requestDetails }) => {
  //user info
  const { account, roles } = useUser();

  //modal handling
  const [modals, setModals] = useState<ModalsState>({
    requestNotes: false,
  });

  const [notes, setNotes] = useState<Notes[]>([]);
  const [newNotes, setNewNotes] = useState<string>('');
  const [selectedNoteId, setSelectedNoteId] = useState<string>('');
  const [isEditMode, setIsEditMode] = useState(false);

  const fetchNotes = async () => {
    const requestId = requestDetails?.id;

    try {
      const res = await getNotesByRequestId(requestId);
      setNotes(res[0]);
      console.log('notes data:', res[0]);
    } catch (error) {
      console.error('Error fetching notes for this request:', error);
      notifyUser('error', 'Error fetching notes for this request:' + error);
    }
  };

  const handleEditMode = (mode: boolean, record?: any) => {
    const { id, notes } = record;
    //alert(id);

    if (mode) {
      setIsEditMode(true);
      setNewNotes(notes);
      setSelectedNoteId(id);
    } else {
      setIsEditMode(false);
      setNewNotes('');
      setSelectedNoteId('');
    }
  };

  const handleDelete = async (noteId: string) => {
    try {
      await deleteCustomerRequestNotes(noteId);
      notifyUser('success', 'Notes deleted successfully.');
      fetchNotes();
    } catch (error) {
      notifyUser('warning', 'Unable to delete notes.');
    }
  };

  //confirmation of deletion.
  const renderDeleteButton = (noteId: string, createdBy: string) => {
    const popconfirmProps: PopconfirmProps = {
      title: 'Are you sure you want to delete this note?',
      okText: 'Yes, Delete',
      cancelText: 'No',
      onConfirm: () => handleDelete(noteId),
    };

    return (
      <>
        <Popconfirm {...popconfirmProps}>
          <Button
            disabled={account?.username.toLowerCase != createdBy?.toLowerCase}
            size="small"
            icon={<DeleteOutlined />}
            danger
          ></Button>
        </Popconfirm>
      </>
    );
  };
  //confirmation of deletion - end.

  const handleChangeNewNotes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewNotes(e.target.value);
  };

  //Add/Edit Notes
  const handleNotes = async () => {
    const requestId = requestDetails?.id;

    if (newNotes.length > 0) {
      //add the new notes for this request.
      try {
        isEditMode
          ? await editCustomerRequestNotes({
              customerRequestId: requestId,
              noteId: selectedNoteId,
              notes: newNotes,
              modifiedBy: account.username,
            })
          : await addCustomerRequestNotes({
              customerRequestId: requestId,
              notes: newNotes,
              createdBy: account.username,
            });

        //setModals({ ...modals, readyForBilling: false });
        fetchNotes();
        setNewNotes('');
        setSelectedNoteId('');
        setIsEditMode(false);
        notifyUser(
          'success',
          isEditMode ? 'Notes updated successfully.' : 'Notes added successfully.'
        );
      } catch (error) {
        //console.log('Error adding the notes for this request:', error);
        notifyUser(
          'warning',
          isEditMode
            ? 'Error updating the notes for this request.'
            : 'Error adding the notes for this request.'
        );
      }
    }
  };

  useEffect(() => {
    if (requestDetails?.id) {
      fetchNotes();
    }
  }, [requestDetails?.id]); // Empty dependency array to run only once on mount

  const columns = [
    {
      title: 'Notes',
      dataIndex: '',
      width: 350,
      key: 'id',
      render: (record: any) => (
        <>
          {record && record.notes ? (
            <span
              style={{
                fontSize: '0.84em',
              }}
            >
              <i>{record.notes}</i>
              <br></br>
              Created By - {record.createdBy}
            </span>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Create On',
      dataIndex: '',
      width: 145,
      key: 'createdOn',
      render: (record: any) => (
        <>
          {record && record.notes ? (
            <span
              style={{
                fontSize: '0.84em',
              }}
            >
              {format(new Date(record.createdOn), 'MMMM d, yyyy hh:mm a')}
            </span>
          ) : (
            '-'
          )}
        </>
      ),
      sorter: (a: any, b: any) => {
        const valA = a.createdOn || ''; // Default to empty string if null/undefined
        const valB = b.createdOn || ''; // Default to empty string if null/undefined
        return valA.localeCompare(valB);
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      render: (record: any) => (
        <>
          <Tooltip title="Edit">
            <Button
              size="small"
              icon={<EditOutlined />}
              disabled={account?.username.toLowerCase != record?.createdBy.toLowerCase}
              style={{ marginRight: 10 }}
              onClick={() => {
                handleEditMode(true, record);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete">{renderDeleteButton(record?.id, record?.createdBy)}</Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <Modal
        title="Request Notes"
        open={modals.requestNotes}
        onCancel={() => {
          setModals({ ...modals, requestNotes: false });
        }}
        width="980px"
        footer={[
          <>
            <Button
              onClick={() => {
                setModals({ ...modals, requestNotes: false });
                setNewNotes('');
              }}
            >
              Close
            </Button>
            <Button
              onClick={() => handleEditMode(false)}
              style={{ marginRight: 5, display: isEditMode ? 'inline-block' : 'none' }}
            >
              Cancel Edit
            </Button>
            <Button
              disabled={newNotes.length == 0}
              type="primary"
              key="save"
              onClick={() => {
                handleNotes();
              }}
            >
              {isEditMode ? 'Save Notes' : 'Add New Notes'}
            </Button>
          </>,
        ]}
      >
        <Divider
          type="horizontal"
          style={{ width: '5px', marginTop: '7px', marginBottom: '10px' }}
        ></Divider>
        <Row gutter={12}>
          <Col span={15}>
            <div style={{ marginRight: 5 }}>
              Billing Notes:<br></br>
              {requestDetails?.description ? requestDetails?.description : ''}
              <br></br>
              <br></br>
              <Table
                rowKey="noteid"
                size="small"
                pagination={{ pageSize: 4 }}
                dataSource={notes}
                columns={columns}
              />
            </div>
          </Col>
          <Divider type="vertical" style={{ height: 'auto', alignSelf: 'stretch' }}></Divider>
          <Col span={8}>
            <div>
              {isEditMode ? 'Edit Notes' : 'Add New Notes'}
              <br></br>
              <TextArea
                rows={4}
                value={newNotes}
                placeholder="maxLength is 4000"
                maxLength={4000}
                style={{ height: 300, width: '100%' }}
                onChange={handleChangeNewNotes}
              ></TextArea>
            </div>
          </Col>
        </Row>
        <Divider
          type="horizontal"
          style={{ width: '5px', marginTop: '7px', marginBottom: '10px' }}
        ></Divider>
      </Modal>
      <Tooltip title="Add/Edit Request Notes">
        <Button
          style={{ margin: '0 0px 0 5px' }}
          size="middle"
          type="primary"
          icon={<EditOutlined />}
          onClick={() => {
            setModals({ ...modals, requestNotes: true });
          }}
        ></Button>
      </Tooltip>
    </>
  );
};

export default RequestNotesForm;
