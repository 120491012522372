import React, { useState } from 'react';
import { FileSearchOutlined, FileProtectOutlined, SettingOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { USER_ROLES, hasRole } from '../../utils/auth';
import { useUser } from '../../contexts/UserContext';
 
type MenuItem = Required<MenuProps>['items'][number];
 
/*
const navigateTo = () => {
  console.log('Hello');
}
  */
 
const items: MenuItem[] = [
  {
    label: <Link to="/productsearch">General Product Search</Link>,
    key: 'general',
    style: { fontSize: '0.86em' },
  },
 
  {
    label: 'File Dissemination', //(<a href="/dissemination" rel="noopener noreferrer">File Dissemination</a>),
    key: 'disseminate',
    style: { fontSize: '0.86em' },
    icon: <FileSearchOutlined />,
    children: [
      { label: <Link to="/dissemination">Dashboard</Link>, key: 'disseminationdashboard:0' },
      { label: <Link to="/dissemination/irm">Manage IRM</Link>, key: 'manageirm:1' },
      { label: 'Manage Subscriptions', key: 'managesubscription:2' },
      { label: 'Complete ERF Request', key: 'completeERFRequest:3' },
    ],
  },
  {
    label: 'File Maintenance',
    key: 'maintenance',
    style: { fontSize: '0.86em' },
    icon: <FileProtectOutlined />,
    children: [
      { label: <Link to="/maintenance">Dashboard</Link>, key: 'managewelllogfiles:0' },
      {
        label: <Link to="/maintenance/welllog">Manage Well Log Files</Link>,
        key: 'managewelllogfiles:1',
      },
      { label: 'Manage Licence Transfer Files', key: 'managelicencetransferfiles:2' },
    ],
  },
  {
    label: 'Administration',
    key: 'administration',
    style: { fontSize: '0.86em' },
    icon: <SettingOutlined />,
    children: [
      { label: <Link to="/administration">Dashboard</Link>, key: 'userprofile:0' },
      { label: 'User Profile', key: 'userprofile:1' },
      { label: 'Manage Public Request', key: 'managepublicrequest:2' },
      { label: 'Manage Reporting', key: 'managereporting:3' },
      { label: 'Add New File Type', key: 'addnewfiletype:4' },
    ],
  },
];
 
const MainMenu: React.FC = () => {
  const {roles} = useUser();
 
  const getFilteredItems = () => {
    const isDisseminator = roles.includes( USER_ROLES.DISSEMINATOR);
    const isWellLogViewer = roles.includes( USER_ROLES.WELL_LOG_VIEWER);
    const isLicenseTransferAdmin = roles.includes( USER_ROLES.LICENSE_TRANSFER_ADMIN);
    const isTechnicalAdmin =  roles.includes( USER_ROLES.TECHNICAL_ADMIN);
    
 
    const filteredItems = items.filter((item) => {
      if (item?.key === 'general') return true; // Always show Product Search
      if (item?.key === 'disseminate' && (isDisseminator || isTechnicalAdmin)) return true;
      if (item?.key === 'maintenance' && (isWellLogViewer || isTechnicalAdmin)) return true;
      if (item?.key === 'administration' && isTechnicalAdmin) return true;
      
      return false;
    });
 
    return filteredItems;
  };
 
  return (
    <Menu
      style={{
        border: '0px solid #222',
        width: '100%',
        fontFamily: 'Segoe UI',
        fontWeight: 'bold',
        fontSize: '14px',
        position: 'absolute',
        marginLeft: '400px',
      }}
      items={getFilteredItems()}
      mode="horizontal"
    />
  );
};
 
export default MainMenu;