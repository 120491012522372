/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { ReactEventHandler, ReactHTMLElement, useEffect, useState } from 'react';
import { useData } from '../../../providers/WellFileDataProvider';
import { Button, Checkbox, Empty, Flex, Table, Tag, Tooltip } from 'antd'; // Assumes you are using Ant Design for tables
import type { WellInfo, FileInfo } from '../../../providers/WellFileDataProvider';
import { format } from 'date-fns';
import convertDbUwi from '../../../utils/convertDbUwi';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import notifyUser from '../../../utils/notifyUser';
import {
  addCustomerRequestFiles,
  getContainerNameByFileTypeId,
  removeCustomerRequestFiles,
  setCustomerRequestStatus,
} from '../../../services/api';
import { downloadFile } from '../../../services/blob';
import { useUser } from '../../../contexts/UserContext';

interface RequestDetails {
  id: string;
  requestNumber: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  companyName: string;
  requestTypeName: string;
  requestStatusName: string;
  billingStatusName: string;
  paymentTypeName: string;
  requestFileTypes: [
    {
      requestFileTypeId: string;
      requestFileTypeName: string;
    }
  ];
}

interface RequestProps {
  requestDetails: RequestDetails;
  selectedFileTypeContainerName: string;
  cartCount: number;
  updateCartCount: (newCount: number) => void;
}

const WellSearchResults: React.FC<RequestProps> = ({
  requestDetails,
  selectedFileTypeContainerName,
  cartCount,
  updateCartCount,
}) => {
  //user info
  const { account, roles } = useUser();

  const combinedData = useData();
  //const [requestDetails, setRequestDetails] = useState<RequestDetails>();

  const [expandedRowKey, setExpandedRowKey] = useState<string | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [loading, setLoading] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);

  const [selectedWells, setSelectedWells] = useState<Set<string>>(new Set());
  const [selectedFiles, setSelectedFiles] = useState<Set<string>>(new Set());

  // Effect to handle changes to selectedFiles or combinedData
  useEffect(() => {
    // Example: Log selected files or perform any side effect based on the selectedFiles or combinedData
    //console.log('Selected Files:', selectedFiles);
    // Add any necessary logic here (e.g., fetching data or updating the UI)

    // If you need to fetch data based on combinedData or selectedFiles, do it here.
    if (combinedData) {
      // fetch data or perform actions based on combinedData
    }
  }, [combinedData, selectedFiles, requestDetails]); // Add any dependencies here

  // ... rest of your component code

  //console.log('combineddata:', combinedData);

  if (!combinedData && fileInfo > 0) {
    return (
      <>
        <div>
          <LoadingOutlined /> Loading data...
        </div>
      </>
    );
  }

  const handleUpdateRequestStatus = async (status: string) => {
    console.log('test:', status);
    console.log('test:', requestDetails?.id);

    try {
      const res = await setCustomerRequestStatus(requestDetails?.id, status);
      console.log('customer request status update - res:', res);
      console.log('END');
    } catch (error) {
      notifyUser(
        'warning',
        `Unable to set the request status to completed when processing the dissemination request. Error: ${error}`
      );
    }
  };

  const handleExpand = (expanded: boolean, record: WellInfo) => {
    // If expanding, set the current row's UWI; if collapsing, clear the expanded row key
    setExpandedRowKey(expanded ? record.uwi : null);
  };

  const handleFileSelection = async (files: FileInfo[], isChecked: boolean) => {
    setSelectedFiles((prevSelectedFiles) => {
      // Clone the existing Set to avoid directly mutating state
      const updatedSelectedFiles = new Set(prevSelectedFiles);

      // Check if the item is already in the Set
      if (isChecked) {
        const fileSelection: any[] = [];
        files?.map((file: any) => {
          updatedSelectedFiles.add(file.FileName); // Add if checkbox is checked
          fileSelection.push({
            customerRequestId: requestDetails.id,
            fileName: file.FileName,
            associatedUWI: file.UWI,
            associatedLicenceNumber: file.Licence,
            confidentialityStatus: file.ConfStatus,
            containerName: selectedFileTypeContainerName,
            fileTypeId: file.fileTypeId,
            createdBy: account.username, //'andrew.heaven@aer.ca',
          });
        });

        addCustomerRequestFiles(fileSelection);

        handleUpdateRequestStatus('Incompleted');
        updateCartCount(cartCount + 1);
      } else {
        const fileSelection: any[] = [];
        files?.map((file: any) => {
          updatedSelectedFiles.delete(file.FileName), // Remove if checkbox is unchecked
            fileSelection.push({
              //delete files based on these (where) conditions
              customerRequestId: requestDetails.id,
              fileName: file.FileName,
              associatedUWI: file.UWI,
              fileTypeId: file.fileTypeId,
            });
        });

        removeCustomerRequestFiles(fileSelection);
        updateCartCount(cartCount - 1);
      }

      return updatedSelectedFiles; // Return the updated Set
    });
  };

  const handleDownloadFile = async (fileTypeId: string, fileName: string) => {
    try {
      const res = await getContainerNameByFileTypeId(fileTypeId);

      // Check if container name exists and proceed
      if (!res.data || !res.data.containerName) {
        return new Error('Container name not found for the given file type.');
      }

      const link = await downloadFile(res.data.containerName, fileName);

      if (link) {
        console.log('link:', link);
        link.click(); // Trigger download
        window.URL.revokeObjectURL(link.href); // Clean up

        notifyUser('success', 'Downloading file...');
      } else {
        // If no link was returned, notify the user
        notifyUser('warning', 'File download failed: link not available.');
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          notifyUser('error', `Error 404: File not found - ${fileName}`);
        } else if (error.response.status === 500) {
          notifyUser('error', 'Error 500: Server error. Please try again later.');
        } else {
          notifyUser('error', `Error ${error.response.status}: ${error.response.statusText}`);
        }
      } else {
        notifyUser('error', `Unable to download file: ${error.message}`);
      }
    }
    return true; // Indicates asynchronous response
  };

  // Columns for the well table
  const wellColumns = [
    {
      title: '',
      dataIndex: '',
      key: 'wellUwi',
      width: 30,
      render: (record: any) => {
        return (
          <>
            <Checkbox
              key={record.uwi}
              disabled={
                record.files < 1 ||
                record.confStatus === 'Confidential' ||
                record.confStatus === 'Confidential Below'
              }
              checked={selectedWells.has(record.uwi)}
            />
          </>
        );
      },
    },
    {
      title: 'UWI',
      dataIndex: '',
      width: 160,
      key: 'uwi',
      sorter: (a: any, b: any) => {
        const valA = a.uwi || ''; // Default to empty string if null/undefined
        const valB = b.uwi || ''; // Default to empty string if null/undefined
        return valA.localeCompare(valB);
      },
      render: (record: any) => {
        return (
          <>
            <span
              style={{
                fontSize: '0.84em',
                color:
                  record.confStatus == 'Confidential' || record.confStatus == 'Confidential Below'
                    ? 'red'
                    : 'black',
              }}
            >
              {record && convertDbUwi(record.uwi)}
            </span>
          </>
        );
      },
    },
    {
      title: 'Historical UWI',
      dataIndex: '',
      width: 160,
      key: 'historicalUwi',
      sorter: (a: any, b: any) => {
        const valA = a.historicalUwi || ''; // Default to empty string if null/undefined
        const valB = b.historicalUwi || ''; // Default to empty string if null/undefined
        return valA.localeCompare(valB);
      },
      render: (record: any) => (
        <>
          {record && record.historicalUwi ? (
            <span
              style={{
                fontSize: '0.84em',
                color:
                  record.confStatus == 'Confidential' || record.confStatus == 'Confidential Below'
                    ? 'red'
                    : 'black',
              }}
            >
              {convertDbUwi(record.historicalUwi)}
            </span>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Licence',
      dataIndex: '',
      width: 90,
      key: 'licence',
      sorter: (a: any, b: any) => {
        const valA = a.licence || ''; // Default to empty string if null/undefined
        const valB = b.licence || ''; // Default to empty string if null/undefined
        return valA.localeCompare(valB);
      },
      render: (record: any) => (
        <>
          {record && record.licence ? (
            <span
              style={{
                fontSize: '0.84em',
                color:
                  record.confStatus == 'Confidential' || record.confStatus == 'Confidential Below'
                    ? 'red'
                    : 'black',
              }}
            >
              {record.licence}
            </span>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Conf Status',
      dataIndex: '',
      width: 160,
      key: 'confStatus',
      sorter: (a: any, b: any) => {
        const valA = a.confStatus || ''; // Default to empty string if null/undefined
        const valB = b.confStatus || ''; // Default to empty string if null/undefined
        return valA.localeCompare(valB);
      },
      render: (record: any) => (
        <>
          <Flex gap="12px 0" wrap>
            <Tag
              bordered={true}
              color={
                record.confStatus === 'Confidential' || record.confStatus === 'Confidential Below'
                  ? '#f50'
                  : '#139A43'
              }
            >
              {record.confStatus}
            </Tag>
          </Flex>
        </>
      ),
    },
    {
      title: 'Conf Release',
      dataIndex: '',
      width: 170,
      key: 'confRelease',
      sorter: (a: any, b: any) => {
        const valA = a.confRelease || '';
        const valB = b.confRelease || '';
        return valA.localeCompare(valB);
      },
      render: (record: any) => {
        return (
          <>
            {
              <span
                style={{
                  fontSize: '0.84em',
                  color:
                    record.confStatus == 'Confidential' || record.confStatus == 'Confidential Below'
                      ? 'red'
                      : 'black',
                }}
              >
                {record && record.confRelease
                  ? format(new Date(record.confRelease), 'MMMM d, yyyy')
                  : '-'}
              </span>
            }
          </>
        );
      },
    },
    {
      title: 'Well Name',
      dataIndex: '',
      //width: 180,
      key: 'wellName',
      sorter: (a: any, b: any) => {
        const valA = a.wellName || ''; // Default to empty string if null/undefined
        const valB = b.wellName || ''; // Default to empty string if null/undefined
        return valA.localeCompare(valB);
      },
      render: (record: any) => (
        <>
          {record && record.wellName ? (
            <span
              style={{
                fontSize: '0.84em',
                color:
                  record.confStatus == 'Confidential' || record.confStatus == 'Confidential Below'
                    ? 'red'
                    : 'black',
              }}
            >
              {record.wellName}
            </span>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Final Drill Date',
      dataIndex: '',
      width: 150,
      key: 'finalDrillDate',
      sorter: (a: any, b: any) => {
        const valA = a.finalDrillDate || '';
        const valB = b.finalDrillDate || '';
        return valA.localeCompare(valB);
      },
      render: (record: any) => {
        return (
          <>
            <span
              style={{
                fontSize: '0.85em',
                color:
                  record.confStatus == 'Confidential' || record.confStatus == 'Confidential Below'
                    ? 'red'
                    : 'black',
              }}
            >
              {record && record.finalDrillDate
                ? format(new Date(record.finalDrillDate), 'MMMM d, yyyy')
                : '-'}
            </span>
          </>
        );
      },
    },
    {
      title: 'Final TD',
      dataIndex: '',
      width: 90,
      key: 'finalTd',
      sorter: (a: any, b: any) => {
        const valA = a.finalTd || '';
        const valB = b.finalTd || '';
        return valA.localeCompare(valB);
      },
      render: (record: any) => (
        <>
          {record && record.finalTd ? (
            <span
              style={{
                fontSize: '0.84em',
                color:
                  record.confStatus == 'Confidential' || record.confStatus == 'Confidential Below'
                    ? 'red'
                    : 'black',
              }}
            >
              {record.finalTd}
            </span>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Files',
      dataIndex: 'files',
      width: 40,
      key: 'fileCount',
      render: (files: FileInfo[]) => (
        <>
          <span
            style={{
              fontSize: '0.84em',
              fontWeight: files.length === 0 ? '100' : '700',
            }}
          >
            {files && files.length}
          </span>
        </>
        //files.length, // Count of files for each well
      ),
    },
  ];

  // Columns for the file table (expanded row)
  const fileColumns = [
    {
      title: '',
      key: 'fileId',
      // render: (record: FileInfo) => {
      //     return (
      //         <>
      //             <Checkbox

      //                 checked={selectedFiles.has(record.FileName)}
      //                 onChange={(e) => handleFileSelection(record, e.target.checked)}
      //             >
      //             </Checkbox>
      //         </>
      //     );
      // }
      render: (record: any) => {
        return (
          <>
            <Checkbox
              disabled={
                record.ConfStatus === 'Confidential' || record.ConfStatus === 'Confidential Below'
              }
              checked={selectedFiles.has(record.FileName)}
              onChange={(e) => handleFileSelection([record], e.target.checked)}
            ></Checkbox>
          </>
        );
      },
    },
    {
      title: 'Doc Type',
      dataIndex: '',
      width: 100,
      key: 'DocumentType',
      sorter: (a: any, b: any) => {
        const valA = a.DocumentType || ''; // Default to empty string if null/undefined
        const valB = b.DocumentType || ''; // Default to empty string if null/undefined
        return valA.localeCompare(valB);
      },
      render: (record: any) => (
        <>
          {record && record.DocumentType ? (
            <span style={{ fontSize: '0.84em' }}>{record.DocumentType}</span>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'File Name',
      dataIndex: '',
      width: '30%',
      key: 'FileName',
      sorter: (a: any, b: any) => {
        const valA = a.FileName || ''; // Default to empty string if null/undefined
        const valB = b.FileName || ''; // Default to empty string if null/undefined
        return valA.localeCompare(valB);
      },
      render: (record: any) => (
        <>
          {record && record.FileName ? (
            <span style={{ fontSize: '0.84em' }}>{record.FileName}</span>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Size',
      dataIndex: 'size',
      width: 80,
      key: 'size',
      sorter: (a: any, b: any) => {
        const valA = a.size || ''; // Default to empty string if null/undefined
        const valB = b.size || ''; // Default to empty string if null/undefined
        return valA.localeCompare(valB);
      },
    },
    {
      title: 'Run Date',
      dataIndex: '',
      width: 150,
      key: 'RunDate',
      sorter: (a: any, b: any) => {
        const valA = a.RunDate || ''; // Default to empty string if null/undefined
        const valB = b.RunDate || ''; // Default to empty string if null/undefined
        return valA.localeCompare(valB);
      },
      render: (record: any) => {
        return (
          <>
            {
              <span
                style={{
                  fontSize: '0.84em',
                  color: record.confStatus == 'Confidential' ? 'red' : 'black',
                }}
              >
                {record && record.RunDate ? format(new Date(record.RunDate), 'MMMM d, yyyy') : '-'}
              </span>
            }
          </>
        );
      },
    },
    {
      title: 'Start',
      dataIndex: '',
      width: 60,
      key: 'Start',
      render: (record: any) => {
        return (
          <>
            {
              <span
                style={{
                  fontSize: '0.84em',
                  color: record.confStatus == 'Confidential' ? 'red' : 'black',
                }}
              >
                {record && record.Start ? record.Start : '-'}
              </span>
            }
          </>
        );
      },
    },
    {
      title: 'Stop',
      dataIndex: '',
      width: 60,
      key: 'Stop',
      render: (record: any) => {
        return (
          <>
            {
              <span
                style={{
                  fontSize: '0.84em',
                  color: record.confStatus == 'Confidential' ? 'red' : 'black',
                }}
              >
                {record && record.Stop ? record.Stop : '-'}
              </span>
            }
          </>
        );
      },
    },
    {
      title: 'Step',
      dataIndex: '',
      width: 60,
      key: 'Step',
      render: (record: any) => {
        return (
          <>
            {
              <span
                style={{
                  fontSize: '0.84em',
                  color: record.confStatus == 'Confidential' ? 'red' : 'black',
                }}
              >
                {record && record.Step ? record.Step : '-'}
              </span>
            }
          </>
        );
      },
    },
    {
      title: 'TVDM',
      dataIndex: '',
      width: 60,
      key: 'TVDM',
      render: (record: any) => {
        return (
          <>
            {
              <span
                style={{
                  fontSize: '0.84em',
                  color: record.confStatus == 'Confidential' ? 'red' : 'black',
                }}
              >
                {record && record.TVDM ? record.TVDM : '-'}
              </span>
            }
          </>
        );
      },
    },
    {
      title: 'Service Company',
      dataIndex: '',
      width: 350,
      key: 'ServiceCompany',
      sorter: (a: any, b: any) => {
        const valA = a.ServiceCompany || ''; // Default to empty string if null/undefined
        const valB = b.ServiceCompany || ''; // Default to empty string if null/undefined
        return valA.localeCompare(valB);
      },
      render: (record: any) => (
        <>
          {record && record.ServiceCompany ? (
            <span style={{ fontSize: '0.84em' }}>{record.ServiceCompany}</span>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Legacy Code',
      dataIndex: '',
      width: 140,
      key: 'LegacyCode',
      render: (record: any) => (
        <>
          {record && record.LegacyCode ? (
            <span style={{ fontSize: '0.84em' }}>{record.LegacyCode}</span>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Log Type',
      dataIndex: '',
      width: 220,
      key: 'LogType',
      sorter: (a: any, b: any) => {
        const valA = a.LogType || ''; // Default to empty string if null/undefined
        const valB = b.LogType || ''; // Default to empty string if null/undefined
        return valA.localeCompare(valB);
      },
      render: (record: any) => (
        <>
          {record && record.LogType ? (
            <span style={{ fontSize: '0.84em' }}>{record.LogType}</span>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'DowndloadFile',
      render: (record: FileInfo) => (
        <>
          <Tooltip title={'Download ' + record.FileName + ' for UWI ' + convertDbUwi(record.UWI)}>
            <Button
              disabled={
                record?.ConfStatus === 'Confidential' || record?.ConfStatus === 'Confidential Below'
              }
              size="small"
              onClick={() => {
                handleDownloadFile(record.fileTypeId, record.FileName);
              }}
              icon={<DownloadOutlined />}
            ></Button>
          </Tooltip>
        </>
      ),
    },
  ];
  const onSelectChange = (newSelectedRowKeys: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const handleWellAndFilesSelection = (allSelected: any, selectedRows: any) => {
    if (allSelected) {
      selectedRows.map((selectedRow: any) => {
        handleFileSelection(selectedRow?.files, true);
      });
    } else {
      combinedData.map((selectedRow: any) => {
        handleFileSelection(selectedRow?.files, false);
      });
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    onSelectAll: handleWellAndFilesSelection,
  };
  return (
    <>
      {/* <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Perform Search' /> */}
      <Table
        rowSelection={{ type: 'checkbox', ...rowSelection }}
        loading={loading}
        columns={wellColumns}
        dataSource={combinedData}
        rowKey="uwi"
        size="small"
        bordered
        expandable={{
          expandedRowRender: (record: WellInfo) => (
            <Table
              size="small"
              columns={fileColumns}
              dataSource={record.files}
              rowKey="fileName"
              pagination={false}
            />
          ),
          rowExpandable: (record: WellInfo) => record.files.length > 0,
          // Set expandedRowKeys to allow only one expanded row at a time
          expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
          onExpand: handleExpand,
        }}
      />
    </>
  );
};

export default WellSearchResults;
