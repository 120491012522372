import { Button, Modal, Tooltip } from 'antd';
import React from 'react';
import notifyUser from '../../../../utils/notifyUser';

const ModifyRequestProductListForm: React.FC = () => {
  const handleProductListUpdate = () => {
    notifyUser('success', 'modal to appear...');
  };

  return (
    <>
      <Modal></Modal>
      <Tooltip title="Update the requested product list for this request.">
        <Button style={{ margin: '0 6px' }} disabled onClick={handleProductListUpdate}>
          Modify Product List
        </Button>
      </Tooltip>
    </>
  );
};

export default ModifyRequestProductListForm;
