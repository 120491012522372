import React from 'react';
import AppLayout from '../../components/Layout/AppLayout';
import { AppFooter } from '../../components/Layout/AppFooter';
import Breadcrumbs from '../../components/Layout/Breadcrumbs';
import { Card, Col, Row, Statistic } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

const Index: React.FC = () => {
  return (
    <>
      <AppLayout />
      <div className='content'>
        <Breadcrumbs
          items={[
            { href: '/dissemination', title: 'File Dissemination' },
            { title: 'Dashboard' },
          ]}
        />
        <p></p>
        <p className='Page-heading'>File Dissemination</p>
        {/* <Row gutter={16}>
          <Col span={8}>
            <Card bordered={false}>
              <Statistic
                title="Active"
                value={11.28}
                precision={2}
                valueStyle={{ color: '#3f8600' }}
                prefix={<ArrowUpOutlined />}
                suffix="%"
              />
            </Card>
          </Col>
          <Col span={5}>
            <Card bordered={false}>
              <Statistic
                title="Idle"
                value={9.3}
                precision={2}
                valueStyle={{ color: '#cf1322' }}
                prefix={<ArrowDownOutlined />}
                suffix="%"
              />
            </Card>
          </Col>
          <Col span={5}>
            <Card bordered={false}>
              <Statistic
                title="Idle"
                value={9.3}
                precision={2}
                valueStyle={{ color: '#cf1322' }}
                prefix={<ArrowDownOutlined />}
                suffix="%"
              />
            </Card>
          </Col>
        </Row> */}
      </div>
      <AppFooter />
    </>
  );
};

export default Index;