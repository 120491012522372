import React, { useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  PauseOutlined,
  RedoOutlined,
  TagOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Checkbox, Divider, Dropdown, Modal, Space } from 'antd';
import notifyUser from '../../../utils/notifyUser';
import { useNavigate } from 'react-router-dom';
import {
  deleteCustomerRequest,
  deleteDisseminationRequest,
  pauseDisseminationRequest,
  resendDisseminationRequest,
} from '../../../services/api';

interface RequestContextProps {
  cartCount: number;
  requestId: string;
  requestNumber: string;
  requestStatus: string;
}

interface ModalsState {
  delete: boolean;
  pause: boolean;
  resend: boolean;
  editDetails: boolean;
  changeProducts: boolean;
}

const App: React.FC<RequestContextProps> = ({
  cartCount,
  requestId,
  requestNumber,
  requestStatus,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const [confirmResend, setConfirmResend] = useState(false);
  const loadRequestLists = '/dissemination/irm';

  //modal handling
  const [modals, setModals] = useState<ModalsState>({
    delete: false,
    pause: false,
    resend: false,
    changeProducts: false,
    editDetails: false,
  });

  // const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
  //     notifyUser('success', 'Button Clicked ' + requestNumber);
  //     //message.info('Click on left button.');
  //     console.log('click left button', e);
  // };

  // const handleMenuClick: MenuProps['onClick'] = (e) => {
  //     notifyUser('success', 'Menu Clicked ' + requestNumber);
  //     //message.info('Click on menu item.');
  //     console.log('click', e);
  // };

  //======Handle - Resend Dissemination Modal
  const handleResendModal = () => {
    setModals({ ...modals, resend: true });
  };

  const handleConfirmResend = () => {
    setConfirmResend(!confirmResend);
  };

  const handleResend = async (requestId: string) => {
    setLoading(true);
    await resendDisseminationRequest(requestId);
    setModals({ ...modals, resend: false });
    notifyUser('success', 'Dissemination resent successfully.');
    setLoading(false);
  };

  //======Handle - Pause Dissemination Modal
  const handlePauseModal = () => {
    setModals({ ...modals, pause: true });
  };

  const handlePause = async (requestId: string) => {
    setLoading(true);
    await pauseDisseminationRequest(requestId);
    setModals({ ...modals, pause: false });
    notifyUser('success', 'Dissemination paused successfully.');
    setLoading(false);
  };

  //======Handle - Delete Request Modal.
  const handleDeleteModal = () => {
    setModals({ ...modals, delete: true });
  };

  //deletion confirmations
  const handleConfirmDeletion = () => {
    setConfirmDeletion(!confirmDeletion);
  };

  //process deletion
  const handleDelete = async (requestId: string) => {
    setLoading(true);

    //reset the confirmation check.
    setConfirmDeletion(false);
    console.log('deletion: ' + requestId);

    try {
      const res = await deleteCustomerRequest(requestId);

      notifyUser('success', res.data.message);

      /*
            if (res.status != 200) {
                notifyUser('warning', res.data.message);
            } else {
                const resms = await deleteDisseminationRequest(requestId);
                resms.status === 200 ?
                    notifyUser('success', res.data.message) : notifyUser('success', resms.data.message);
            }
                    */
    } catch (error) {
      notifyUser('error', 'Unable to delete the customer request: ' + error);
    }

    // Close the modal and refresh the list.
    setModals({ ...modals, delete: false });
    setLoading(true);
    //setModalDelete(false);
    setTimeout(() => {
      navigate(loadRequestLists);
    }, 2000);
  };

  const items: MenuProps['items'] = [
    {
      label: 'Edit Request Details',
      key: '1',
      icon: <EditOutlined />,
      disabled: requestStatus && requestStatus === 'Completed' ? true : false,
    },
    {
      label: 'Resend Dissemination',
      key: '3',
      icon: <RedoOutlined />,
      disabled: requestStatus && requestStatus === 'Paused' && cartCount > 0 ? false : true,
      onClick: () => {
        handleResendModal();
      },
    },
    {
      label: 'Pause Dissemination',
      key: '4',
      icon: <PauseOutlined />,
      danger: true,
      disabled: requestStatus && requestStatus === 'Completed' ? false : true,
      onClick: () => {
        handlePauseModal();
      },
    },
    {
      type: 'divider',
    },
    {
      label: 'Delete Request',
      key: '5',
      icon: <DeleteOutlined />,
      danger: true,
      disabled: false,
      onClick: () => {
        handleDeleteModal();
      },
    },
  ];

  const menuProps = {
    items,
    //onClick: handleMenuClick,
  };

  return (
    <>
      <span style={{}}>
        <Space wrap>
          <Dropdown.Button menu={menuProps}>Request Options</Dropdown.Button>
        </Space>
      </span>

      {/* Resend Dissemination */}
      <Modal
        title={'Resend Dissemination: ' + 'IRM' + requestNumber}
        open={modals.resend}
        onCancel={() => {
          setModals({ ...modals, resend: false });
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setModals({ ...modals, resend: false });
            }}
          >
            Cancel
          </Button>,
          <Button
            loading={loading}
            key="submit"
            disabled={!confirmResend}
            type="primary"
            danger
            onClick={() => handleResend(requestId)}
          >
            Resend Dissemination
          </Button>,
        ]}
      >
        <Divider type="horizontal" className="dividerColor, dividerTop"></Divider>

        <p>
          Are you sure you want to resend the dissemination for request {'IRM' + requestNumber}?
        </p>
        <p>
          Please note that all associated file records for this request will be resent to the
          customer.
        </p>
        <br></br>

        <Checkbox checked={confirmResend} onChange={handleConfirmResend}>
          Yes, I want to resend this dissemination.
        </Checkbox>
        <Divider type="horizontal" className="dividerColor, dividerBottom"></Divider>
      </Modal>

      {/* Pause Dissemination */}
      <Modal
        title={'Pause Dissemination: ' + 'IRM' + requestNumber}
        open={modals.pause}
        onCancel={() => {
          setModals({ ...modals, pause: false });
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setModals({ ...modals, pause: false });
            }}
          >
            Cancel
          </Button>,
          <Button
            loading={loading}
            key="submit"
            type="primary"
            danger
            onClick={() => handlePause(requestId)}
          >
            Pause Dissemination
          </Button>,
        ]}
      >
        <Divider type="horizontal" className="dividerColor, dividerTop"></Divider>

        <p>Are you sure you want to pause the dissemination for request {'IRM' + requestNumber}?</p>
        <p>Please note that all associated file records for this request will be deleted.</p>
        <br></br>

        <Divider type="horizontal" className="dividerColor, dividerBottom"></Divider>
      </Modal>

      {/* Deletion */}
      <Modal
        title={'Request Deletion: ' + 'IRM' + requestNumber}
        open={modals.delete}
        onCancel={() => {
          setModals({ ...modals, delete: false });
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setModals({ ...modals, delete: false });
            }}
          >
            Cancel
          </Button>,
          <Button
            loading={loading}
            key="submit"
            disabled={!confirmDeletion}
            type="primary"
            danger
            onClick={() => handleDelete(requestId)}
          >
            Process Deletion
          </Button>,
        ]}
      >
        <Divider type="horizontal" className="dividerColor, dividerTop"></Divider>

        <p>Are you sure you want to perform the deletion for request {'IRM' + requestNumber}?</p>
        <p>Please note that all associated file records for this request will be deleted.</p>
        <br></br>
        <Checkbox checked={confirmDeletion} onChange={handleConfirmDeletion}>
          Yes, I confirm the deletion.
        </Checkbox>
        <Divider type="horizontal" className="dividerColor, dividerBottom"></Divider>
      </Modal>
    </>
  );
};

export default App;
