import React, { useState } from 'react';
import type { RadioChangeEvent } from 'antd';
import { Radio, Tabs } from 'antd';
import AppLayout from '../components/Layout/AppLayout';
import { AppFooter } from '../components/Layout/AppFooter';

type TabPosition = 'left' | 'right' | 'top' | 'bottom';

const Help: React.FC = () => {

  const [mode, setMode] = useState<TabPosition>('top');

  const handleModeChange = (e: RadioChangeEvent) => {
    setMode(e.target.value);
  };



  return (
    <>
      <AppLayout />
      <div className='content'>
        <p className='Page-heading'>Help</p>

        <div>
          <Radio.Group onChange={handleModeChange} value={mode} style={{ marginBottom: 8 }}>
            <Radio.Button value="top">Horizontal</Radio.Button>
            <Radio.Button value="left">Vertical</Radio.Button>
          </Radio.Group>
          <Tabs
            defaultActiveKey="1"
            tabPosition={mode}
            style={{ height: 220 }}
            items={new Array(30).fill(null).map((_, i) => {
              const id = String(i);
              return {
                label: `Tab-${id}`,
                key: id,
                disabled: i === 28,
                children: `Content of tab ${id}`,
              };
            })}
          />
        </div>
      </div>
      <AppFooter />
    </>
  );
};

export default Help;